import { InfoRounded } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import {
  accountStatusIsError,
  getAccountStatusData,
} from "../../utils/accountStatus";

function ButtonContainer({
  name,
  lastname,
  email,
  companyName,
  children,
  onClick,
  selected,
  accountStatus,
}) {
  const withErrors = accountStatusIsError(accountStatus);
  const { title, severity } = getAccountStatusData(accountStatus);

  return (
    <Tooltip
      arrow
      title={
        <>
          <Typography my={0.4} fontSize={".85rem"} fontWeight={"500"}>
            Conta Notepay
          </Typography>
          <Typography
            fontSize={".8rem"}
            sx={{ opacity: 0.8 }}
            fontWeight={"500"}
          >
            {name} {lastname || ""}
          </Typography>
          <Typography
            fontSize={".8rem"}
            sx={{ opacity: 0.8 }}
            fontWeight={"500"}
          >
            {email}
          </Typography>
          <Divider sx={{ borderColor: "white", my: 0.5 }} />
          <Typography
            fontSize={".8rem"}
            sx={{ opacity: 0.8 }}
            fontWeight={"500"}
          >
            {companyName}
          </Typography>
          {withErrors && (
            <>
              <Alert
                icon={() => <></>}
                sx={{ my: 1, p: 0 }}
                variant="outlined"
                severity={severity}
              >
                <AlertTitle
                  sx={{ fontSize: ".75rem" }}
                  color={
                    severity === "warning" ? "#F4932C" : "rgb(255, 180, 171)"
                  }
                >
                  {title}
                </AlertTitle>
              </Alert>
            </>
          )}
        </>
      }
    >
      <Paper
        variant="outlined"
        onClick={onClick}
        sx={{
          transition: "none",
          position: "relative",
          display: "flex",
          alignItems: "center",
          borderRadius: {
            xs: 100,
            md: 1.5,
          },
          width: {
            xs: 47,
            md: 170,
          },
          height: {
            xs: 47,
            md: 47,
          },
          pr: 1,
          cursor: "pointer",
          outline: (t) =>
            selected || withErrors
              ? `${selected ? "2px" : "1px"} solid ${
                  t.palette[withErrors ? severity : selected && "primary"].main
                }`
              : "none",
          ":hover": {
            boxShadow: 2,
          },
          ":active": {
            outline: (t) =>
              `2px solid ${
                t.palette[
                  selected ? "primary" : withErrors ? severity : "primary"
                ].main
              }`,
          },
        }}
      >
        {withErrors && (
          <Box
            p={0.2}
            position={"absolute"}
            bottom={-4}
            bgcolor={"background.default"}
            right={-4}
            borderRadius={100}
            zIndex={100}
            width={20}
            height={20}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <InfoRounded fontSize="small" color={severity} />
          </Box>
        )}
        {children}
      </Paper>
    </Tooltip>
  );
}

export default memo(ButtonContainer);
