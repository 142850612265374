import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import React, { useState } from "react";

function ModalDelete({
  onDelete = async () => {},
  onClose,
  open,
  title = "Excluir",
  subtitle = "",
  buttonText,
}) {
  const [loading, setLoading] = useState(false);

  async function handleDelete(e) {
    e.stopPropagation();
    setLoading(true);
    await onDelete();
    setLoading(false);
    onClose();
  }

  return (
    <Dialog
      PaperProps={{
        onClick: (e) => e.stopPropagation(),
        sx: {
          maxWidth: 420,
        },
      }}
      onClose={(e) => {
        onClose();
        e.stopPropagation();
      }}
      open={open}
      fullWidth
      maxWidth="xs"
    >
      {loading && <LinearProgress color="primary" />}
      <DialogTitle fontSize={"1.2rem"} fontWeight={"600"}>
        {title}
        <Box flex={1} />
      </DialogTitle>
      <DialogContent>
        {subtitle && (
          <DialogContentText fontSize={".93rem"}>{subtitle}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          disableElevation
          onClick={handleDelete}
          disabled={loading}
          color="primary"
        >
          {buttonText || (loading ? "Excluindo" : "Excluir")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalDelete;
