import { createSlice } from "@reduxjs/toolkit";
import { HttpClient } from "../../../api/http-client";
import { BASE_URL } from "../../../api/urls";
import { store } from "../../store";
import { setError } from "./errorBaseSlice";
import { openSnackbar } from "./snackbarBaseSlice";

const initialState = {
  requestPDFSettings: {
    groupBy: "category",
    order: "desc",
    orderBy: "date",
  },
};

export const exportRequestToExcel = async (requestId) => {
  const state = store.getState();

  const filename =
    state.expenseRequests?.entities[requestId]?.title || "report" + ".xls";


  const user_id =  state.expenseRequests?.entities[requestId]?.user_id

  try {
    const {
      data: { file_url },
    } = await HttpClient.client().post(
      `/controlling/requests/${requestId}/export/xls`,
      {
        filename,
        user_id,
        download: true,
      }
    );
    const URL = BASE_URL + file_url;
    const link = document.createElement("a");
    link.download = filename;
    link.href = URL;
    link.target = "_blank";
    link.click();
    store.dispatch(
      openSnackbar({
        message: "Transferido para downloads",
      })
    );
  } catch (error) {
    store.dispatch(
      setError({
        title: "Erro ao exportar Excel",
        message: error?.response?.data?.message,
      })
    );
  }
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLayoutViewMode(state, { payload }) {
      state.layoutViewMode = payload || "grid";
    },
    toggleLayoutViewMode(state, { payload }) {
      state.layoutViewMode = state.layoutViewMode === "grid" ? "line" : "grid";
    },
    setCurrentPosition(state, { payload }) {
      state.currentPosition = payload;
    },
    resetSettings(state) {
      state.currentPosition = initialState.currentPosition;
      state.layoutViewMode = initialState.layoutViewMode;
    },
    setRequestPDFSetting(state, { payload }) {
      state.requestPDFSettings[payload?.setting] = payload?.value;
    },
  },
});

export const {
  toggleLayoutViewMode,
  setCurrentPosition,
  setLayoutViewMode,
  resetSettings,
  setRequestPDFSetting,
} = settingsSlice.actions;

export default settingsSlice.reducer;

export const selectRequestPDFSettings = (state) =>
  state.settings.requestPDFSettings;
export const selectLayoutViewMode = (state) => state.settings.layoutViewMode;
