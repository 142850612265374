import { Box, CircularProgress } from "@mui/material";
import React from "react";

function LoadingSection({
  h = 300,
  w = "100%",
  sx,
  children,
  loadingSize = 40,
}) {
  return (
    <Box
      width={w}
      height={h}
      display={"flex"}
      alignItems="center"
      justifyContent={"center"}
      flexDirection="column"
      sx={sx}
    >
      <CircularProgress size={loadingSize} />
      {children}
    </Box>
  );
}

export default LoadingSection;
