import {
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText
} from "@mui/material";
import React from "react";
import { HighlightedText } from "../../../../utils/functions/highlightText";
import AvatarType from "../../../avatar/AvatarType";

function ExpTypeItem({ type, searchTerm, onClick }) {
  return (
    <ListItem disablePadding dense disableGutters>
      <ListItemButton onClick={onClick} sx={{ transition: "none" }}>
        <ListItemAvatar>
          <AvatarType icon={type.icon} />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ fontWeight: "500" }}
          sx={{ ml: -1 }}
          primary={<HighlightedText searchTerm={searchTerm} text={type.name} />}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default ExpTypeItem;
