import React, { memo } from "react";
import { OrgItem as OrgIListItem } from "../../../../pages/my_company/components/items/MenuOrgs";

function OrgItem({ name, onClose, orgId, searchTerm }) {
  return (
    <OrgIListItem
      orgId={orgId}
      activeIcon
      activeHighlightedText
      searchTerm={searchTerm}
      textSx={{ fontSize: ".9rem" }}
      disableRemoveButton
      onClose={onClose}
    />
  );
}

export default memo(OrgItem);
