import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";

const baseName = "projects";

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchProjects = createAsyncThunk(
  `${baseName}/fetchProjects`,
  async () => {
    const res = await HttpClient.client().get("/projects");
    return res.data;
  }
);

export const projectsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    addProject(state, { payload }) {
      adapter.addOne(state, {...payload, active: true});
    },
    removeProject(state, { payload }) {
      adapter.removeOne(state, payload);
    },
    updateProject(state, { payload }) {
      const { changes, id } = payload;
      adapter.updateOne(state, {
        id,
        changes,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        const data = action.payload;
        state.ids = data.ids;
        state.entities = data.entities;
      });
  },
});

export const { addProject, removeProject, updateProject } =
  projectsSlice.actions;

export const {
  selectAll: selectAllProjects,
  selectIds: selectProjectsIds,
  selectById: selectProjectById,
  selectEntities: selectProjectsEntities,
  selectTotal: selectProjectsTotal,
} = adapter.getSelectors((state) => state[baseName]);

export default projectsSlice.reducer;

export const selectProjectsIdsByStatusValid = createSelector(
  [
    selectProjectsIds,
    selectProjectsEntities,
    (state, validStatus) => validStatus,
  ],
  (ids, entities, validStatus) => {
    if (validStatus === "all") {
      return ids;
    }
    return ids.filter((id) => {
      const fromDate = new Date(
        new Date(entities[id].valid_from).setHours(0, 0, 0, 0)
      ).getTime();
      const endDate = new Date(
        new Date(entities[id].valid_to).setHours(0, 0, 0, 0)
      ).getTime();
      const curDate = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
      if (validStatus === "active") {
        return Boolean(curDate >= fromDate && curDate < endDate);
      }
      if (validStatus === "pending") {
        return Boolean(curDate < fromDate);
      }
      if (validStatus === "completed") {
        return Boolean(curDate >= endDate);
      }
    });
  }
);

export const selectProjectsIdsByUser = createSelector(
  [selectProjectsIds, selectProjectsEntities, (state, userId) => userId],
  (ids, entities, userId) => {
    return ids.filter((id) => entities[id].users.includes(userId));
  }
);
