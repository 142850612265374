import { Box } from "@mui/material";
import React, { Suspense, useCallback } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import "../App.css";
import DialogAlert from "../classes/DialogAlert";
import AccountStatusBanner from "../components/AccountStatusBanner";
import Fallback from "../components/Fallback";
import Appbar from "../components/appbar/Appbar";
import Sidebar from "../components/sidebar/Sidebar";
import SnackbarBase from "../components/snackbar/SnackbarBase";
import ErrorBoundary from "../errors/Boundary";
import DuplicatedChecker from "../modals/duplicated-checker";
import ModalErrorBase from "../modals/error/ModalErrorBase";
import ModalRegisterUser from "../modals/users/ModalRegisterUser";
import ModalUserView from "../modals/users/modal-user-view";
import { selectAccountReadOnly } from "../store/reducers/accountSlice";
import RouterContent from "./RouterContent";
import { routes } from "./routes";

const INITAL_ROUTE = "/statistics";
export const DEFAULT_DOCUMENT_TITLE = "Administrador";

function RouterContainer(props) {
  // const hasUpdatesInVersion = useSelector(
  //   (state) => state?.account?.hasUpdatesInVersion
  // );
  const expandedSidebar = useSelector((state) => state.sidebarContent.expanded);

  const accountReadOnly = useSelector(selectAccountReadOnly);

  const renderRoutes3 = useCallback((c3) => {
    return (
      <Route key={c3.path} path={c3.path} element={c3.wrapper}>
        {(c3.element || c3.indexElement) && (
          <Route index element={c3.element || c3.indexElement} />
        )}
        <Route path={c3.path} element={c3.element} />
      </Route>
    );
  }, []);

  const renderRoutes2 = useCallback(
    (c2) => {
      return (
        <Route key={c2.path} path={c2.path} element={c2.wrapper}>
          {(c2.element || c2.indexElement) && (
            <Route index element={c2.element || c2.indexElement} />
          )}
          {c2.children && c2.children.map(renderRoutes3)}
        </Route>
      );
    },
    [renderRoutes3]
  );

  const renderRoutes1 = useCallback(
    (c1) => {
      return (
        <Route key={c1.path} path={c1.path} element={c1.wrapper}>
          {(c1.element || c1.indexElement) && (
            <Route index element={c1.element || c1.indexElement} />
          )}
          {c1.children.map(renderRoutes2)}
        </Route>
      );
    },
    [renderRoutes2]
  );

  return (
    <>
      {accountReadOnly && <AccountStatusBanner />}
      <Box
        className="grid_container"
        flexGrow={1}
        flexBasis={0}
        position={"relative"}
        bgcolor={"elevation1.main"}
      >
        <Box component={"div"} className="appbar_container">
          <Appbar />
        </Box>
        <Box component={"div"} className="sidebar_content">
          <Sidebar accountReadOnly={accountReadOnly} />
        </Box>
        <Box
          flex={1}
          flexBasis={0}
          className="routes_container"
          id="box_container"
          pl={{ xs: 1, xl: expandedSidebar ? "15.5em" : "4.2em" }}
          sx={{
            transition: ".2s ease",
            overflowX: { xs: "auto", xl: "hidden" },
          }}
          pr={{ xs: 1, xl: 2 }}
          pb={2}
          pt={0.5}
        >
          <RouterContent>
            <ErrorBoundary>
              <Suspense fallback={<Fallback />}>
                <Routes>
                  <Route index element={<Navigate to={INITAL_ROUTE} />} />
                  {routes.map(renderRoutes1)}
                </Routes>
              </Suspense>
              <ModalUserView />
              <DuplicatedChecker />
              <ModalRegisterUser />
              <ModalErrorBase />
              <DialogAlert />
              {/* {version === hasUpdatesInVersion && <UpdatesModal />} */}
            </ErrorBoundary>
          </RouterContent>
          <SnackbarBase />
        </Box>
      </Box>
    </>
  );
}

export default RouterContainer;
