import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";
import normalize from "../../utils/normalize";

const baseName = "savedViews";

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchSavedViews = createAsyncThunk(
  `${baseName}/fetchSavedViews`,
  async () => {
    const res = await HttpClient.client().get(`/table-views`);
    return res.data;
  }
);

export const savedViewsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    addSavedView(state, { payload }) {
      adapter.addOne(state, payload);
    },
    removeSavedView(state, { payload }) {
      adapter.removeOne(state, payload);
    },
    updateSavedView(state, { payload }) {
      adapter.updateOne(state, {
        id: payload?.id,
        changes: payload?.changes,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSavedViews.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSavedViews.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchSavedViews.fulfilled, (state, action) => {
        state.status = "succeeded";
        const data = action.payload;
        const { ids, entities } = normalize(data);
        adapter.setAll(state, entities);
      });
  },
});

export const { addSavedView, removeSavedView, updateSavedView } =
  savedViewsSlice.actions;

export default savedViewsSlice.reducer;

export const {
  selectAll: selectAllSavedViews,
  selectIds: selectSavedViewsIds,
  selectById: selectSavedViewById,
  selectEntities: selectSavedViewsEntities,
  selectTotal: selectTotalSavedViews,
} = adapter.getSelectors((state) => state[baseName]);

export const selectSavedViewsByTableName = createSelector(
  [selectAllSavedViews, (state, tableName) => tableName],
  (views, tableName) => views?.filter((view) => view?.table_name === tableName)
);
