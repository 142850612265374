import { Check } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
} from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import validator from "validator";
import PaperSection from "../../../components/form/PaperSection";
import TextInput from "../../../components/inputs/text-input";
import AvatarPicker from "./components/AvatarPicker";

const RightEmailInput = memo(({ loading, succeeded }) => {
  if (loading) {
    return <CircularProgress size={30} />;
  } else if (succeeded) {
    return <Check color="success" />;
  } else return;
});

const EmailInput = memo(({ onChange, value = "" }) => {
  const [error, setError] = useState(false);

  const renderRight = useCallback(() => {
    return <RightEmailInput />;
  }, []);

  const handleBlur = () => {
    if (!Boolean(value.trim()) || !validator.isEmail(value)) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <Box minWidth={200} width={"100%"}>
      <TextInput
        label={"E-mail"}
        placeholder={""}
        required
        variant={"filled"}
        value={value}
        onChange={(v) => {
          onChange(v);
        }}
        onBlur={handleBlur}
        onFocus={() => setError(false)}
        helperText={"E-mail inválido"}
        RightIcon={renderRight}
        propError={error}
      />
      {!error && (
        <FormHelperText sx={{ ml: 0 }}>
          Um código de confirmação será enviado para este e-mail
        </FormHelperText>
      )}
    </Box>
  );
});

const InputName = memo(({ onChange }) => {
  return (
    <TextInput
      autoFocus
      label={"Nome"}
      required
      activeInternalValue
      variant={"filled"}
      onChange={(v) => {
        onChange(v);
      }}
    />
  );
});

function BaseInfoSection({
  onInputChange,
  photo,
  onChangePhoto,
  onRemovePhoto,
  email,
}) {
  return (
    <PaperSection title={"Dados pessoais"} disableElevation>
      <Box display={"flex"} alignItems="flex-start" flexWrap={"wrap"} gap={3}>
        <AvatarPicker
          photo={photo}
          onChange={onChangePhoto}
          onRemovePhoto={onRemovePhoto}
        />
        <FormControl
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            flex: 1,
            minWidth: 300,
          }}
        >
          <Box display={"flex"} alignItems="center" gap={2}>
            <InputName onChange={(v) => onInputChange("name", v)} />
            <TextInput
              label={"Sobrenome"}
              variant={"filled"}
              onChange={(v) => onInputChange("lastname", v)}
            />
          </Box>
          <EmailInput
            value={email}
            onChange={(v) => onInputChange("email", v)}
          />
        </FormControl>
      </Box>
    </PaperSection>
  );
}

export default memo(BaseInfoSection);
