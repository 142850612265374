import { ExitToAppOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AuthAPIGateway } from "../../../../../api/auth";
import { AUTH_BASE_URL } from "../../../../../api/urls";
import { setError } from "../../../../../store/reducers/bases/errorBaseSlice";
import { ButtonItem } from "../FooterActions";

const DialogConfirmation = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleExit = async () => {
    setLoading(true);
    try {
      await AuthAPIGateway.logout();
      window.location.replace(
        `${AUTH_BASE_URL}?step=0&email=${window.localStorage.getItem(
          "email"
        )}&redirect=${window.location.origin}`
      );
      dispatch({
        type: "RESET_STORE",
      });
    } catch (error) {
      setLoading(false)
      dispatch(setError({ title: "Erro ao sair da conta", error }));
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      {loading && <LinearProgress />}
      <DialogTitle fontSize={"1.1rem"}>
        Tem certeza que deseja sair da conta?
      </DialogTitle>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          variant="contained"
          disableElevation
          disabled={loading}
          onClick={handleExit}
        >
          {loading ? "Saindo" : "Sair"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function ExitButton(props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonItem
        onClick={() => setOpen(true)}
        divider={false}
        Icon={ExitToAppOutlined}
      >
        Sair
      </ButtonItem>
      <DialogConfirmation open={open} onClose={() => setOpen(false)} />
    </>
  );
}

export default ExitButton;
