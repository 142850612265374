import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import ModalFullContent from "../../../components/modal-full-content";
import UserView from "../../../pages/users/view/UserView";

export default (props) => {
  const [params, setParams] = useSearchParams();

  const userId = params?.get("userView");
  const visible = Boolean(userId);

  const closeModal = useCallback(() => {
    params.delete("userView");
    setParams(params);
  }, [params]);

  return (
    <ModalFullContent visible={visible}>
      <UserView closeModal={closeModal} userId={userId} />
    </ModalFullContent>
  );
};
