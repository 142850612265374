import { NotesOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React, { memo, useState } from "react";
import { connect } from "react-redux";
import { selectUserById } from "../../../../../../../../store/reducers/usersSlice";
import DetailListItem from "../../../../../components/DetailListItem";
import MenuView from "./MenuView";

function NotesItem({ notes, userId }) {
  const [modal, setModal] = useState(false);

  return (
    <>
      <DetailListItem
        emptyText={"Sem notas"}
        Icon={NotesOutlined}
        title={"Notas"}
        divider={false}
        focused={modal}
        onClick={() => setModal(true)}
      >
        {notes && (
          <Typography component={"span"} variant="inherit" fontWeight={"500"}>
            {notes}
          </Typography>
        )}
      </DetailListItem>
      <MenuView
        open={modal}
        onClose={() => setModal(false)}
        notes={notes}
        userId={userId}
      />
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { notes } = selectUserById(state, ownProps.userId) || { notes: "" };

  return {
    notes: notes || "",
    ...ownProps,
  };
};

export default connect(mapStateToProps)(memo(NotesItem));
