import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { HttpClient } from "../../../../../api/http-client";
import { setError } from "../../../../../store/reducers/bases/errorBaseSlice";
import { openSnackbar } from "../../../../../store/reducers/bases/snackbarBaseSlice";

const ModalNewInvite = ({ open, onClose, userId }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await HttpClient.client().post(`/users/${userId}/invites/resend`);
      dispatch(
        openSnackbar({
          message: "Convite enviado",
        })
      );
      onClose();
    } catch (error) {
      dispatch(setError(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      {loading && <LinearProgress />}
      <DialogTitle>Reenviar convite</DialogTitle>
      <DialogContent>
        <DialogContentText>
          O colaborador receberá um novo e-mail com um convite para cadastro.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          disabled={loading}
          disableElevation
          variant="contained"
          onClick={handleConfirm}
        >
          {loading ? "Enviando" : "Enviar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalNewInvite;
