import { Tooltip } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import {
  selectAccountReadOnly,
  selectHasSettingsPermission,
} from "../../store/reducers/accountSlice";

const NoAccessTooltip = ({ children, disableCheckPermission }) => {
  const hasPermission = useSelector(selectHasSettingsPermission);
  const accountReadOnly = useSelector(selectAccountReadOnly);

  return (
    <Tooltip
      title={
        accountReadOnly
          ? "Sua conta ficará no modo leitura até processarmos o seu pagamento pendente"
          : "Você não tem permissão para gerenciar as configurações"
      }
      disableHoverListener={disableCheckPermission || hasPermission}
      disableFocusListener={disableCheckPermission || hasPermission}
    >
      {children}
    </Tooltip>
  );
};

export default NoAccessTooltip;
