import { Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React, { memo } from "react";
import ActionsControlsContent from "./sections/base-info/components/ActionsControlsContent";
import DetailsContent from "./sections/base-info/components/DetailsContent";
import DetailsSection from "./sections/details/DetailsSection";

function UserView({ closeModal, userId }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        pt: 2,
      }}
    >
      <IconButton
        onClick={() => closeModal()}
        sx={{ position: "sticky", top: 10, left: 10 }}
      >
        <Close />
      </IconButton>
      <Box
        pb={10}
        flex={1}
        pt={1}
        pr={3}
        ml={4}
        display={"flex"}
        flexDirection="column"
      >
        <Box display={"flex"} alignItems="flex-start">
          <Box flex={1} display={"flex"} alignItems="center">
            <DetailsContent userId={userId} />
          </Box>
          <Box pt={2}>
            <ActionsControlsContent userId={userId} />
          </Box>
        </Box>
        <DetailsSection userId={userId} />
      </Box>
    </Box>
  );
}

export default memo(UserView);
