import { MenuOutlined } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { EXPENSES_BASE_URL } from "../../api/urls";
import { changeExpandedSidebar } from "../../store/reducers/bases/sidebarContentSlice";
import { isStaging } from "../../theme";
import NotepayLogo from "../NotepayLogo";
import AccountIndicator from "../account-indicator/AccountIndicator";
import DrawerNavigation from "../drawer/DrawerNavigation";
import SearchBar from "../search/SearchBar";
import HelpButton from "./components/HelpButton";
import Title from "./components/Title";
import ToggleThemeButton from "./components/ToggleThemeButton";
import NotificationsMain from "./components/notifications/NotificationsMain";

function Appbar(props) {
  const dispatch = useDispatch();

  return (
    <AppBar
      variant="elevation"
      color="transparent"
      position="relative"
      elevation={0}
      sx={{
        border: "none",
        zIndex: (t) => t.zIndex.appBar,
      }}
    >
      <Toolbar disableGutters sx={{ height: 60, px: 2.1 }}>
        <DrawerNavigation />
        <IconButton
          sx={{ mr: 1, display: { xs: "none", xl: "flex" } }}
          onClick={() => dispatch(changeExpandedSidebar())}
        >
          <MenuOutlined />
        </IconButton>
        <NotepayLogo />
        {isStaging && (
          <>
            <Typography
              ml={2}
              fontWeight={"500"}
              color={"text.secondary"}
              fontSize={"1.3rem"}
            >
              Homologação
            </Typography>
            <Divider sx={{ ml: 1, height: 20 }} orientation="vertical" />
          </>
        )}
        <Title />
        <SearchBar />
        <Box flex={1} />
        <Box display={"flex"} alignItems={"center"} gap={1.5}>
          <Button
            size="large"
            variant="outlined"
            LinkComponent={"a"}
            href={EXPENSES_BASE_URL}
            target="_blank"
            sx={{
              transition: "none",
              display: { xs: "none", lg: "flex" },
              borderRadius: 100,
              height: 39,
              ":hover": { boxShadow: 2 },
            }}
          >
            <Typography variant="inherit" noWrap>
              Minhas despesas
            </Typography>
          </Button>
          <NotificationsMain />
          <HelpButton />
          <ToggleThemeButton />
          <AccountIndicator />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default memo(Appbar);
