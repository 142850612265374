import { Close, DeleteOutlineOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HttpClient } from "../../../../../../../../api/http-client";
import GroupSelector from "../../../../../../../../components/inputs/selectors/GroupSelector";
import { setError } from "../../../../../../../../store/reducers/bases/errorBaseSlice";
import { openSnackbar } from "../../../../../../../../store/reducers/bases/snackbarBaseSlice";
import { updateUser } from "../../../../../../../../store/reducers/usersSlice";

function MenuView({ open, onClose, groupId, userId }) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(groupId || "");

  useEffect(() => {
    setValue(groupId || "");
  }, [open]);

  const dispatch = useDispatch();

  const handleSave = async () => {
    setLoading(true);
    try {
      await HttpClient.client().put(`/users/${userId}`, {
        group_id: value || null,
      });
      dispatch(
        updateUser({ id: userId, changes: { group_id: value || null } })
      );
      dispatch(openSnackbar({ message: "Grupo salvo" }));
      onClose();
    } catch (error) {
      dispatch(setError({ title: "Erro ao editar grupo", error }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      {loading && <LinearProgress />}
      <DialogTitle height={55}>
        <Typography
          fontSize={"1rem"}
          color={"text.secondary"}
          variant="inherit"
        >
          Editar grupo
        </Typography>
        <Box flex={1} />
        <IconButton sx={{ m: -1 }} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box mt={1} display={"flex"} alignItems={"flex-end"}>
          <GroupSelector
            disableClearable
            initialValue={value || ""}
            onChange={setValue}
            variant="filled"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancelar
        </Button>
        <Box flex={1} />
        <Button
          startIcon={<DeleteOutlineOutlined />}
          onClick={() => setValue("")}
          color="inherit"
        >
          Limpar
        </Button>
        <Button disabled={loading} onClick={handleSave}>
          {loading ? "Salvando" : "Salvar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MenuView;
