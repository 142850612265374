import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";

const baseName = "paymentTypes";

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
    status: "idle",
    error: null,
});

//chamada c/ api rest;
export const fetchPaymentTypes = createAsyncThunk(`${baseName}/fetchPaymentTypes`, async () => {
    const res = await HttpClient.client().get(`/payment-types`);
    return res.data;
});

export const paymentTypesSlice = createSlice({
    name: baseName,
    initialState,
    reducers: {
        addPaymentType(state, action) {
            adapter.addOne(state, action.payload);
        },
        updatePaymentType(state, action) {
            const { id, changes } = action.payload
            adapter.updateOne(state, { changes, id });
        },
        removePaymentType(state, action) {
            adapter.removeOne(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPaymentTypes.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchPaymentTypes.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(fetchPaymentTypes.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.error = null;
                const data = action.payload;
                state.ids = data.ids;
                state.entities = data.entities;
            });
    },
});

export const { addPaymentType, removePaymentType, updatePaymentType } = paymentTypesSlice.actions;

export const {
    selectAll: selectAllPaymentTypes,
    selectIds: selectPaymentTypesIds,
    selectById: selectPaymentById,
    selectEntities: selectPaymentTypesEntities,
    selectTotal: selectTotalPaymentTypes,
} = adapter.getSelectors((state) => state[baseName]);

export default paymentTypesSlice.reducer;
