import { AccountTreeOutlined, HelpOutlineOutlined } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchApprovalFlows,
  selectApprovalFlowById,
} from "../../../../../../../../store/reducers/approvalFlowsSlice";
import { selectGroupById } from "../../../../../../../../store/reducers/groupsSlice";
import { selectUserById } from "../../../../../../../../store/reducers/usersSlice";
import DetailListItem from "../../../../../components/DetailListItem";
import ModalView from "./ModalView";

function ApFlowItem({ apFlowStatus, userId, apflowId, groupId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (apFlowStatus === "idle") {
      dispatch(fetchApprovalFlows());
    }
  }, [userId]);

  const [open, setOpen] = useState(false);

  const groupData = useSelector((state) => selectGroupById(state, groupId));

  const apFlow = useSelector((s) =>
    selectApprovalFlowById(s, apflowId || groupData?.apflowId)
  );

  const isFromGroup = !apflowId && groupData?.apflowId && apFlow;

  return (
    <>
      <DetailListItem
        emptyText={"Nenhum fluxo vinculado"}
        Icon={AccountTreeOutlined}
        title={"Fluxo de aprovação"}
        loading={apFlowStatus === "loading"}
        onClick={() => setOpen(true)}
        focused={open}
      >
        {apFlow ? (
          <Typography variant="inherit" fontWeight={"500"} component={"span"}>
            {isFromGroup ? `DO GRUPO - ${apFlow?.title}` : `${apFlow?.title}`}
          </Typography>
        ) : (
          <Typography
            color={"text.secondary"}
            component={"span"}
            display={"flex"}
            variant="inherit"
            fontWeight={"400"}
            alignItems={"center"}
          >
            Sem fluxo de aprovação{" "}
            <Tooltip
              title="Colaboradores sem fluxo de aprovação poderão ser aprovados por
              qualquer aprovador do sistema"
            >
              <HelpOutlineOutlined
                sx={{ ml: 1, fontSize: "1.1rem" }}
                fontSize="small"
              />
            </Tooltip>
          </Typography>
        )}
      </DetailListItem>
      <ModalView
        isFromGroup={isFromGroup}
        apflowId={apflowId || groupData?.apflowId}
        data={apFlow}
        open={open}
        onClose={() => setOpen(false)}
        userId={userId}
        groupName={groupData?.name || ""}
        groupId={groupId}
      />
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { apflowId, group_id } = selectUserById(state, ownProps.userId) || {
    apflowId: null,
    group_id: "",
  };

  return {
    apflowId,
    apFlowStatus: state.approvalFlows.status,
    groupId: group_id,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(memo(ApFlowItem));
