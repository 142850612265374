import { Box } from "@mui/material";
import { useEffect, useState } from "react";

export default ({ children, visible }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const boxContent = document.getElementById("box_content");
    if (boxContent && visible) {
      boxContent.style.overflow = "hidden";
    } else {
      boxContent.style.overflow = "auto";
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      setMounted(true);
    } else {
      setTimeout(() => {
        setMounted(false);
      }, 400);
    }
  }, [visible]);

  return (
    <Box
      id="box_content"
      sx={{
        transition: ".2s ease",
        opacity: visible ? 1 : 0,
        transform: `scale(${visible ? "1" : "0.6"})`,
      }}
      position={"absolute"}
      zIndex={(t) => (visible ? t.zIndex.appBar + 100 : -1)}
      left={0}
      top={0}
      width={"100%"}
      height={"100%"}
      borderRadius={3}
      boxShadow={24}
      display={"flex"}
      flexDirection={"column"}
      bgcolor={"backgroundPrimary.main"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        position={"relative"}
        flex={1}
        flexBasis={0}
        overflow={"auto"}
      >
        {mounted && children}
      </Box>
    </Box>
  );
};
