import {
  Box,
  Collapse,
  List,
  ListItemButton,
  Radio,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectAllApprovalFlows,
  selectApprovalFlowById,
} from "../../store/reducers/approvalFlowsSlice";
import { selectGroupById } from "../../store/reducers/groupsSlice";

const RadioItem = memo(
  ({
    onClick = () => {},
    disabled,
    title = "",
    description = "",
    checked,
    divider,
  }) => {
    return (
      <ListItemButton
        disabled={disabled}
        onClick={onClick}
        selected={checked}
        sx={{ py: 1.5 }}
      >
        <Radio size="small" sx={{ mr: 0.5, ml: -1 }} checked={checked} />
        <Box>
          <Typography fontSize={".95rem"} fontWeight={"600"} variant="h5">
            {title}
          </Typography>
          <Typography
            fontSize={".875rem"}
            color={"text.secondary"}
            variant="inherit"
          >
            {description}
          </Typography>
        </Box>
      </ListItemButton>
    );
  }
);

const ListWrapper = ({ children }) => {
  return (
    <List
      disablePadding
      sx={{
        border: 1,
        borderColor: "divider",
        borderRadius: 2,
        overflow: "hidden",
        mb: 2,
      }}
    >
      {children}
    </List>
  );
};

const SelectApprovalFlowList = ({
  initialValue,
  onChange = () => {},
  groupId,
  disabled,
}) => {
  const [value, setValue] = useState(initialValue || "");

  const group = useSelector((state) => selectGroupById(state, groupId));
  const groupApflow = useSelector((state) =>
    selectApprovalFlowById(state, group?.apflowId)
  );

  const apFlows = useSelector(selectAllApprovalFlows);
  const [groupSelected, setGroupSelected] = useState(!value);

  const handleChange = (id) => {
    setValue(id);
    onChange(id);
  };

  return (
    <>
      {Boolean(groupApflow && group?.apflowId) && (
        <>
          <Collapse
            in={!Boolean(groupSelected && group?.apflowId && groupApflow)}
          >
            <Box
              borderRadius={3}
              p={2}
              mb={2}
              border={1}
              borderColor={"divider"}
              bgcolor={(t) => `${t.palette.warning.main}10`}
            >
              <Typography
                variant="body2"
                fontWeight={"500"}
                color={"text.primary"}
              >
                Você está definindo um fluxo de aprovação específico para o
                colaborador, o que impede que ele seja atribuído pelo fluxo do
                grupo atual.
              </Typography>
            </Box>
          </Collapse>
          <Typography
            mb={1}
            variant="body2"
            fontWeight={"500"}
            color={"text.secondary"}
          >
            Do grupo
          </Typography>
          <ListWrapper>
            <RadioItem
              disabled={disabled}
              title={groupApflow?.title}
              description={groupApflow?.notes || "Sem descrição"}
              checked={!value}
              onClick={() => {
                handleChange(null);
                setGroupSelected(true);
              }}
            />
          </ListWrapper>
        </>
      )}
      <Typography
        mb={1}
        variant="body2"
        fontWeight={"500"}
        color={"text.secondary"}
      >
        Cadastrados
      </Typography>
      <ListWrapper>
        {apFlows.length ? (
          apFlows
            ?.filter((apFlow) =>
              groupApflow && group?.apflowId
                ? apFlow?.id !== group?.apflowId
                : true
            )
            ?.map((apFlow, idx) => (
              <RadioItem
                disabled={disabled}
                key={apFlow.id}
                title={apFlow.title}
                description={apFlow.notes || `Sem descrição`}
                checked={apFlow.id === value}
                onClick={() => {
                  handleChange(apFlow.id);
                  setGroupSelected(false);
                }}
                divider={idx < apFlows.length - 1}
              />
            ))
        ) : (
          <Typography
            variant="body2"
            m={2}
            textAlign={"center"}
            color={"text.secondary"}
          >
            Nenhum fluxo cadastrado
          </Typography>
        )}
      </ListWrapper>
    </>
  );
};

export default memo(SelectApprovalFlowList);
