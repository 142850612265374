import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: '',
    placeholder: 'Pesquisar',
    type: '',
    slot1: null,
    slot2: null
}

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearchValue: (state, { payload }) => {
            state.value = payload
        },
        setSearchConfigs: (state, { payload }) => {
            if(payload.type){
                state.type = payload.type
            }
            if(payload.placeholder){
                state.placeholder = payload.placeholder
            }
        }
    }
})

export const { setSearchRef, setSearchConfigs, setSearchValue } = searchSlice.actions

export default searchSlice.reducer
export const searchInitialState = initialState;
