import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
} from "date-fns";
import { HttpClient } from "../../api/http-client";
import normalize from "../../utils/normalize";

const baseName = "expenseRequests";

const adapter = createEntityAdapter({
  selectId: (entity) => entity.id,
});

const initialState = adapter.getInitialState({
  working: false,
  status: "idle",
  reset: true,
  filters: {
    date: {
      type: "this-month",
      meta: {},
    },
  },
  activeSavedView: null,
});

export const fetchExpenseRequests = createAsyncThunk(
  `${baseName}/fetchExpenseRequests`,
  async ({ filters, skip = undefined }) => {
    const { type, meta } = filters?.date;

    const today = startOfDay(new Date());
    const yesterday = subDays(today, 1);

    const from_date =
      type === "today"
        ? today
        : type === "yesterday"
        ? startOfDay(yesterday)
        : type === "this-month"
        ? startOfMonth(today)
        : type === "this-year"
        ? startOfYear(today)
        : type === "last-month"
        ? startOfMonth(subMonths(today, 1))
        : type === "last-90-days"
        ? subDays(today, 90)
        : type === "custom"
        ? startOfDay(new Date(meta?.from))
        : undefined;

    const to_date =
      type === "today"
        ? endOfDay(today)
        : type === "yesterday"
        ? endOfDay(yesterday)
        : type === "this-month"
        ? endOfMonth(today)
        : type === "this-year"
        ? endOfYear(today)
        : type === "last-month"
        ? endOfMonth(subMonths(today, 1))
        : type === "last-90-days"
        ? today
        : type === "custom"
        ? endOfDay(new Date(meta?.to))
        : undefined;

    const res = await HttpClient.client().post(
      "/controlling/requests",
      {
        users: filters?.users,
        to_date,
        from_date,
        to_value: (filters?.amount && filters?.amount[1]) || undefined,
        from_value: (filters?.amount && filters?.amount[0]) || undefined,
        status: filters?.forFinish
          ? ["A"]
          : filters?.status
          ? filters?.status
          : undefined,
        sap: filters?.sap || undefined,
        custom_fields: filters?.customFields || undefined,
        groups: filters?.groups || undefined,
        orgs: filters?.orgs || undefined,
        occupations: filters?.occupations || undefined,
      },
      {
        params: {
          // limit: 50,
          // skip,
          text: filters?.request,
        },
      }
    );
    return res.data;
  }
);

export const fetchRequestDetails = createAsyncThunk(
  `${baseName}/fetchRequestDetails`,
  async (requestId) => {
    const res = await HttpClient.client().get(
      `/controlling/requests/${requestId}`
    );
    return res.data;
  }
);

export const expensesRequestsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setupRequestsTable(state, { payload }) {
      state.status = "idle";
      state.reset = false;
    },

    setTableWorking(state, { payload }) {
      state.working = Boolean(payload);
    },
    resetRequestsTable(state) {
      state.filters = initialState.filters;
      state.activeSavedView = null;
      state.reset = false;
    },

    updateRequest(state, { payload }) {
      adapter.updateOne(state, payload);
    },
    updateManyRequests(state, { payload }) {
      const { ids, changes } = payload;
      adapter.updateMany(
        state,
        ids?.map((id) => ({
          id,
          changes,
        }))
      );
    },

    //Atualizar status sap
    updateRequestStatusSap(state, { payload }) {
      const { requestId, status } = payload;
      if (state.entities[requestId]) {
        state.entities[requestId].sap.status = status;
        state.entities[requestId].sap_processing = false;
      }
    },

    onChangeRequestsTableFilters(state, { payload }) {
      const { filter, value } = payload;
      state.filters[filter] = value;
    },
    removeRequestsTableFilter(state, { payload }) {
      delete state.filters[payload];
    },
    setAllRequestsFilters(state, { payload }) {
      if (!payload) return;
      state.filters = payload;
    },
    clearRequestsTableFilter(state) {
      state.filters = {
        ...initialState.filters,
        date: state.filters.date,
      };
    },

    //table views
    setTableActiveView(state, { payload }) {
      state.activeSavedView = payload;
    },
  },
  extraReducers: (builder) => {
    builder

      //ALL LIST
      .addCase(fetchExpenseRequests.pending, (state, action) => {
        state.working = true;
      })
      .addCase(fetchExpenseRequests.rejected, (state, action) => {
        state.working = false;
      })
      .addCase(fetchExpenseRequests.fulfilled, (state, action) => {
        state.working = false;
        state.status = "succeeded";
        const data = action.payload;
        const formatedData = data.map((request) => ({
          ...request,
          sap_processing: Boolean(request?.sap?.status === "processing"),
          id: request._id,
        }));
        const { ids, entities } = normalize(formatedData);
        adapter.setAll(state, entities);
      })

      //DETAIL
      .addCase(fetchRequestDetails.pending, (state, { meta: { arg } }) => {
        state.entities[arg].fetching = true;
      })
      .addCase(fetchRequestDetails.rejected, (state, { meta: { arg } }) => {
        state.entities[arg].fetching = false;
      })
      .addCase(
        fetchRequestDetails.fulfilled,
        (state, { meta: { arg }, payload }) => {
          state.entities[arg] = {
            ...state.entities[arg],
            ...payload,
            id: arg,
            sap_processing: Boolean(payload?.sap?.status === "processing"),
            fetching: false,
          };
        }
      );
  },
});

export const {
  onChangeRequestsTableFilters,
  removeRequestsTableFilter,
  setTableWorking,
  clearRequestsTableFilter,
  updateRequest,
  setupRequestsTable,
  resetRequestsTable,
  updateManyRequests,
  updateRequestStatusSap,
  setAllRequestsFilters,

  //table views
  setTableActiveView,
} = expensesRequestsSlice.actions;

export const {
  selectAll: selectAllExpenseRequests,
  selectIds: selectExpenseRequestsIds,
  selectById: selectExpenseRequestById,
  selectEntities: selectExpenseRequestsEntities,
  selectTotal: selectTotalExpenseRequests,
} = adapter.getSelectors((state) => state[baseName]);

export default expensesRequestsSlice.reducer;

export const selectRequestsTableFilters = (state) =>
  state.expenseRequests.filters;
