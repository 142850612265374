import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";

const baseName = "requestsLimits";

const initialState = {
  status: "idle",
  error: null,
  data: {},
};

export const fetchRequestsLimits = createAsyncThunk(
  `${baseName}/fetchRequestsLimits`,
  async () => {
    const res = await HttpClient.client().get(`/request-limits`);
    return res.data;
  }
);

export const requestsLimitsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    addRequestLimit(state, { payload }) {
      const typeId = payload?.typeId || payload?.type_id;
      if (!state.data[typeId]) {
        state.data[typeId] = [];
      }
      state.data[typeId].push(payload);
    },
    updateRequestLimit(state, { payload }) {
      const { typeId, limitId, changes } = payload;
      if (state.data.hasOwnProperty(typeId)) {
        const itemsArray = state.data[typeId];
        const itemIndex = itemsArray.findIndex((item) => item._id === limitId);
        if (itemIndex !== -1) {
          state.data[typeId][itemIndex] = {
            ...state.data[typeId][itemIndex],
            ...changes,
          };
        }
      }
    },
    removeRequestLimit(state, { payload }) {
      const { typeId, limitId } = payload;
      if (state.data.hasOwnProperty(typeId)) {
        const itemsArray = state.data[typeId];
        const itemIndex = itemsArray.findIndex((item) => item._id === limitId);
        if (itemIndex !== -1) {
          state.data[typeId].splice(itemIndex, 1);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequestsLimits.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchRequestsLimits.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchRequestsLimits.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        const data = action.payload;
        state.data = data?.expensesTypes || {};
      });
  },
});

export const { addRequestLimit, updateRequestLimit, removeRequestLimit } =
  requestsLimitsSlice.actions;

export const selectAllRequestsLimits = createSelector(
  [(state) => state.requestsLimits.data],
  (limits = {}) => {
    let allLimits = [];

    for (const [type_id, items] of Object.entries(limits)) {
      for (const item of items) {
        const itemWithTypeID = { ...item, type_id };
        allLimits.push(itemWithTypeID);
      }
    }

    return allLimits;
  }
);

export default requestsLimitsSlice.reducer;
