import {
  Close,
  DeleteOutlineOutlined,
  EditOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";

function ModalViewPhoto({ open, onClose, photo, onRemove, onEdit }) {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle display={"flex"} alignItems="center" component={"div"}>
        <IconButton sx={{ m: -1, mr: 0 }} onClick={onClose}>
          <Close />
        </IconButton>
        <Typography ml={2} fontWeight={"600"} fontSize={"1.1rem"}>
          Foto do colaborador
        </Typography>
        <Box flex={1} />
        <IconButton onClick={onEdit}>
          <EditOutlined />
        </IconButton>
        <IconButton onClick={onRemove}>
          <DeleteOutlineOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          pb: 5,
        }}
      >
        <Avatar
          sx={{
            width: 450,
            boxShadow: 2,
            height: 450,
            border: 1,
            borderColor: "divider",
          }}
          src={photo}
        ></Avatar>
      </DialogContent>
    </Dialog>
  );
}

export default ModalViewPhoto;
