import { List } from "@mui/material";
import React, { memo } from "react";
import BasePaper from "../../../components/BasePaper";
import ApFlowItem from "./items/apflow/ApFlowItem";
import GroupItem from "./items/group/GroupItem";
import NotesItem from "./items/notes/NotesItem";
import OrgItem from "./items/org/OrgItem";

function ConfigsCard({ userId }) {
  return (
    <BasePaper title={"Configurações do colaborador"}>
      <List disablePadding>
        <OrgItem userId={userId} />
        <GroupItem userId={userId} />
        <ApFlowItem userId={userId} />
        {/* <OfficeItem userId={userId} /> */}
        <NotesItem userId={userId} />
      </List>
    </BasePaper>
  );
}

export default memo(ConfigsCard);
