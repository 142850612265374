import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";

const initialState = {
  clientSecret: null,
  plan: {
    status: "idle",
    error: null,
    details: {},
  },
  nextInvoice: {
    status: "idle",
    error: null,
    data: {},
  },
  invoices: {
    status: "idle",
    error: null,
    data: [],
  },
  billingData: {
    status: "idle",
    error: null,
    data: {},
  },
  products: {
    status: "idle",
    error: null,
    data: [],
  },
  prices: {
    status: "idle",
    error: null,
    data: [],
  },
  payments: {
    status: "idle",
    error: null,
    data: [],
  },
};

export const billingInitialState = initialState;

export const fetchPlanDetails = createAsyncThunk(
  "billing/fetchPlanDetails",
  async () => {
    const res = await HttpClient.client().get("/billing/subscription");
    return res.data;
  }
);

export const fetchNotepayProducts = createAsyncThunk(
  "billing/fetchNotepayProducts",
  async () => {
    const res = await HttpClient.client().get("/billing/products");
    return res.data;
  }
);

export const fetchPrices = createAsyncThunk("billing/fetchPrices", async () => {
  const res = await HttpClient.client().get("/billing/products/prices");
  return res.data;
});

export const fetchPaymentMethods = createAsyncThunk(
  "billing/fetchPaymentMethods",
  async () => {
    const res = await HttpClient.client().get("/billing/paymentMethods");
    return res.data;
  }
);

export const fetchNextInvoice = createAsyncThunk(
  "billing/fetchNextInvoice",
  async () => {
    const res = await HttpClient.client().get("/billing/upcoming/invoice");
    return res.data;
  }
);
export const fetchInvoices = createAsyncThunk(
  "billing/fetchInvoices",
  async () => {
    const res = await HttpClient.client().get("/billing/invoices");
    return res.data;
  }
);

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setClientSecret(state, { payload }) {
      state.clientSecret = payload;
    },
    setBillingData(state, { payload }) {
      state.plan.details.billingInfo = {
        ...(state.plan.details.billingInfo || null),
        ...payload,
      };
    },
    removePaymentMethod(state, { payload }) {
      let payments = [...state.payments.data];
      payments.splice(
        payments.findIndex((pay) => pay.id === payload),
        1
      );
      state.payments.data = payments;
      if (
        state.plan.details.customer.invoice_settings.default_payment_method ===
        payload
      ) {
        const boletoId = state.payments.data.find(
          (pay) => pay.type === "boleto"
        )?.id;
        if (boletoId) {
          state.plan.details.customer.invoice_settings.default_payment_method =
            boletoId;
        }
      }
    },
    setDefaultPaymentMethod(state, { payload }) {
      state.plan.details.customer.invoice_settings.default_payment_method =
        payload;
    },
  },
  extraReducers: (builder) =>
    builder

      //plan details actions
      .addCase(fetchPlanDetails.pending, (state, { payload }) => {
        state.plan.error = null;
        state.plan.status = "loading";
      })
      .addCase(fetchPlanDetails.rejected, (state, { error }) => {
        state.plan.status = "failed";
        state.plan.error = error.message;
      })
      .addCase(fetchPlanDetails.fulfilled, (state, { payload }) => {
        state.plan.status = "succeeded";
        state.plan.error = null;
        state.plan.details = payload;
        console.log(payload);
      })

      //products actions
      .addCase(fetchNotepayProducts.pending, (state, { payload }) => {
        state.products.error = null;
        state.products.status = "loading";
      })
      .addCase(fetchNotepayProducts.rejected, (state, { error }) => {
        state.products.status = "failed";
        state.products.error = error.message;
      })
      .addCase(fetchNotepayProducts.fulfilled, (state, { payload }) => {
        state.products.status = "succeeded";
        state.products.error = null;
        state.products.data = payload;
      })

      //prices actions
      .addCase(fetchPrices.pending, (state, { payload }) => {
        state.prices.error = null;
        state.prices.status = "loading";
      })
      .addCase(fetchPrices.rejected, (state, { error }) => {
        state.prices.status = "failed";
        state.prices.error = error.message;
      })
      .addCase(fetchPrices.fulfilled, (state, { payload }) => {
        state.prices.status = "succeeded";
        state.prices.error = null;
        state.prices.data = payload;
      })

      //prices actions
      .addCase(fetchPaymentMethods.pending, (state, { payload }) => {
        state.payments.error = null;
        state.payments.status = "loading";
      })
      .addCase(fetchPaymentMethods.rejected, (state, { error }) => {
        state.payments.status = "failed";
        state.payments.error = error.message;
      })
      .addCase(fetchPaymentMethods.fulfilled, (state, { payload }) => {
        state.payments.status = "succeeded";
        state.payments.error = null;
        state.payments.data = payload;
      })

      //next invoice
      .addCase(fetchNextInvoice.pending, (state, { payload }) => {
        state.nextInvoice.error = null;
        state.nextInvoice.status = "loading";
      })
      .addCase(fetchNextInvoice.rejected, (state, { error }) => {
        state.nextInvoice.status = "failed";
        state.nextInvoice.error = error.message;
      })
      .addCase(fetchNextInvoice.fulfilled, (state, { payload }) => {
        state.nextInvoice.status = "succeeded";
        state.nextInvoice.error = null;
        state.nextInvoice.data = payload;
      })

      //all invoice
      .addCase(fetchInvoices.pending, (state, { payload }) => {
        state.invoices.error = null;
        state.invoices.status = "loading";
      })
      .addCase(fetchInvoices.rejected, (state, { error }) => {
        state.invoices.status = "failed";
        state.invoices.error = error.message;
      })
      .addCase(fetchInvoices.fulfilled, (state, { payload }) => {
        state.invoices.status = "succeeded";
        state.invoices.error = null;
        state.invoices.data = payload.data;
      }),
});

export const {
  setClientSecret,
  setBillingData,
  setDefaultPaymentMethod,
  removePaymentMethod,
} = billingSlice.actions;

export default billingSlice.reducer;

const selectPrices = (state) => state.billing.prices.data;
const selectProducts = (state) => state.billing.products.data;

export const selectCurrentProductId = (state) =>
  state.billing.plan.details?.billingInfo?.product_id;

export const selectPriceById = createSelector(
  [selectPrices, (state, priceId) => priceId],
  (prices, priceId) => {
    return prices.find((price) => price.id === priceId) || {};
  }
);

export const selectProductById = createSelector(
  [selectProducts, (state, productId) => productId],
  (products, productId) => {
    return products.find((item) => item._id === productId) || {};
  }
);

export const selectPlanConfigurationIsLoading = createSelector(
  [(state) => state.billing],
  (billing) => {
    return Boolean(
      billing.plan.status === "loading" ||
        billing.products.status === "loading" ||
        billing.prices.status === "loading"
    );
  }
);
export const selectStatusPlanConfigurations = createSelector(
  [(state) => state.billing],
  (billing) => {
    return {
      plan: billing.plan.status,
      prices: billing.plan.status,
      products: billing.plan.status,
    };
  }
);

export const formatBillingDataFromAPI = (data = null) => {
  if (!data) return {};
  return {
    name: data.fiscal_name,
    fiscalId: data.cnpj || data.cpf,
    country: data.address.country,
    cep: data.address.cep,
    region: data.address.state,
    cityId: data.address.ibge,
    cityName: data.address.city,
    district: data.address.bairro,
    street: data.address.logradouro,
    complement: data.address.complemento,
    streetNumber: data.address.numero,
    corporateEmail: data.fiscal_email,
    personType: data.person_type === "PJ" ? "CNPJ" : "CPF",
  };
};

export const formatBillingDataToAPI = (data = {}) => {
  return {
    fiscal_name: data.name,
    cnpj: data.fiscalId,
    cpf: data.fiscalId,
    address: {
      country: data.country,
      state: data.region,
      city: data.cityName,
      ibge: data.cityId,
      bairro: data.district,
      logradouro: data.street,
      numero: data.streetNumber,
      cep: data.cep,
    },
    person_type: data.personType === "CPF" ? "PF" : "PJ",
    fiscal_email: data.corporateEmail,
  };
};
