import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HttpClient } from "../../../api/http-client";
import TextInput from "../../../components/inputs/text-input";
import { setError } from "../../../store/reducers/bases/errorBaseSlice";
import { openSnackbar } from "../../../store/reducers/bases/snackbarBaseSlice";
import { addOrg } from "../../../store/reducers/orgsSlice";

function ModalCreateOrg({ open, onClose, onCreate = () => {} }) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setLoading(false);
      setName("");
    }
  }, [open]);

  const handleCreate = async () => {
    setLoading(true);
    try {
      const { data } = await HttpClient.client().post("/organizations", {
        name,
      });
      dispatch(addOrg({ id: data.id, name }));
      dispatch(openSnackbar({ message: "Organização criada" }));
      setName("");
      onClose();
      onCreate(data.id);
    } catch (error) {
      dispatch(
        setError({
          title: "Erro ao criar organização",
          error,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      {loading && <LinearProgress />}
      <DialogTitle>Criar organização</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Organizações podem ajudar a agrupar sua equipe.
        </DialogContentText>
        <TextInput
          value={name}
          placeholder={"Ex: Nome da filial"}
          onChange={setName}
          autoFocus
          required
          margin="normal"
          label="Nome"
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          disabled={!Boolean(name.trim().length) || loading}
          onClick={handleCreate}
          variant="contained"
          disableElevation
        >
          Criar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalCreateOrg;
