import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";
  
  const baseName = "occupations";
  
  const adapter = createEntityAdapter();
  
  const initialState = adapter.getInitialState({
    status: "idle",
    error: null,
  });
  
  //chamada c/ api rest;
  export const fetchOccupations = createAsyncThunk(`${baseName}/fetchOccupations`, async () => {
    const res = await HttpClient.client().get(`/occupations`);
    return res.data;
  });
  
  export const occupationsSlice = createSlice({
    name: baseName,
    initialState,
    reducers: {
      addOccupation(state, action) {
        adapter.addOne(state, action.payload);
      },
      updateOccupation(state, action) {
        const {id, changes} = action.payload
        adapter.updateOne(state, {changes, id});
      },
      removeOccupation(state, action) {
        adapter.removeOne(state, action.payload);
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchOccupations.pending, (state, action) => {
          state.status = "loading";
        })
        .addCase(fetchOccupations.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        })
        .addCase(fetchOccupations.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.error = null;
          const data = action.payload;
          state.ids = data.ids;
          state.entities = data.entities;
        });
    },
  });
  
  export const { addOccupation, removeOccupation, updateOccupation} = occupationsSlice.actions;
  
  export const {
    selectAll: selectAllOccupations,
    selectIds: selectOccupationsIds,
    selectById: selectOccupationById,
    selectEntities: selectOccupationsEntities,
    selectTotal: selectTotalOccupations,
  } = adapter.getSelectors((state) => state[baseName]);
  
  export default occupationsSlice.reducer;
  