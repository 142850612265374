import { Box } from "@mui/material";
import React, { memo } from "react";

function SectionLine({ children, Icon }) {
  return (
    <Box width={"100%"} display={"flex"} alignItems="flex-start">
      {Icon && <Icon color="action" sx={{  mt: 3.5, opacity: .85}} />}
      <Box ml={Icon ? 3 : 6} width={"100%"} display={"flex"} flexDirection="column" gap={2}>
        {children}
      </Box>
    </Box>
  );
}

export default memo(SectionLine);
