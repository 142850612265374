import { BusinessOutlined } from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";
import React, { memo, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectAccountStatus,
  selectProfilePhotoUrl,
} from "../../store/reducers/accountSlice";
import { selectUserById } from "../../store/reducers/usersSlice";
import getAccountLogoUrl from "../../utils/getAccountLogoUrl";
import ButtonContainer from "./ButtonContainer";
import AccountMenu from "./menu/AccountMenu";

function AccountIndicator(props) {
  const [menu, setMenu] = useState(null);
  const logoVersion = useSelector((state) => state.account.logoVersion);
  const user = useSelector((state) => state.account.data);

  const accountStatus = useSelector(selectAccountStatus);

  const {
    name: accountName,
    _id,
    lastname: accountLastname,
    email,
    account: { name: companyName, _id: accountId },
  } = user || {
    _id: undefined,
    name: "",
    lastname: "",
    email: "",
    account: { name: "" },
  };

  const userStoreData = useSelector((s) => selectUserById(s, _id));
  const hasLogo = useSelector((state) => state.account.data.hasLogo);

  const { name, lastname } = userStoreData || {
    name: accountName || "",
    lastname: accountLastname || "",
  };

  const logoURL = useMemo(
    () => getAccountLogoUrl(accountId),
    [accountId, logoVersion]
  );

  const profilePhotoSrc = useSelector(selectProfilePhotoUrl);

  return (
    <>
      <ButtonContainer
        companyName={companyName}
        email={email}
        lastname={lastname}
        name={name}
        onClick={(e) => setMenu(e.target)}
        selected={Boolean(menu)}
        accountStatus={accountStatus}
      >
        <Box
          textOverflow={"ellipsis"}
          overflow={"hidden"}
          flex={1}
          pl={0.3}
          display={{ xs: "none", md: "flex" }}
          alignItems={"center"}
        >
          {hasLogo ? (
            <img style={{ width: "97%", height: 45 }} src={logoURL} />
          ) : (
            <>
              <BusinessOutlined
                sx={{ fontSize: "1rem", ml: 1, opacity: 0.6 }}
              />
              <Typography noWrap ml={1} fontSize={".8rem"} fontWeight={"600"}>
                {companyName}
              </Typography>
            </>
          )}
        </Box>
        <Avatar
          src={profilePhotoSrc}
          sx={{
            ml: {
              xs: 0.3,
              md: 1,
            },
          }}
        >
          {name[0] && name[0]?.toUpperCase()}
        </Avatar>
      </ButtonContainer>
      {Boolean(menu) && (
        <AccountMenu
          anchorEl={menu}
          onClose={() => setMenu(null)}
          companyName={companyName}
          email={email}
          lastname={lastname}
          logo={hasLogo ? logoURL : null}
          name={name}
          occupation={""}
          org={""}
          userId={_id}
          profilePhotoSrc={profilePhotoSrc}
          accountStatus={accountStatus}
        />
      )}
    </>
  );
}

export default memo(AccountIndicator);
