import {
  AdminPanelSettingsOutlined,
  BadgeOutlined,
  BusinessCenterOutlined,
  SettingsOutlined,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthClient } from "../../../../api/auth";
import ModalChangePassword from "../../../../modals/change-password/ModalChangePassword";
import ModalChangePersonalData from "../../../../modals/change-personal-data/ModalChangePersonalData";
import {
  selectAccountIsMaster,
  updateProfilePhotoUrl,
} from "../../../../store/reducers/accountSlice";
import { setError } from "../../../../store/reducers/bases/errorBaseSlice";
import { openSnackbar } from "../../../../store/reducers/bases/snackbarBaseSlice";
import {
  fetchOccupations,
  selectOccupationsEntities,
} from "../../../../store/reducers/occupationsSlice";
import {
  fetchOrgs,
  selectOrgsEntities,
} from "../../../../store/reducers/orgsSlice";
import { selectUserById } from "../../../../store/reducers/usersSlice";
import { blobToBase64 } from "../../../../utils/more/images_transform";
import AvatarPicker from "./components/AvatarPicker";
import SectionActionButton from "./components/SectionActionButton";
import SectionActionPaper from "./components/SectionActionPaper";
import SectionPaper from "./components/SectionPaper";

const SettingsButton = memo(() => {
  const [menu, setMenu] = useState(null);
  const [changePassModal, setChangePassModal] = useState(false);
  const [changeDataModal, setChangeDataModal] = useState(false);
  return (
    <>
      <IconButton onClick={(e) => setMenu(e.target)}>
        <SettingsOutlined />
      </IconButton>
      <Menu
        slotProps={{
          paper: {
            elevation: 5,
          },
        }}
        anchorEl={menu}
        onClose={() => setMenu(null)}
        open={Boolean(menu)}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem
          onClick={() => {
            setChangeDataModal(true);
            setMenu(null);
          }}
        >
          Editar dados pessoais
        </MenuItem>
        <MenuItem
          onClick={() => {
            setChangePassModal(true);
            setMenu(null);
          }}
        >
          Alterar senha de acesso
        </MenuItem>
      </Menu>
      <ModalChangePassword
        open={changePassModal}
        onClose={() => setChangePassModal(false)}
      />
      <ModalChangePersonalData
        open={changeDataModal}
        onClose={() => setChangeDataModal(false)}
      />
    </>
  );
});

function BaseInfo({
  name,
  lastname,
  userId,
  email,
  initialOccupation,
  initialOrg,
  profilePhotoSrc,
  closeMenu,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector((s) => selectUserById(s, userId));

  const isMaster = useSelector(selectAccountIsMaster);

  const { org, occupation } = userData || {
    org: initialOrg || "",
    occupation: initialOccupation || "",
  };

  const orgsEntities = useSelector(selectOrgsEntities);
  const occupationsEntities = useSelector(selectOccupationsEntities);

  const orgsStatus = useSelector((s) => s.orgs.status);
  const occupationsStatus = useSelector((s) => s.occupations.status);

  useEffect(() => {
    if (orgsStatus === "idle") {
      dispatch(fetchOrgs);
    }
    if (occupationsStatus === "idle") {
      dispatch(fetchOccupations);
    }
  }, []);

  const handleChangeUserPhoto = async (photo) => {
    const base64 = await blobToBase64(photo);
    try {
      await AuthClient.client().put(`/profile`, {
        profilePicBase64: base64,
      });
      fetch(profilePhotoSrc, {
        headers: {
          "force-revalidate": 1,
        },
      }).then(() => {
        dispatch(updateProfilePhotoUrl(photo));
      });
      dispatch(openSnackbar({ message: "Foto salva" }));
    } catch (error) {
      dispatch(setError({ title: "Erro ao salvar foto de perfil", error }));
    }
  };
  const handleRemoveUserPhoto = async (photo) => {
    try {
      await AuthClient.client().put(`/profile`, {
        deleteProfilePic: true,
      });
      fetch(profilePhotoSrc, {
        headers: {
          "force-revalidate": 1,
        },
      }).then(() => {
        dispatch(updateProfilePhotoUrl(null));
      });
      dispatch(openSnackbar({ message: "Foto removida" }));
    } catch (error) {
      dispatch(setError({ title: "Erro ao remover foto de perfil", error }));
    }
  };

  return (
    <>
      <SectionPaper
        sx={{
          display: "flex",
          alignItems: "flex-start",
          borderBottomLeftRadius: !isMaster ? 20 : 0,
          borderBottomRightRadius: !isMaster ? 20 : 0,
        }}
      >
        <AvatarPicker
          name={name}
          userId={userId}
          photo={profilePhotoSrc || null}
          onChange={handleChangeUserPhoto}
          onRemove={handleRemoveUserPhoto}
          showAlertRemove
          closeOnChange={false}
        />
        <Box ml={2} width={"100%"}>
          <Box display={"flex"} alignItems={"center"}>
            <Box flex={1}>
              <Typography fontWeight={"600"} fontSize={"1.2rem"}>
                {name} {lastname || ""}
              </Typography>
              <Typography
                sx={{ wordBreak: "break-all" }}
                fontSize={".85rem"}
                color={"text.secondary"}
              >
                {email}
              </Typography>
            </Box>
            <SettingsButton />
          </Box>
          <Divider sx={{ my: 1 }} />
          {isMaster && (
            <Typography
              display={"flex"}
              alignItems={"center"}
              fontSize={".8rem"}
              color={"text.primary"}
              fontWeight={"500"}
              mb={1}
            >
              <WorkspacePremiumOutlined
                color="primary"
                sx={{ fontSize: ".9rem", mr: 1 }}
              />
              Proprietário
            </Typography>
          )}
          <Typography
            display={"flex"}
            alignItems={"center"}
            fontSize={".8rem"}
            color={"text.secondary"}
            mb={0.3}
            fontStyle={org ? "normal" : "italic"}
          >
            <BusinessCenterOutlined
              color="action"
              sx={{ fontSize: ".9rem", mr: 1 }}
            />
            {orgsStatus === "loading" ? (
              <Skeleton width={"100%"} />
            ) : (
              (org && orgsEntities[org].name) || "Sem organização"
            )}
          </Typography>
          <Typography
            display={"flex"}
            alignItems={"center"}
            fontSize={".8rem"}
            color={"text.secondary"}
            fontStyle={occupation ? "normal" : "italic"}
          >
            <BadgeOutlined color="action" sx={{ fontSize: ".9rem", mr: 1 }} />
            {occupationsStatus === "loading" ? (
              <Skeleton width={"100%"} />
            ) : (
              (occupation && occupationsEntities[occupation].name) ||
              "Sem cargo"
            )}
          </Typography>
        </Box>
      </SectionPaper>
      {isMaster && (
        <SectionActionPaper>
          <SectionActionButton
            onClick={() => {
              navigate("/advanced-settings");
              closeMenu();
            }}
            Icon={AdminPanelSettingsOutlined}
          >
            Configurações da conta
          </SectionActionButton>
        </SectionActionPaper>
      )}
    </>
  );
}

export default memo(BaseInfo);
