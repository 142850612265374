import {
  Add,
  BusinessCenterOutlined,
  BusinessOutlined,
  Check,
  Close,
  DomainDisabledOutlined,
  EditOutlined,
  MoreVert,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HttpClient } from "../../../../api/http-client";
import HelpIconButton from "../../../../components/buttons/HelpIconButton";
import NoDataContent from "../../../../components/form/NoDataContent";
import TextInput from "../../../../components/inputs/text-input";
import LoadingSection from "../../../../components/loading/LoadingSection";
import ModalDelete from "../../../../modals/ModalDelete";
import ModalCreateOrg from "../../../../modals/orgs/create-org/ModalCreateOrg";
import { selectHasSettingsPermission } from "../../../../store/reducers/accountSlice";
import { openSnackbar } from "../../../../store/reducers/bases/snackbarBaseSlice";
import {
  fetchOrgs,
  removeOrg,
  selectOrgById,
  selectOrgsIds,
  updateOrg,
} from "../../../../store/reducers/orgsSlice";
import {
  selectUsersIdsByOrg,
  updateManyUsers,
} from "../../../../store/reducers/usersSlice";
import { HighlightedText } from "../../../../utils/functions/highlightText";

const RemoveItem = ({ orgId }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const usersIdsInOrg = useSelector((s) => selectUsersIdsByOrg(s, orgId));

  const handleDelete = async () => {
    try {
      await HttpClient.client().delete(`/organizations/${orgId}`);
      dispatch(removeOrg(orgId));
      dispatch(openSnackbar({ message: "Organização removida" }));
      dispatch(updateManyUsers({ ids: usersIdsInOrg, changes: { org: null } }));
    } catch (error) {}
  };

  return (
    <>
      <MenuItem
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        Excluir
      </MenuItem>
      <ModalDelete
        open={open}
        onClose={() => setOpen(false)}
        title="Exlcuir a organização"
        onDelete={handleDelete}
      />
    </>
  );
};

const OrgItemMore = ({ orgId, onClose, disableRemoveButton }) => {
  const [menu, setMenu] = useState(null);
  const navigate = useNavigate();
  return (
    <>
      <IconButton
        sx={{ bgcolor: Boolean(menu) && "action.focus" }}
        onClick={(e) => setMenu(e.target)}
      >
        <MoreVert fontSize="small" />
      </IconButton>
      <Menu anchorEl={menu} open={Boolean(menu)} onClose={() => setMenu(null)}>
        {!disableRemoveButton && <RemoveItem orgId={orgId} />}
      </Menu>
    </>
  );
};

export const OrgItem = memo(
  ({
    orgId,
    onClose,
    searchTerm,
    activeHighlightedText,
    textSx = {},
    disableRemoveButton,
    activeIcon,
    hasPermission,
  }) => {
    const dispatch = useDispatch();

    const [editMode, setEditMode] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [loading, setLoading] = useState(false);

    const { name } = useSelector((s) => selectOrgById(s, orgId));

    const inputRef = useRef();

    const handleSave = async () => {
      setLoading(true);
      try {
        const newName = inputRef.current?.value;
        await HttpClient.client().put(`/organizations/${orgId}`, {
          name: newName,
        });
        dispatch(updateOrg({ id: orgId, changes: { name: newName } }));
        dispatch(openSnackbar({ message: "Organização salva" }));
      } catch (error) {
      } finally {
        setEditMode(false);
        setLoading(false);
      }
    };

    return (
      <ListItem
        onMouseLeave={() => setHovered(false)}
        onMouseEnter={() => setHovered(true)}
        sx={{ height: 50, ":hover": { bgcolor: "action.hover" } }}
      >
        {editMode ? (
          <>
            <Tooltip title="Cancelar">
              <IconButton onClick={() => setEditMode(false)} sx={{ ml: -1 }}>
                <Close fontSize="small" />
              </IconButton>
            </Tooltip>
            <Box
              flex={1}
              component={"form"}
              onSubmit={(e) => {
                e.preventDefault();
                handleSave();
              }}
            >
              <TextInput
                inputRef={inputRef}
                autoFocus
                value={name}
                activeInternalValue
                margin={"none"}
                variant={"standard"}
              />
            </Box>
          </>
        ) : (
          <>
            {activeIcon && (
              <BusinessOutlined color="action" sx={{ mr: 2, ml: 0.8 }} />
            )}
            <ListItemText
              primaryTypographyProps={{
                fontWeight: "600",
                fontSize: "1rem",
                ...textSx,
              }}
              primary={
                activeHighlightedText ? (
                  <HighlightedText searchTerm={searchTerm} text={name} />
                ) : (
                  name
                )
              }
            />
          </>
        )}
        {editMode ? (
          <>
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <Tooltip title="Salvar">
                <IconButton onClick={handleSave} color="primary">
                  <Check />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : (
          hovered &&
          hasPermission && (
            <>
              <IconButton onClick={() => setEditMode(true)}>
                <EditOutlined fontSize="small" />
              </IconButton>
              <OrgItemMore
                onClose={onClose}
                orgId={orgId}
                disableRemoveButton={disableRemoveButton}
              />
            </>
          )
        )}
      </ListItem>
    );
  }
);

const CreateButton = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        variant="outlined"
        startIcon={<Add />}
        sx={{ borderRadius: 100 }}
        onClick={() => setOpen(true)}
      >
        Criar nova
      </Button>
      <ModalCreateOrg open={open} onClose={() => setOpen(false)} />
    </>
  );
};

function MenuOrgs({ open, onClose }) {
  const dispatch = useDispatch();

  const orgsIds = useSelector(selectOrgsIds);
  const hasPermission = useSelector(selectHasSettingsPermission);

  const status = useSelector((state) => state.orgs.status);

  useEffect(() => {
    if (open) {
      if (status === "idle") {
        dispatch(fetchOrgs());
      }
    }
  }, [open]);

  return (
    <Card variant="outlined" sx={{ width: "100%", borderRadius: 3 }}>
      <CardContent sx={{ p: 0, pb: 3 }}>
        <Box
          borderBottom={1}
          borderColor={"divider"}
          p={2}
          py={1}
          display={"flex"}
          alignItems={"center"}
        >
          <BusinessCenterOutlined color="action" />
          <Typography ml={2} fontSize={"1.2rem"} fontWeight={"600"}>
            Organizações ({orgsIds.length})
          </Typography>
          <Box flex={1} />
          {hasPermission && (
            <>
              <CardActions sx={{ mr: 1 }}>
                <CreateButton />
              </CardActions>
            </>
          )}
          <HelpIconButton size="medium" title={"Organizações"}>
            As organizações podem te ajudar a organizar e visualizar melhor os
            colaboradores da conta
          </HelpIconButton>
        </Box>
        <List disablePadding>
          {status === "loading" ? (
            <LoadingSection h={150} />
          ) : (
            <>
              {!Boolean(orgsIds.length) && (
                <NoDataContent
                  height={120}
                  titleSize="1rem"
                  iconSize="3rem"
                  title={"Nenhuma organização"}
                  Icon={DomainDisabledOutlined}
                />
              )}
              {orgsIds.map((orgId) => (
                <OrgItem
                  hasPermission={hasPermission}
                  key={orgId}
                  orgId={orgId}
                  onClose={onClose}
                />
              ))}
            </>
          )}
        </List>
      </CardContent>
    </Card>
  );
}

export default memo(MenuOrgs);
