import {
  saveAccount,
  setAccessExpiresAt,
} from "../../store/reducers/accountSlice";
import { store } from "../../store/store";
import { AuthAPIGateway, AuthClient } from "../auth";
import { HttpClient } from "../http-client";
import updateReceiptsAccessToken from "../updateReceiptsAccessToken";
import { AUTH_BASE_URL } from "../urls";
import WebSockets from "../websockets";

const checkReceiptsAccessToken = async (accessToken) => {
  //Dados de acesso para comprovantes
  const receiptsAccess = store.getState().account?.receiptsAccessToken || null;
  if (!receiptsAccess?.token || !receiptsAccess?.expiresAt) {
    await updateReceiptsAccessToken(accessToken);
    return;
  }
  const now = new Date().getTime();
  const expiresAt = new Date(receiptsAccess?.expiresAt).getTime();
  if (now > expiresAt) {
    await updateReceiptsAccessToken(accessToken);
  }
};

const updateUserData = (data = {}) => {
  if (data?.email) {
    window.localStorage.setItem("email", data?.email);
  }
  store.dispatch(saveAccount(data));
};

const setupAccessToken = (accessToken) => {
  HttpClient.setAccessToken(accessToken);
  AuthClient.setAccessToken(accessToken);
  WebSockets.setAccessToken(accessToken);
};

const handleDebugAuthentication = async () => {
  try {
    const d = await fetch(`${AUTH_BASE_URL}/auth/v1/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: process.env.REACT_APP_USER,
        password: process.env.REACT_APP_PASSWORD,
        debug: true,
      }),
    });
    const data = await d.json();
    const { accessToken, user, expiresAt } = data;

    if (expiresAt) {
      store.dispatch(setAccessExpiresAt(expiresAt));
    }

    // Set access token
    await checkReceiptsAccessToken(accessToken);
    setupAccessToken(accessToken);
    if (user) {
      updateUserData(user);
    }

    // Autenticar usuário p/ suporte do Zendesk
    if (window.zE) {
      window.zE("messenger", "loginUser", function (callback) {
        callback(data.zendeskToken);
      });
    }

    return {
      isAuth: true,
      accessToken,
    };
  } catch (error) {
    window.alert("Ocorreu uma falha na autenticação");
    return {
      isAuth: false,
      accessToken: null,
    };
  }
};

export const refreshToken = async ({ onError }) => {
  if (process.env.REACT_APP_DEBUG === "true") {
    return handleDebugAuthentication();
  }
  try {
    const { data } = await AuthAPIGateway.refreshToken();
    const { accessToken, user, expiresAt } = data;

    if (expiresAt) {
      store.dispatch(setAccessExpiresAt(expiresAt));
    }

    // Set access token
    await checkReceiptsAccessToken(accessToken);
    setupAccessToken(accessToken);

    // Autenticar usuário p/ suporte do Zendesk
    if (window.zE) {
      window.zE("messenger", "loginUser", function (callback) {
        callback(data.zendeskToken);
      });
    }

    // Return authentication information
    if (user) {
      updateUserData(user);
    }

    return { isAuth: true, accessToken };
  } catch (error) {
    if (onError && typeof onError === "function") {
      onError(error);
    }
    if (error.response?.status === 401) {
      // not authorized, redirect to authentication page
      const email = window.localStorage.getItem("email");
      const redirectUrl = window.location;
      const urlParams = `${
        email ? `email=${email}&` : ""
      }redirect=${redirectUrl}`;
      if (email) {
        window.location.replace(`${AUTH_BASE_URL}?step=1&${urlParams}`);
      } else {
        window.location.replace(`${AUTH_BASE_URL}?step=0&${urlParams}`);
      }
    }
    // Return authentication information
    return {
      isAuth: false,
      accessToken: null,
    };
  }
};

export const startRefreshingToken = () => {
  let interval;
  interval = setInterval(async () => {
    const { isAuth } = await refreshToken({
      onError: null,
    });
    if (!isAuth) {
      clearInterval(interval);
    }
  }, 360000);
};

export const getAccount = async () => {
  try {
    const {
      data: { user },
    } = await AuthAPIGateway.getMe();
    if (!user) return {};

    // Save email in local storage
    if (user.email) {
      window.localStorage.setItem("email", user.email);
    }

    return {
      ok: true,
      data: user,
      error: null,
    };
  } catch (error) {
    return {
      ok: false,
      data: {},
      error,
    };
  }
};
