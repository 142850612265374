import { store } from "../../store/store";
import { AUTH_BASE_URL } from "../urls";
import sapIntegrationMessageCapture from "./sapIntegrationMessageCapture";

const logout = () => {
  const currentEmail = window.localStorage.getItem("email") || "";
  store.dispatch({
    type: "RESET_STORE",
  });
  window.location.replace(
    `${AUTH_BASE_URL}?step=${currentEmail ? "1" : "0"}${
      currentEmail ? `&${currentEmail}` : ""
    }&redirect=${window.location.origin}`
  );
};

export default function (message = "") {
  const messageSplit = message.split("|");

  if (messageSplit[0] === "sap") {
    sapIntegrationMessageCapture(messageSplit[1], messageSplit[2]);
    return;
  }

  switch (message) {
    case "logout":
      logout();
      break;
    default:
      break;
  }
}
