import { Box, Paper, Typography } from "@mui/material";
import React from "react";

function BasePaper({ title, subtitle, children }) {
  return (
    <Paper
      elevation={0}
      sx={{
        flex: 1,
        borderRadius: 2,
        border: 1,
        borderColor: (t) =>
          t.palette.mode === "light"
            ? "rgba(0,0,0,.18)"
            : "rgba(255,255,255,.18)",
      }}
    >
      <Box display={"flex"} alignItems="center" p={2}>
        <Box>
          <Typography fontSize={"1.2rem"} fontWeight={"600"}>
            {title}
          </Typography>
          {subtitle && (
            <Typography mt={0.5} color={"text.secondary"} fontSize={".9rem"}>
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
      {children || <Box height={100} />}
    </Paper>
  );
}

export default BasePaper;
