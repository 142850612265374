import {
  fetchRequestDetails,
  updateRequestStatusSap,
} from "../../store/reducers/expenseRequestsSlice";
import { store } from "../../store/store";

const requestPreviewRegex = /[?&]preview=([^&]+)/;

export default (requestId, status) => {
  if (!requestId || !status) return;
  const searchParams = window.location.search;
  const match = searchParams.match(requestPreviewRegex);
  if (match) {
    const previewValue = match[1];
    if (previewValue === requestId) {
      store.dispatch(fetchRequestDetails(requestId));
    }
  }
  store.dispatch(
    updateRequestStatusSap({
      requestId,
      status,
    })
  );
};
