import {
  Close,
  DeleteOutlineOutlined,
  EditOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { memo, useRef, useState } from "react";
import ModalDelete from "../../../../../modals/ModalDelete";

const ModalPhoto = ({
  open,
  onClose,
  onEdit,
  onRemove,
  photo,
  loading,
  name,
  showAlertRemove,
  hasPhoto,
  loadingPhoto,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  return (
    open && (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => !loading && onClose()}
      >
        <DialogTitle height={55}>
          <IconButton sx={{ m: -1 }} onClick={onClose}>
            <Close />
          </IconButton>
          <Typography ml={2} fontSize={"1.1rem"} fontWeight={"500"}>
            Foto de perfil
          </Typography>
          <Box flex={1} />
          <IconButton disabled={loading} onClick={onEdit}>
            <EditOutlined />
          </IconButton>
          {photo && hasPhoto && (
            <IconButton
              disabled={loading}
              onClick={() =>
                showAlertRemove ? setConfirmDelete(true) : onRemove()
              }
            >
              <DeleteOutlineOutlined />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            pt: 2,
            pb: 5,
          }}
        >
          <Box
            mt={2}
            position={"relative"}
            width={200}
            height={200}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {loading && (
              <CircularProgress
                size={205}
                sx={{
                  position: "absolute",
                }}
              />
            )}
            <Box
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: 100,
                zIndex: 100,
                p: loading ? 1 : 0,
                transition: ".2s ease",
                bgcolor: "background.default",
              }}
            >
              <Avatar
                sx={{
                  width: "100%",
                  height: "100%",
                  border: 1,
                  borderColor: "divider",
                  fontSize: "5rem",
                  fontWeight: "600",
                  opacity: loading ? 0.6 : 1,
                }}
                src={loading ? loadingPhoto : photo}
              >
                {name[0] && name[0].toUpperCase()}
              </Avatar>
            </Box>
          </Box>
        </DialogContent>
        {showAlertRemove && (
          <ModalDelete
            open={confirmDelete}
            onClose={() => setConfirmDelete(false)}
            onDelete={onRemove}
            title="Excluir foto de perfil"
          />
        )}
      </Dialog>
    )
  );
};

const AvatarPicker = ({
  photo,
  size = 60,
  onChange = async () => {},
  onRemove = async () => {},
  closeOnChange = true,
  name = "",
  showAlertRemove,
}) => {
  const [loading, setLoading] = useState(false);

  const [loadingPhoto, setLoadingPhoto] = useState(null);
  const [hasPhoto, setHasPhoto] = useState(Boolean(photo));
  const [open, setOpen] = useState(false);
  const inputFileRef = useRef(null);

  const handlePhotoChange = async (e) => {
    setLoading(true);
    const url = URL.createObjectURL(e.target.files[0]);
    setLoadingPhoto(url);
    await onChange(url);
    setHasPhoto(true);
    if (closeOnChange) {
      setOpen(false);
    }
    setLoading(null);
    setLoading(false);
  };

  const handleRemove = async () => {
    setLoading(true);
    await onRemove();
    setHasPhoto(false);
    if (closeOnChange) {
      setOpen(false);
    }
    setLoading(false);
  };

  return (
    <>
      <Box
        onClick={() => setOpen(true)}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        p={0.3}
        position={"relative"}
        borderRadius={100}
        sx={{
          cursor: "pointer",
          border: 1,
          borderColor: "divider",
          ":hover": { bgcolor: "action.hover", boxShadow: 2 },
        }}
      >
        <Avatar
          src={photo}
          onError={() => setHasPhoto(false)}
          sx={{
            width: size,
            height: size,
            fontSize: "2rem",
            fontWeight: "600",
          }}
        >
          {name[0] && name[0].toUpperCase()}
        </Avatar>
        <EditOutlined
          fontSize={size > 100 ? "large" : "medium"}
          color="action"
          sx={{
            position: "absolute",
            bgcolor: "background.default",
            p: 0.4,
            borderRadius: 100,
            right: 0,
            bottom: 0,
          }}
        />
      </Box>
      <input
        ref={inputFileRef}
        onChange={handlePhotoChange}
        hidden
        accept="image/*"
        multiple
        type="file"
      />
      <ModalPhoto
        loading={loading}
        photo={photo}
        loadingPhoto={loadingPhoto}
        hasPhoto={hasPhoto}
        name={name}
        onClose={() => setOpen(false)}
        open={open}
        onEdit={() => inputFileRef && inputFileRef.current.click()}
        onRemove={handleRemove}
        showAlertRemove={showAlertRemove}
      />
    </>
  );
};

export default memo(AvatarPicker);
