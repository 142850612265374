import { Box, Button, ListSubheader, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function ListHeaderSection({ title, path, onBlur }) {
  const navigate = useNavigate();

  return (
    <ListSubheader
      component={"div"}
      sx={{ display: "flex", alignItems: "center" }}
    >
      <Typography fontWeight={"500"} variant="body2">
        {title}
      </Typography>
      <Box flex={1} />
      <Button
        sx={{ mr: -1 }}
        onClick={() => {
          navigate(path);
          onBlur();
          const inpuEl = document.getElementById("search_main");
          if (inpuEl) {
            inpuEl.value = "";
          }
        }}
      >
        Ver tudo
      </Button>
    </ListSubheader>
  );
}

export default ListHeaderSection;
