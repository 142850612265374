import { Circle } from "@mui/icons-material";
import { Box, ListItemButton, ListItemText } from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  selectNotificationById,
  selectNotificationsIds,
} from "../../../../../store/reducers/notificationsSlice";
import ActionsButton from "./components/ActionsButton";
import BodyContent from "./components/BodyContent";
import CustomAvatar from "./components/CustomAvatar";
import SentAt from "./components/SentAt";

function NotificationItem({
  data = {},
  index,
  style,
  setRowHeight = () => {},
  onClick = () => {},
}) {
  const rowRef = useRef({});

  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(index, rowRef.current.clientHeight);
    }
  }, [rowRef]);

  const { is_read, title, body, variant, payload, sent_at, id } = data;

  return (
    <div style={style}>
      <ListItemButton
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        disableRipple
        sx={{
          alignItems: "flex-start",
          transition: "none",
          cursor: "default",
        }}
        ref={rowRef}
      >
        {!is_read && (
          <Circle
            color="primary"
            sx={{
              fontSize: ".3rem",
              position: "absolute",
              zIndex: 100,
              top: 30,
              left: 6,
            }}
          />
        )}
        {variant !== "system" && (
          <CustomAvatar variant={variant} payload={payload} />
        )}
        <ListItemText
          primaryTypographyProps={{
            variant: "body1",
            fontSize: ".9rem",
            component: "div",
          }}
          primary={
            <Box display={"flex"} alignItems={"flex-start"}>
              <Box pr={2} flex={1}>
                <BodyContent variant={variant} body={body} />
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-end"}
                justifyContent={"space-between"}
              >
                <SentAt sentAt={sent_at} isRead={is_read} />
                <ActionsButton hovered={hovered} notificationId={id} />
              </Box>
            </Box>
          }
        />
      </ListItemButton>
    </div>
  );
}
function mapStateToProps(state, ownProps) {
  const notificationIds = selectNotificationsIds(state);
  const data = selectNotificationById(state, notificationIds[ownProps.index]);

  return {
    ...ownProps,
    data,
  };
}

export default connect(mapStateToProps)(memo(NotificationItem));
