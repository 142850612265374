import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { Component } from "react";

export default class DialogAlert extends Component {
  static componentInstance;

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      title: "",
      message: "",
      actions: [],
      loading: false,
      onClose: () => {},
    };

    DialogAlert.componentInstance = this;

    // Bind the methods to the class instance
    this._show = this._show.bind(this);
    this._close = this._close.bind(this);
  }

  static show({ title, message, actions, onClose }) {
    DialogAlert.componentInstance._show({
      title,
      message,
      actions,
      onClose,
    });
  }

  _show({ title, message, actions, onClose }) {
    this.setState({
      visible: true,
      title,
      message,
      actions,
      onClose,
    });
  }
  _close() {
    this.setState({
      visible: false,
      title: "",
      message: "",
      actions: [],
      onClose: () => {},
    });
  }

  async _onClickAction(action) {
    if (action?.onClick && typeof action?.onClick === "function") {
      this.setState({
        loading: true,
      });
      await action?.onClick();
      this.setState({
        loading: false,
      });
    }
    this._close();
  }

  render() {
    const { message, title, visible, actions, loading, onClose } = this.state;
    return (
      visible && (
        <Dialog
          sx={{
            zIndex: (t) => t.zIndex.snackbar + 100,
          }}
          PaperProps={{
            sx: {
              maxWidth: 450,
            },
          }}
          TransitionProps={{
            onExited: () => {
              this.setState({
                title: "",
                message: "",
                actions: [],
                onClose: () => {},
              });
            },
          }}
          onClose={() => {
            if (onClose) {
              onClose();
            }
            this._close();
          }}
          open={visible}
          fullWidth
        >
          {loading && <LinearProgress />}
          <DialogTitle fontWeight={"500"} fontSize={"1.2rem"}>
            {title}
          </DialogTitle>
          <DialogContent>
            {message && (
              <DialogContentText sx={{ wordBreak: "break-word" }}>
                {message}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            {actions?.length ? (
              actions?.map((action, idx) => (
                <Button
                  onClick={() => this._onClickAction(action)}
                  key={idx.toString()}
                  variant={action?.variant}
                  disabled={loading && action?.main}
                  disableElevation
                >
                  {action.text}
                </Button>
              ))
            ) : (
              <Button onClick={this._close}>Ok</Button>
            )}
          </DialogActions>
        </Dialog>
      )
    );
  }
}
