// { code: 'BR', title: 'Brazil', phone: '55' },

export const CountryCodes = [
  { code: "BR", title: "Brazil", phone: "55" },
  { code: "BS", title: "Bahamas", phone: "1-242" },
  { code: "BT", title: "Bhutan", phone: "975" },
  { code: "BV", title: "Bouvet Island", phone: "47" },
  { code: "BW", title: "Botswana", phone: "267" },
  { code: "BY", title: "Belarus", phone: "375" },
  { code: "BZ", title: "Belize", phone: "501" },
  {
    code: "CA",
    title: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    title: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    title: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    title: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    title: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", title: "Switzerland", phone: "41" },
  { code: "CI", title: "Cote d'Ivoire", phone: "225" },
  { code: "CK", title: "Cook Islands", phone: "682" },
  { code: "CL", title: "Chile", phone: "56" },
  { code: "CM", title: "Cameroon", phone: "237" },
  { code: "CN", title: "China", phone: "86" },
  { code: "CO", title: "Colombia", phone: "57" },
  { code: "CR", title: "Costa Rica", phone: "506" },
  { code: "CU", title: "Cuba", phone: "53" },
  { code: "CV", title: "Cape Verde", phone: "238" },
  { code: "CW", title: "Curacao", phone: "599" },
  { code: "CX", title: "Christmas Island", phone: "61" },
  { code: "CY", title: "Cyprus", phone: "357" },
  { code: "CZ", title: "Czech Republic", phone: "420" },
  {
    code: "DE",
    title: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", title: "Djibouti", phone: "253" },
  { code: "DK", title: "Denmark", phone: "45" },
  { code: "DM", title: "Dominica", phone: "1-767" },
  {
    code: "DO",
    title: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", title: "Algeria", phone: "213" },
  { code: "EC", title: "Ecuador", phone: "593" },
  { code: "EE", title: "Estonia", phone: "372" },
  { code: "EG", title: "Egypt", phone: "20" },
  { code: "EH", title: "Western Sahara", phone: "212" },
  { code: "ER", title: "Eritrea", phone: "291" },
  { code: "ES", title: "Spain", phone: "34" },
  { code: "ET", title: "Ethiopia", phone: "251" },
  { code: "FI", title: "Finland", phone: "358" },
  { code: "FJ", title: "Fiji", phone: "679" },
  {
    code: "FK",
    title: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    title: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", title: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    title: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", title: "Gabon", phone: "241" },
  { code: "GB", title: "United Kingdom", phone: "44" },
  { code: "GD", title: "Grenada", phone: "1-473" },
  { code: "GE", title: "Georgia", phone: "995" },
  { code: "GF", title: "French Guiana", phone: "594" },
  { code: "GG", title: "Guernsey", phone: "44" },
  { code: "GH", title: "Ghana", phone: "233" },
  { code: "GI", title: "Gibraltar", phone: "350" },
  { code: "GL", title: "Greenland", phone: "299" },
  { code: "GM", title: "Gambia", phone: "220" },
  { code: "GN", title: "Guinea", phone: "224" },
  { code: "GP", title: "Guadeloupe", phone: "590" },
  { code: "GQ", title: "Equatorial Guinea", phone: "240" },
  { code: "GR", title: "Greece", phone: "30" },
  {
    code: "GS",
    title: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", title: "Guatemala", phone: "502" },
  { code: "GU", title: "Guam", phone: "1-671" },
  { code: "GW", title: "Guinea-Bissau", phone: "245" },
  { code: "GY", title: "Guyana", phone: "592" },
  { code: "HK", title: "Hong Kong", phone: "852" },
  {
    code: "HM",
    title: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", title: "Honduras", phone: "504" },
  { code: "HR", title: "Croatia", phone: "385" },
  { code: "HT", title: "Haiti", phone: "509" },
  { code: "HU", title: "Hungary", phone: "36" },
  { code: "ID", title: "Indonesia", phone: "62" },
  { code: "IE", title: "Ireland", phone: "353" },
  { code: "IL", title: "Israel", phone: "972" },
  { code: "IM", title: "Isle of Man", phone: "44" },
  { code: "IN", title: "India", phone: "91" },
  {
    code: "IO",
    title: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", title: "Iraq", phone: "964" },
  {
    code: "IR",
    title: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", title: "Iceland", phone: "354" },
  { code: "IT", title: "Italy", phone: "39" },
  { code: "JE", title: "Jersey", phone: "44" },
  { code: "JM", title: "Jamaica", phone: "1-876" },
  { code: "JO", title: "Jordan", phone: "962" },
  {
    code: "JP",
    title: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", title: "Kenya", phone: "254" },
  { code: "KG", title: "Kyrgyzstan", phone: "996" },
  { code: "KH", title: "Cambodia", phone: "855" },
  { code: "KI", title: "Kiribati", phone: "686" },
  { code: "KM", title: "Comoros", phone: "269" },
  {
    code: "KN",
    title: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    title: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", title: "Korea, Republic of", phone: "82" },
  { code: "KW", title: "Kuwait", phone: "965" },
  { code: "KY", title: "Cayman Islands", phone: "1-345" },
  { code: "KZ", title: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    title: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", title: "Lebanon", phone: "961" },
  { code: "LC", title: "Saint Lucia", phone: "1-758" },
  { code: "LI", title: "Liechtenstein", phone: "423" },
  { code: "LK", title: "Sri Lanka", phone: "94" },
  { code: "LR", title: "Liberia", phone: "231" },
  { code: "LS", title: "Lesotho", phone: "266" },
  { code: "LT", title: "Lithuania", phone: "370" },
  { code: "LU", title: "Luxembourg", phone: "352" },
  { code: "LV", title: "Latvia", phone: "371" },
  { code: "LY", title: "Libya", phone: "218" },
  { code: "MA", title: "Morocco", phone: "212" },
  { code: "MC", title: "Monaco", phone: "377" },
  {
    code: "MD",
    title: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", title: "Montenegro", phone: "382" },
  {
    code: "MF",
    title: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", title: "Madagascar", phone: "261" },
  { code: "MH", title: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    title: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", title: "Mali", phone: "223" },
  { code: "MM", title: "Myanmar", phone: "95" },
  { code: "MN", title: "Mongolia", phone: "976" },
  { code: "MO", title: "Macao", phone: "853" },
  {
    code: "MP",
    title: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", title: "Martinique", phone: "596" },
  { code: "MR", title: "Mauritania", phone: "222" },
  { code: "MS", title: "Montserrat", phone: "1-664" },
  { code: "MT", title: "Malta", phone: "356" },
  { code: "MU", title: "Mauritius", phone: "230" },
  { code: "MV", title: "Maldives", phone: "960" },
  { code: "MW", title: "Malawi", phone: "265" },
  { code: "MX", title: "Mexico", phone: "52" },
  { code: "MY", title: "Malaysia", phone: "60" },
  { code: "MZ", title: "Mozambique", phone: "258" },
  { code: "NA", title: "Namibia", phone: "264" },
  { code: "NC", title: "New Caledonia", phone: "687" },
  { code: "NE", title: "Niger", phone: "227" },
  { code: "NF", title: "Norfolk Island", phone: "672" },
  { code: "NG", title: "Nigeria", phone: "234" },
  { code: "NI", title: "Nicaragua", phone: "505" },
  { code: "NL", title: "Netherlands", phone: "31" },
  { code: "NO", title: "Norway", phone: "47" },
  { code: "NP", title: "Nepal", phone: "977" },
  { code: "NR", title: "Nauru", phone: "674" },
  { code: "NU", title: "Niue", phone: "683" },
  { code: "NZ", title: "New Zealand", phone: "64" },
  { code: "OM", title: "Oman", phone: "968" },
  { code: "PA", title: "Panama", phone: "507" },
  { code: "PE", title: "Peru", phone: "51" },
  { code: "PF", title: "French Polynesia", phone: "689" },
  { code: "PG", title: "Papua New Guinea", phone: "675" },
  { code: "PH", title: "Philippines", phone: "63" },
  { code: "PK", title: "Pakistan", phone: "92" },
  { code: "PL", title: "Poland", phone: "48" },
  {
    code: "PM",
    title: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", title: "Pitcairn", phone: "870" },
  { code: "PR", title: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    title: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", title: "Portugal", phone: "351" },
  { code: "PW", title: "Palau", phone: "680" },
  { code: "PY", title: "Paraguay", phone: "595" },
  { code: "QA", title: "Qatar", phone: "974" },
  { code: "RE", title: "Reunion", phone: "262" },
  { code: "RO", title: "Romania", phone: "40" },
  { code: "RS", title: "Serbia", phone: "381" },
  { code: "RU", title: "Russian Federation", phone: "7" },
  { code: "RW", title: "Rwanda", phone: "250" },
  { code: "SA", title: "Saudi Arabia", phone: "966" },
  { code: "SB", title: "Solomon Islands", phone: "677" },
  { code: "SC", title: "Seychelles", phone: "248" },
  { code: "SD", title: "Sudan", phone: "249" },
  { code: "SE", title: "Sweden", phone: "46" },
  { code: "SG", title: "Singapore", phone: "65" },
  { code: "SH", title: "Saint Helena", phone: "290" },
  { code: "SI", title: "Slovenia", phone: "386" },
  {
    code: "SJ",
    title: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", title: "Slovakia", phone: "421" },
  { code: "SL", title: "Sierra Leone", phone: "232" },
  { code: "SM", title: "San Marino", phone: "378" },
  { code: "SN", title: "Senegal", phone: "221" },
  { code: "SO", title: "Somalia", phone: "252" },
  { code: "SR", title: "Suriname", phone: "597" },
  { code: "SS", title: "South Sudan", phone: "211" },
  {
    code: "ST",
    title: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", title: "El Salvador", phone: "503" },
  {
    code: "SX",
    title: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    title: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", title: "Swaziland", phone: "268" },
  {
    code: "TC",
    title: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", title: "Chad", phone: "235" },
  {
    code: "TF",
    title: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", title: "Togo", phone: "228" },
  { code: "TH", title: "Thailand", phone: "66" },
  { code: "TJ", title: "Tajikistan", phone: "992" },
  { code: "TK", title: "Tokelau", phone: "690" },
  { code: "TL", title: "Timor-Leste", phone: "670" },
  { code: "TM", title: "Turkmenistan", phone: "993" },
  { code: "TN", title: "Tunisia", phone: "216" },
  { code: "TO", title: "Tonga", phone: "676" },
  { code: "TR", title: "Turkey", phone: "90" },
  {
    code: "TT",
    title: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", title: "Tuvalu", phone: "688" },
  {
    code: "TW",
    title: "Taiwan, Province of China",
    phone: "886",
  },
  {
    code: "TZ",
    title: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", title: "Ukraine", phone: "380" },
  { code: "UG", title: "Uganda", phone: "256" },
  {
    code: "US",
    title: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", title: "Uruguay", phone: "598" },
  { code: "UZ", title: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    title: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    title: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", title: "Venezuela", phone: "58" },
  {
    code: "VG",
    title: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    title: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", title: "Vietnam", phone: "84" },
  { code: "VU", title: "Vanuatu", phone: "678" },
  { code: "WF", title: "Wallis and Futuna", phone: "681" },
  { code: "WS", title: "Samoa", phone: "685" },
  { code: "XK", title: "Kosovo", phone: "383" },
  { code: "YE", title: "Yemen", phone: "967" },
  { code: "YT", title: "Mayotte", phone: "262" },
  { code: "ZA", title: "South Africa", phone: "27" },
  { code: "ZM", title: "Zambia", phone: "260" },
  { code: "ZW", title: "Zimbabwe", phone: "263" },
];

export const CurrencyList = [
  { code: "BRL", symbol: "R$" },
  { code: "EUR", symbol: "€" },
  { code: "GBP", symbol: "£" },
  { code: "JPY", symbol: "¥" },
  { code: "USD", symbol: "$" },
];

export function curr(currency) {
  switch (currency?.toUpperCase()) {
    case "BRL":
      return "R$";
    case "USD":
      return "U$";
    case "EUR":
      return "€";
    default:
      return currency;
  }
}
