import React from "react";

export const highlightText = (text, searchTerm) => {
  if (!searchTerm.trim()) {
    return text;
  }
  const regex = new RegExp(`(${searchTerm.trim()})`, "gi");
  const parts = text.split(regex).filter(Boolean);
  return parts.map((part, i) =>
    regex.test(part) ? (
      <span
        key={i}
        style={{ backgroundColor: "yellow", color: "black", fontWeight: "500" }}
      >
        {part}
      </span>
    ) : (
      <React.Fragment key={i}>{part}</React.Fragment>
    )
  );
};

export const HighlightedText = ({ text, searchTerm }) => (
  <span style={{ whiteSpace: "pre-wrap" }}>
    {highlightText(text, searchTerm)}
  </span>
);
