export default function (r = []) {
  const obj = {
    ids: [],
    entities: {},
  };
  if (!r.length) {
    return {
      ids: [],
      entities: {},
    };
  }
  for (let index = 0; index < r.length; index++) {
    const d = r[index];
    obj.ids.push(d.id || d._id);
    obj.entities[d.id || d._id] = {
      id: d.id || d._id,
      ...d,
    };
  }
  return obj;
}
