import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";

const baseName = "snippets";

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchSnippets = createAsyncThunk(
  `${baseName}/fetchSnippets`,
  async () => {
    const res = await HttpClient.client().get(`/snippets`);
    return res.data;
  }
);

export const snippetsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    addSnippet(state, { payload }) {
      adapter.addOne(state, { ...payload, active: true });
    },
    updateSnippet(state, { payload }) {
      const { id, changes } = payload;
      adapter.updateOne(state, {
        changes,
        id,
      });
    },
    removeSnippet(state, { payload }) {
      adapter.removeOne(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSnippets.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSnippets.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchSnippets.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        const data = action.payload;
        state.ids = data.ids;
        state.entities = data.entities;
      });
  },
});

export const { addSnippet, updateSnippet, removeSnippet } =
  snippetsSlice.actions;

export const {
  selectAll: selectAllSnippets,
  selectIds: selectSnippetsIds,
  selectById: selectSnippetById,
  selectEntities: selectSnippetsEntities,
  selectTotal: selectSnippetsTotal,
} = adapter.getSelectors((state) => state[baseName]);

export default snippetsSlice.reducer;
