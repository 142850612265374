import { Box, Button, Popover, Typography, lighten } from "@mui/material";
import React from "react";

import { EXPENSES_BASE_URL } from "../../../api/urls";
import "./styles.css";

function MenuContainer({ anchorEl, onClose, children, name }) {
  return (
    <Popover
      elevation={5}
      sx={{ mt: 1 }}
      anchorEl={anchorEl}
      disableScrollLock
      open={Boolean(anchorEl)}
      onClose={onClose}
      transitionDuration={0}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      slotProps={{
        paper: {
          className: "account-menu",
          elevation: 0,
          sx: {
            boxShadow: 5,
            width: 410,
            borderRadius: 6,
            bgcolor: (t) =>
              t.palette.mode === "light"
                ? lighten(t.palette.primary.main, 0.95)
                : lighten(t.palette.background.default, 0.015),
          },
        },
      }}
    >
      <Box p={2} pb={2}>
        <Box mb={1} display={"flex"} alignItems={"center"}>
          <Typography fontSize={"1.1rem"} fontWeight={"500"}>
            Olá, {name || ""}.
          </Typography>
          <Box flex={1} />
          <Button
            LinkComponent={"a"}
            sx={{
              px: 1,
              transition: "none",
              height: 30,
              ":hover": { boxShadow: 2 },
            }}
            href={EXPENSES_BASE_URL}
            target="_blank"
          >
            Painel de despesas
          </Button>
        </Box>
        {children}
        <Box mt={2} display={"flex"} alignItems="center" gap={1}>
          <Box flex={1}/>
          <Button
            size="small"
            target="_blank"
            LinkComponent={"a"}
            sx={{ height: 20 }}
          >
            Feedback
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}

export default MenuContainer;
