import { ExpandLess, ExpandMore, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeError } from "../../store/reducers/bases/errorBaseSlice";

function ModalErrorBase(props) {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.errorBase.open);

  const errorBase = useSelector((state) => state.errorBase);
  const { title, message, error, content, config } = errorBase;

  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (!open) {
      setShowMore(false);
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      PaperProps={{
        sx: { maxWidth: 470 },
      }}
    >
      {open && (
        <>
          <DialogTitle
            fontSize={"1.2rem"}
            display={"flex"}
            alignItems="center"
            height={65}
          >
            <InfoOutlined sx={{ mr: 2 }} color="error" />
            <span>{title}</span>
          </DialogTitle>
          <DialogContent>
            {content}
            <DialogContentText>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    message ||
                    error?.response?.data?.message?.toString() ||
                    error?.response?.data?.toString() ||
                    "",
                }}
              />
              <br />
              <Typography component={"span"} fontSize={"0.8rem"} mt={1}>
                Código do erro:{" "}
                <Typography
                  component={"span"}
                  variant="inherit"
                  fontWeight={600}
                >
                  {error?.response?.data?.code || error?.code || "NO_CODE"}
                </Typography>
              </Typography>
            </DialogContentText>
            {error?.response?.data?.details && (
              <Box>
                <Button
                  onClick={() => setShowMore(!showMore)}
                  size="small"
                  sx={{ px: 1, ml: -1, mt: 0.5 }}
                  endIcon={
                    showMore ? (
                      <ExpandLess fontSize="small" />
                    ) : (
                      <ExpandMore fontSize="small" />
                    )
                  }
                >
                  Mais detalhes
                </Button>
                <Collapse in={showMore}>
                  <Typography mt={1} variant="body2">
                    - {error?.response?.data?.details}
                  </Typography>
                </Collapse>
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={{ py: 2 }}>
            <Button onClick={() => dispatch(closeError())}>Fechar</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default ModalErrorBase;
