import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";

const baseName = "rejectionReasons";

const adapter = createEntityAdapter({
  selectId: (user) => user.id,
  sortComparer: (a, b) =>
    a?.text?.toUpperCase()?.localeCompare(b?.text?.toUpperCase()),
});

const initialState = adapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchRejectionReasons = createAsyncThunk(
  `${baseName}/fetchRejectionReasons`,
  async () => {
    const res = await HttpClient.client().get(`/rejectionReasons`);
    return res.data;
  }
);

export const rejectionReasonsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    updateRejectionReason(state, { payload }) {
      adapter.updateOne(state, {
        changes: payload.changes,
        id: payload.id,
      });
    },
    addRejectionReason(state, action) {
      adapter.addOne(state, { ...action.payload, active: true });
    },
    removeRejectionReason(state, action) {
      adapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRejectionReasons.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchRejectionReasons.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchRejectionReasons.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        const data = action.payload;
        state.ids = data.ids;
        state.entities = data.entities;
      });
  },
});

export const {
  updateRejectionReason,
  addRejectionReason,
  removeRejectionReason,
} = rejectionReasonsSlice.actions;

export const {
  selectAll: selectAllRejectionReasons,
  selectIds: selectRejectionReasonsIds,
  selectById: selectRejectionReasonById,
  selectEntities: selectRejectionReasonsEntities,
  selectTotal: selectRejectionReasonsTotal,
} = adapter.getSelectors((state) => state[baseName]);

export default rejectionReasonsSlice.reducer;
