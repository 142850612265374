import {
  CheckCircleOutlineOutlined,
  CloseOutlined,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  Divider,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { selectIsMe } from "../../../../../store/reducers/accountSlice";
import { selectUserById } from "../../../../../store/reducers/usersSlice";
import BasePaper from "../../components/BasePaper";
import ModalEditPermissions from "./ModalEditPermissions";

const PermissionLabelItem = ({ permited, children }) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      {permited ? (
        <CheckCircleOutlineOutlined fontSize="small" color="success" />
      ) : (
        <CloseOutlined fontSize="small" color="error" />
      )}
      <Typography variant="body2" fontWeight={"500"}>
        {children}
      </Typography>
    </Box>
  );
};

const PermissionsSection = ({ roles, userId, name }) => {
  const [editOpen, setEditOpen] = useState(false);
  const isMe = useSelector((state) => selectIsMe(state, userId));

  return (
    <BasePaper
      title={"Permissões de acesso"}
      subtitle="Ações que o colaborador pode realizar na Notepay"
    >
      <Box mx={2} pb={2}>
        {roles.master ? (
          <>
            <Chip
              sx={{ fontSize: ".9rem", fontWeight: "600" }}
              variant="outlined"
              label="Proprietário"
              icon={<WorkspacePremiumOutlined color="primary" />}
            />
            <Typography
              mt={1}
              variant="body2"
              fontWeight={"500"}
              color={"text.secondary"}
            >
              O colaborador possui acesso completo a todos os recursos da conta
            </Typography>
          </>
        ) : (
          <>
            <PermissionLabelItem permited>Prestar despesas</PermissionLabelItem>
            <Divider sx={{ my: 1 }} />
            <PermissionLabelItem permited={Boolean(roles.approver)}>
              Aprovador
            </PermissionLabelItem>
            <Divider sx={{ my: 1 }} />
            <PermissionLabelItem permited={Boolean(roles.admin)}>
              Administrador
            </PermissionLabelItem>
            <Divider sx={{ my: 1 }} />
            {Boolean(roles.admin) && (
              <>
                <PermissionLabelItem permited={Boolean(roles.admin?.settings)}>
                  Editar configurações principais da conta
                </PermissionLabelItem>
                <Divider sx={{ my: 1 }} />
                <PermissionLabelItem
                  permited={Boolean(roles.admin?.users_control)}
                >
                  Gerenciar e configurar colaboradores
                </PermissionLabelItem>
                <Divider sx={{ my: 1 }} />
                <PermissionLabelItem permited={Boolean(roles.admin?.analysis)}>
                  Analisar estatísticas gerenciais
                </PermissionLabelItem>
                <Divider sx={{ my: 1 }} />
                <PermissionLabelItem
                  permited={Boolean(roles.admin?.panel_control)}
                >
                  Gerenciar relatórios de despesas (acesso ao painel de controle)
                </PermissionLabelItem>
                <Divider sx={{ my: 1 }} />
                <PermissionLabelItem permited={Boolean(roles.admin?.panel_sap)}>
                  Acesso ao painel de integração SAP
                </PermissionLabelItem>
                <Divider sx={{ my: 1 }} />
              </>
            )}
          </>
        )}
      </Box>
      {!isMe && !roles.master && (
        <ListItemButton onClick={() => setEditOpen(true)}>
          <ListItemText
            primaryTypographyProps={{
              color: "primary",
              fontWeight: "600",
              variant: "body2",
            }}
            primary="Editar permissões"
          />
        </ListItemButton>
      )}
      <ModalEditPermissions
        open={editOpen}
        onClose={() => setEditOpen(false)}
        userId={userId}
        initialRoles={roles}
        name={name}
      />
    </BasePaper>
  );
};

function mapStateToProps(state, ownProps) {
  const user = selectUserById(state, ownProps.userId);
  return {
    roles: user?.roles || {},
    name: user?.name || " ",
    ...ownProps,
  };
}

export default connect(mapStateToProps)(PermissionsSection);
