import axios from "axios";
import { API_BASE_URL } from "./urls";

export class HttpClient {
  static _client = null;

  static client() {
    if (!HttpClient._client) {
      HttpClient._client = axios.create({
        baseURL: API_BASE_URL,
        withCredentials: true,
      });
    }

    return HttpClient._client;
  }

  static setAccessToken(token) {
    HttpClient.client().defaults.headers["api-access-token"] = token;
  }
}
