import React, { lazy } from "react";
import CheckConfigAccessWrapper from "../components/access/CheckConfigAccessWrapper";
// configurações SAP

// expense requests
const ExpenseRequests = lazy(() =>
  import("../pages/financial/tables/requests/")
);
const AllExpenses = lazy(() => import("../pages/financial/tables/expenses"));

// statistics
const Statistics = lazy(() => import("../pages/statistics"));

//exp-types
const ExpTypesPage = lazy(() => import("../pages/exp_types"));

//exp-policies
const Rules = lazy(() => import("../pages/rules"));

//custom-fields
const CustomFields = lazy(() => import("../pages/custom-fields"));

//payments
const PaymentMethods = lazy(() => import("../pages/payment_methods"));

//route-policies
const RoutePolicies = lazy(() => import("../pages/route_policies"));

//snippets
const Snippets = lazy(() => import("../pages/snippets"));

//approval-flows
const ApprovalFlowsPage = lazy(() => import("../pages/approval_flows"));

//rejection reasons
const RejectionReasons = lazy(() => import("../pages/rejection_reasons"));

//account
const CompanyInfo = lazy(() => import("../pages/my_company/info"));
const Groups = lazy(() => import("../pages/groups"));
//users
const UsersPage = lazy(() => import("../pages/users"));
const UserViewPage = lazy(() => import("../pages/users/view/UserView"));

//projets
const ProjectsMain = lazy(() => import("../pages/projects/ProjectsMain"));

//sap-integration
const SapIntegrationPage = lazy(() => import("../pages/sap_integration"));

// API Rest integration
const APIRestIntegrationPage = lazy(() =>
  import("../pages/api_rest_integration/APIRestIntegration")
);

// Faturamento/Plano de assinatura Notepay
const BillingPage = lazy(() => import("../pages/billing/BillingPage"));
const InvoicesPage = lazy(() =>
  import("../pages/billing/invoices/InvoicesPage")
);
const InvoiceViewPage = lazy(() =>
  import("../pages/billing/invoices/view/InvoiceView")
);

//advance settings
const AdvancedSettings = lazy(() => import("../pages/advanced_settings"));

export const routes = [
  {
    path: "expense-requests",
    element: (
      <CheckConfigAccessWrapper
        settingKey={"admin.panel_control"}
        showNoAccessComponent
      >
        <ExpenseRequests />
      </CheckConfigAccessWrapper>
    ),
    children: [],
  },
  {
    path: "all-expenses",
    element: (
      <CheckConfigAccessWrapper
        settingKey={"admin.panel_control"}
        showNoAccessComponent
      >
        <AllExpenses />
      </CheckConfigAccessWrapper>
    ),
    children: [],
  },
  {
    path: "statistics",
    element: (
      <CheckConfigAccessWrapper
        settingKey={"admin.analysis"}
        showNoAccessComponent
      >
        <Statistics />
      </CheckConfigAccessWrapper>
    ),
    children: [],
  },
  {
    path: "groups",
    element: <Groups />,
    children: [],
  },
  {
    path: "collaborators",
    element: <UsersPage />,
    children: [
      {
        path: ":userId",
        element: <UserViewPage />,
      },
    ],
  },
  {
    path: "projects",
    element: <ProjectsMain />,
    children: [],
  },
  {
    path: "expense-types",
    element: <ExpTypesPage />,
    children: [],
  },
  {
    path: "route-policies",
    element: <RoutePolicies />,
    children: [{ path: "snippets", element: <Snippets />, children: [] }],
  },
  {
    path: "approval-flows",
    element: <ApprovalFlowsPage />,
    children: [],
  },
  {
    path: "rules",
    element: <Rules />,
    children: [],
  },
  {
    path: "rejection-reasons",
    element: <RejectionReasons />,
    children: [],
  },
  {
    path: "custom-fields",
    element: <CustomFields />,
    children: [],
  },
  {
    path: "payment-methods",
    element: <PaymentMethods />,
    children: [],
  },
  {
    path: "company-info",
    element: <CompanyInfo />,
    children: [],
  },
  {
    path: "sap-integration",
    element: (
      <CheckConfigAccessWrapper
        settingKey={"admin.panel_sap"}
        showNoAccessComponent
      >
        <SapIntegrationPage />
      </CheckConfigAccessWrapper>
    ),
    children: [],
  },
  {
    path: "api-rest",
    element: <APIRestIntegrationPage />,
    children: [],
  },
  {
    path: "billing",
    element: (
      <CheckConfigAccessWrapper settingKey={"master"} showNoAccessComponent>
        <BillingPage />
      </CheckConfigAccessWrapper>
    ),
    children: [
      {
        path: "invoices",
        element: (
          <CheckConfigAccessWrapper settingKey={"master"} showNoAccessComponent>
            <InvoicesPage />
          </CheckConfigAccessWrapper>
        ),
        children: [
          {
            path: ":invoiceId",
            element: (
              <CheckConfigAccessWrapper
                settingKey={"master"}
                showNoAccessComponent
              >
                <InvoiceViewPage />
              </CheckConfigAccessWrapper>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "advanced-settings",
    element: (
      <CheckConfigAccessWrapper settingKey={"master"} showNoAccessComponent>
        <AdvancedSettings />
      </CheckConfigAccessWrapper>
    ),
    children: [],
  },
];
