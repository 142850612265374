import { DatePicker } from "@mui/x-date-pickers";
import { isValid } from "date-fns";
import React from "react";
import { formatDate } from "../../utils/more/date_functions";

function DateInput({
  size,
  label,
  placeholder,
  width,
  value,
  sx,
  onChange = () => {},
  onAccept = () => {},
  variant,
  className,
  error,
  disableHelperText,
  readOnly,
  disabled,
  required,
  autoFocus,
  hiddenLabel,
  helperText,
  maxDate,
  minDate,
  margin,
  inputSx,
}) {
  return (
    <DatePicker
      label={label}
      maxDate={maxDate}
      minDate={minDate}
      defaultValue={null}
      value={value}
      autoFocus={autoFocus}
      disabled={disabled}
      readOnly={readOnly}
      onChange={(newValue) => onChange(newValue)}
      slotProps={{
        textField: {
          autoFocus,
          sx: {
            width,
            ...sx,
          },
          label,
          size,
          hiddenLabel,
          fullWidth: true,
          variant,
          margin,
          error,
          helperText:
            helperText ||
            (!disableHelperText &&
              value &&
              isValid(new Date(value)) &&
              formatDate(new Date(value))),
        },
      }}
    />
  );
}

export default DateInput;
