import {
  ClearAllOutlined,
  Close,
  NotificationsOutlined,
} from "@mui/icons-material";
import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { HttpClient } from "../../../../api/http-client";
import ModalDelete from "../../../../modals/ModalDelete";
import { removeManyNotifications } from "../../../../store/reducers/notificationsSlice";
import { store } from "../../../../store/store";

const ClearAllButton = memo(() => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClear = async () => {
    const ids = [...store.getState().notifications.ids];
    await HttpClient.client().put("/notifications/hide", {
      ids,
    });
    dispatch(removeManyNotifications(ids));
  };

  return (
    <>
      <Tooltip onClick={handleClear} title="Limpar notificações">
        <IconButton onClick={() => setOpen(true)}>
          <ClearAllOutlined />
        </IconButton>
      </Tooltip>
      <ModalDelete
        title="Limpar notificações"
        subtitle={"Todas as notificações serão ocultadas neste dispositivo"}
        open={open}
        onClose={() => setOpen(false)}
        buttonText={"Limpar"}
        onDelete={handleClear}
      />
    </>
  );
});

function NotificationsHeader({ onClose }) {
  return (
    <Box
      position={"relative"}
      p={2}
      py={1}
      display={"flex"}
      alignItems={"center"}
      borderBottom={1}
      borderColor={"divider"}
    >
      <NotificationsOutlined sx={{ mr: 2 }} color="action" />
      <Typography fontSize={"1.1rem"} fontWeight={"600"}>
        Notificações recentes
      </Typography>
      <Box flex={1} />
      <ClearAllButton />
      <Divider sx={{ mx: 1, height: 15 }} orientation="vertical" />
      <IconButton onClick={onClose} sx={{ mr: -1 }}>
        <Close />
      </IconButton>
    </Box>
  );
}

export default memo(NotificationsHeader);
