import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import HelpIconButton from "../buttons/HelpIconButton";

function PaperSection({
  children,
  title,
  subtitle,
  action,
  helperText,
  disableElevation = true,
  sx,
  titleSx,
}) {
  return (
    <Paper
      variant="elevation"
      elevation={1}
      sx={{
        borderRadius: 2,
        border: 1,
        borderColor: (t) =>
          t.palette.mode === "light"
            ? "rgba(0,0,0,.2)"
            : "rgba(255,255,255,.2)",
        p: 2,
        boxShadow: !disableElevation ? 1 : 0,
        ...sx,
      }}
    >
      {(title || subtitle) && (
        <Box mb={2} display="flex" alignItems={"center"} sx={titleSx}>
          <Box flex={1}>
            <Typography
              variant="h5"
              fontWeight={"500"}
              fontSize={"1.3rem"}
              component={"div"}
              display={"flex"}
              alignItems={"center"}
            >
              {title}
              {helperText && (
                <HelpIconButton title={title}>{helperText}</HelpIconButton>
              )}
            </Typography>
            {subtitle && (
              <Typography
                mt={subtitle && 0.4}
                fontSize={".9rem"}
                color="text.secondary"
              >
                {subtitle}
              </Typography>
            )}
          </Box>
          {action}
        </Box>
      )}
      {children}
    </Paper>
  );
}

export default PaperSection;
