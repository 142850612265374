import React, { createContext, useContext } from "react";
import { useSelector } from "react-redux";
import {
  selectAccountCurrency,
  selectCompanyName,
  selectDefaultRoutePolicy,
} from "../store/reducers/accountSlice";
import { curr } from "../utils/more/currency_country";

const AccountSettingsContext = createContext({});

const AccountSettingsProvider = ({ children }) => {
  const currency = useSelector(selectAccountCurrency);
  const companyName = useSelector(selectCompanyName);
  const defaultRoutePolicy = useSelector(selectDefaultRoutePolicy);

  return (
    <AccountSettingsContext.Provider
      value={{
        currency: curr(currency),
        companyName,
        defaultRoutePolicy: {
          rate: defaultRoutePolicy?.rate || 0,
        },
      }}
    >
      {children}
    </AccountSettingsContext.Provider>
  );
};

const useAccountSettings = () => {
  const data = useContext(AccountSettingsContext);
  if (data == null) {
    throw new Error("useAccountSettings() error");
  }
  return data;
};

export { AccountSettingsProvider, useAccountSettings };
