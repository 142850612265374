import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";

const baseName = "routeRates";

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchRouteRates = createAsyncThunk(
  `${baseName}/fetchRouteRates`,
  async () => {
    const res = await HttpClient.client().get("/policies/route");
    return res.data;
  }
);

export const routeRatesSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    addRouteRate(state, { payload }) {
      adapter.addOne(state, { ...payload, active: true });
    },
    updateRouteRate(state, { payload }) {
      const { changes, id } = payload;
      adapter.updateOne(state, {
        changes,
        id,
      });
    },
    removeRoutePolicy(state, { payload }) {
      adapter.removeOne(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRouteRates.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchRouteRates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchRouteRates.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        const data = action.payload;
        state.ids = data.ids;
        state.entities = data.entities;
      });
  },
});

export const { addRouteRate, updateRouteRate, removeRoutePolicy } =
  routeRatesSlice.actions;

export const {
  selectAll: selectAllRouteRates,
  selectIds: selectRouteRatesIds,
  selectById: selectRouteRateById,
  selectEntities: selectRouteRatesEntities,
  selectTotal: selectRouteRatesTotal,
} = adapter.getSelectors((state) => state[baseName]);

export default routeRatesSlice.reducer;
