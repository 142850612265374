import {
  AssignmentOutlined,
  CurrencyExchangeOutlined,
  DirectionsOutlined,
  NotesOutlined,
  PersonPinCircleOutlined,
  PostAddOutlined,
  TodayOutlined,
} from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectSnippetsEntities } from "../../../../../../../store/reducers/snippetsSlice";
import { curr } from "../../../../../../../utils/more/currency_country";
import { formatDate } from "../../../../../../../utils/more/date_functions";
import { CurrencyFormat } from "../../../../../../../utils/more/mask_functions";
import Baseline from "../components/Baseline";
import ReceiptsSection from "./receipts/ReceiptsSection";

export default ({ data = {}, callback = () => {}, showRequest }) => {
  const {
    currency,
    amount,
    project_name,
    distance,
    refundable,
    created_at,
    snippet_id,
    receipts,
    notes,
    rate,
    route_policy_name,
    request_title,
    request_id,
    user_id,
    duplicated_receipts,
    date,
  } = data;

  const navigate = useNavigate();
  const snippetsEntities = useSelector(selectSnippetsEntities);

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={3}>
        <Box>
          <Box display={"flex"} alignItems={"flex-start"}>
            <Box>
              <Typography fontWeight={"500"} fontSize={"1.8rem"} variant="h4">
                {curr(currency)} <CurrencyFormat value={amount || 0} />
              </Typography>
              <Typography
                mt={1}
                color={"text.secondary"}
                fontSize={"1.2rem"}
                variant="h4"
              >
                {parseFloat(distance || 0).toFixed(2)} km
              </Typography>
            </Box>
            <Box flex={1} />
            <Box mt={1} ml={1} display={"flex"} alignItems={"center"}>
              <CurrencyExchangeOutlined
                fontSize="small"
                color={refundable ? "primary" : "disabled"}
                sx={{ mr: 1 }}
              />
              <Typography variant="body2">
                {refundable ? "Reembolsável" : "Não reembolsável"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mx: -2, my: -0.5 }} />
        <Baseline
          Icon={DirectionsOutlined}
          label={"Trecho pré-cadastrado"}
          emptyLabel={"Nenhum trecho selecionado"}
          value={snippetsEntities[snippet_id]?.description || ""}
        />
        <Baseline
          Icon={PersonPinCircleOutlined}
          value={
            <Typography variant="inherit" component={"span"}>
              {route_policy_name} - {currency} <CurrencyFormat value={rate} />{" "}
              /km
            </Typography>
          }
          label={"Política de percurso"}
          emptyLabel="Sem política aplicada"
        />
        <Baseline
          Icon={TodayOutlined}
          label={"Data do percurso"}
          value={formatDate(date)}
        />
        <Baseline
          Icon={PostAddOutlined}
          value={project_name}
          label={"Projeto"}
          emptyLabel="Sem projeto"
        />
        <Baseline
          Icon={NotesOutlined}
          value={notes || ""}
          label={"Descrição"}
          emptyLabel="Sem descrição"
        />
        {showRequest && (
          <Box width={"100%"} display={"flex"} alignItems={"center"}>
            <Baseline
              Icon={AssignmentOutlined}
              value={request_title}
              label={"Relatório"}
              emptyLabel="Sem relatório"
            />
          </Box>
        )}
        <Typography variant="caption" color={"text.secondary"}>
          Despesa criada em {formatDate(created_at || new Date())}
        </Typography>
        <Divider sx={{ mx: -2, my: -0.5 }} />
        <ReceiptsSection
          receipts={receipts || []}
          duplicatedReceipts={duplicated_receipts || []}
          userId={user_id}
        />
      </Box>
    </>
  );
};
