import { Close, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  FormHelperText,
  IconButton,
  MenuItem,
  Radio,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApprovalFlows,
  selectAllApprovalFlows,
  selectApprovalFlowsEntities,
} from "../../../store/reducers/approvalFlowsSlice";
import { selectGroupById } from "../../../store/reducers/groupsSlice";
import LoadingSection from "../../loading/LoadingSection";
import SelectInput from "../Select";

function SelectApprovalFlow({
  onChange,
  size,
  margin,
  autoFocus,
  initialValue = "",
  variant = "standard",
  label = "Fluxo de aprovação",
  selectedGroupId,
}) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(initialValue || "");

  const status = useSelector((state) => state.approvalFlows.status);
  const apFlows = useSelector(selectAllApprovalFlows);
  const apFlowsEntities = useSelector(selectApprovalFlowsEntities);

  const group = useSelector((state) => selectGroupById(state, selectedGroupId));

  const isFromGroup = selectedGroupId && group && group?.apflowId;

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchApprovalFlows());
    }
  }, []);

  useEffect(() => {
    if (isFromGroup) {
      setValue(group?.apflowId || "");
    }
  }, [group, selectedGroupId]);

  const handleChange = (value) => {
    if (!value || value === "none") return;
    setValue(value);
    if (isFromGroup && value === group?.apflowId) {
      onChange("");
    } else {
      onChange(value);
    }
  };

  return (
    <>
      <SelectInput
        transitionDuration={0}
        label={label}
        margin={margin}
        autoFocus={autoFocus}
        size={size}
        variant={variant}
        value={value}
        endIcon={
          value && (
            <Tooltip placement="right" title="Remover fluxo">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setValue("");
                  onChange("");
                }}
                sx={{ mr: 3, p: 2 }}
              >
                <Close />
              </IconButton>
            </Tooltip>
          )
        }
        renderValue={(value) => (
          <Box>
            <Typography mb={0.2} fontWeight={"500"} variant="inherit">
              {apFlowsEntities[value]?.title}
            </Typography>
          </Box>
        )}
        onChange={handleChange}
      >
        {status === "loading" ? (
          <LoadingSection h={100} />
        ) : apFlows.length ? (
          apFlows.map((apFlow) => (
            <MenuItem sx={{ py: 1.5 }} key={apFlow?.id} value={apFlow?.id}>
              <Radio
                size="small"
                sx={{ mr: 0.5, ml: -1 }}
                checked={apFlow?.id === value}
              />
              <Box>
                <Typography
                  fontSize={".95rem"}
                  fontWeight={"600"}
                  variant="inherit"
                >
                  {apFlow?.title}
                </Typography>
                <Typography
                  fontSize={".85rem"}
                  sx={{ opacity: 0.8 }}
                  color={apFlow?.notes ? "text.primary" : "text.secondary"}
                  fontWeight={"400"}
                  variant="inherit"
                >
                  {apFlow?.notes || `Sem descrição`}
                </Typography>
              </Box>
            </MenuItem>
          ))
        ) : (
          <Typography
            variant="body2"
            m={2}
            fontWeight={"500"}
            color={"text.secondary"}
          >
            Nenhum fluxo cadastrado
          </Typography>
        )}
      </SelectInput>
      {isFromGroup && value && (
        <FormHelperText
          sx={{
            mt: -1.4,
            mb: 1,
            display: "flex",
            alignItems: "flex-start",
            gap: 1,
          }}
        >
          <InfoOutlined
            color={group?.apflowId === value ? "info" : "warning"}
            sx={{ fontSize: "1rem", mt: 0.2 }}
          />
          <span>
            {group?.apflowId === value
              ? `O fluxo de aprovação atribuído é configurado pelo grupo selecionado.`
              : `Você está atribuindo um fluxo específico para o colaborador.`}
          </span>
        </FormHelperText>
      )}
    </>
  );
}

export default memo(SelectApprovalFlow);
