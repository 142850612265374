import { Add, BusinessOutlined, Close } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ModalCreateOrg from "../../../modals/orgs/create-org/ModalCreateOrg";
import {
  selectOrgsEntities,
  selectOrgsIds,
} from "../../../store/reducers/orgsSlice";
import SelectInput from "../Select";
import { MenuItemStyle } from "./styles";

function OrgSelector({
  initialValue = "",
  showIcon,
  disableClearable,
  width,
  margin,
  size,
  noLabel,
  autoFocus,
  variant = "standard",
  inputRef,
  onChange = () => {},
}) {
  const [create, setCreate] = useState(false);
  const orgs = useSelector(selectOrgsIds);
  const entities = useSelector(selectOrgsEntities);

  const [value, setValue] = useState(initialValue || "");

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <>
      <SelectInput
        inputRef={inputRef}
        StartIcon={showIcon && BusinessOutlined}
        endIcon={
          value &&
          !disableClearable && (
            <Tooltip placement="right" title="Limpar campo">
              <IconButton
                onClick={() => {
                  setValue("");
                  onChange("");
                }}
                sx={{ mr: 3, p: 2 }}
              >
                <Close />
              </IconButton>
            </Tooltip>
          )
        }
        onChange={(v) => {
          if (v === "none") return;
          setValue(v);
          onChange(v);
        }}
        value={value}
        margin={margin}
        width={width}
        autoFocus={autoFocus}
        noLabel={noLabel}
        label={"Organização"}
        variant={variant}
        size={size}
        renderValue={(value) => (
          <Typography variant="inherit" fontWeight={"500"}>
            {entities[value]?.name}
          </Typography>
        )}
      >
        {!Boolean(orgs.length) && (
          <Typography
            variant="body2"
            mx={2}
            mb={2}
            mt={1}
            color={"text.secondary"}
          >
            Nenhuma organização
          </Typography>
        )}
        {orgs.map((orgId) => {
          return (
            <MenuItem key={orgId} value={orgId} sx={MenuItemStyle}>
              {entities[orgId].name}
            </MenuItem>
          );
        })}
        <Divider />
        <MenuItem
          value="none"
          onClick={() => {
            setCreate(true);
          }}
          sx={{ ...MenuItemStyle, color: "primary.main", fontWeight: "600" }}
        >
          <Add fontSize="small" sx={{ mr: 1 }} />
          Criar nova
        </MenuItem>
      </SelectInput>
      <ModalCreateOrg
        open={create}
        onClose={() => setCreate(false)}
        onCreate={(orgId) => {
          setValue(orgId);
          onChange(orgId);
        }}
      />
    </>
  );
}

export default OrgSelector;
