import { Box, Typography } from "@mui/material";
import React, { memo } from "react";

function NoDataContent({
  Icon,
  subtitle,
  title,
  height = 300,
  iconSize = "4rem",
  titleSize = "1.5rem",
  component,
}) {
  return (
    <Box
      component={component}
      px={6}
      height={height}
      display={"flex"}
      alignItems="center"
      justifyContent={"center"}
      flexDirection="column"
    >
      {Icon && (
        <Icon color="action" sx={{ fontSize: iconSize, opacity: 0.3, mb: 2 }} />
      )}
      <Typography
        fontWeight={"500"}
        textAlign={"center"}
        fontSize={titleSize}
        color={"text.primary"}
        component={"div"}
      >
        {title}
      </Typography>
      <Typography
        mt={0.4}
        maxWidth={400}
        textAlign={"center"}
        fontSize={"1rem"}
        component={"div"}
        color={"text.secondary"}
      >
        {subtitle}
      </Typography>
    </Box>
  );
}

export default memo(NoDataContent);
