import {
  Avatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import NoPermissionImage from "../../assets/imgs/no-permission.png";
import { getProfilePicture } from "../../utils/functions/profile";
import NotepayLogo from "../NotepayLogo";

const NoAccessContent = ({
  title = "Você não tem permissão para acessar este recurso",
  children,
  showUserInfo,
  staticMode,
}) => {
  const user = useSelector((state) => state.account.data);

  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      color={"text.primary"}
      height={"800px"}
      display={"flex"}
      alignItems={"center"}
      flexDirection={"column"}
      px={3}
      pb={"80px"}
      pt={5}
    >
      <NotepayLogo style={{ width: "120px" }} />
      <Typography mt={1} mb={7} color={"text.secondary"} textAlign={"center"}>
        Administrador
      </Typography>
      {staticMode ? (
        <img src={NoPermissionImage} width={"200px"} />
      ) : (
        !isSmall && (
          <img
            style={{ position: "fixed", bottom: 0, right: 30 }}
            src={NoPermissionImage}
            width={"310px"}
          />
        )
      )}
      {showUserInfo && (
        <>
          <Avatar
            sx={{ width: 50, height: 50 }}
            src={getProfilePicture(user?._id)}
            alt={user?.name}
          />
          <Typography fontWeight={"500"} variant="h5" mt={2}>
            Olá, {user?.name}.
          </Typography>
          <Typography color={"text.secondary"} variant="body2">
            {user?.email}.
          </Typography>
        </>
      )}

      <Typography
        maxWidth={600}
        mt={3}
        gutterBottom
        fontWeight={"600"}
        textAlign={"center"}
        variant="h5"
      >
        {title}
      </Typography>
      <Typography
        maxWidth={600}
        color={"text.secondary"}
        textAlign={"center"}
        variant="body1"
      >
        Para mais detalhes, por favor consulte um administrador ou proprietário
        da sua empresa
      </Typography>
      <Box mt={3} />
      {children}
    </Box>
  );
};

export default NoAccessContent;
