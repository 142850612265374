import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expanded: true,
};

export const sidebarContentSlice = createSlice({
  name: "sidebarContent",
  initialState,
  reducers: {
    changeExpandedSidebar(state, { payload }) {
      if (typeof payload === "boolean") {
        state.expanded = Boolean(payload);
        return;
      }
      state.expanded = !state.expanded;
    },
  },
});

export const { changeExpandedSidebar } = sidebarContentSlice.actions;

export default sidebarContentSlice.reducer;
