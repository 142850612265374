import { ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ptBR } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import "react-medium-image-zoom/dist/styles.css";
import { useDispatch } from "react-redux";
import "./App.css";
import { getAccount, refreshToken, startRefreshingToken } from "./api/start";
import { AccountSettingsProvider } from "./contexts/useAccountSettings";
import { useThemeMode } from "./contexts/useThemeMode";
import RouterContainer from "./routes/RouterContainer";
import { saveAccount } from "./store/reducers/accountSlice";
import {
  fetchSapSystem,
  setSapIntegrationActivation,
} from "./store/reducers/sapIntegrationSlice";
import theme from "./theme";

/** CHARTS */
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  SubTitle,
  Title,
  Tooltip,
} from "chart.js";
import WebSockets from "./api/websockets";
import LoadingError from "./components/LoadingError";
import NoAdminAccess from "./components/access/NoAdminAccess";
import { fetchApprovalFlows } from "./store/reducers/approvalFlowsSlice";
import { fetchCustomFields } from "./store/reducers/customFieldsSlice";
import { fetchExpenseTypes } from "./store/reducers/expenseTypesSlice";
import { fetchGroups } from "./store/reducers/groupsSlice";
import { fetchUnredNotificationsTotal } from "./store/reducers/notificationsSlice";
import { fetchOccupations } from "./store/reducers/occupationsSlice";
import { fetchOrgs } from "./store/reducers/orgsSlice";
import { fetchPaymentTypes } from "./store/reducers/paymentTypesSlice";
import { fetchProjects } from "./store/reducers/projectsSlice";
import { fetchRouteRates } from "./store/reducers/routeRatesSlice";
import { fetchSavedViews } from "./store/reducers/savedViewsSlice";
import { fetchUsers } from "./store/reducers/usersSlice";
import { store } from "./store/store";

/** Register charts elements */
ChartJS.register(
  LinearScale,
  CategoryScale,
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  Filler,
  Legend,
  Title,
  SubTitle,
  Tooltip,
  LineController,
  BarController
);

function App(props) {
  const dispatch = useDispatch();
  const { mode } = useThemeMode();
  const [loading, setLoading] = useState(true);

  const [hasPermission, setHasPermission] = useState(true);
  const [critialError, setCritialError] = useState(null);
  const [loadingError, setLoadingError] = useState(null);

  useEffect(() => {
    if (window.zE) {
      window.zE("messenger:set", "zIndex", -1);
      window.zE("messenger:on", "close", function () {
        setTimeout(() => {
          window.zE("messenger:set", "zIndex", -1);
        }, 300);
      });
    }
  }, []);

  useEffect(() => {
    if (mode === "dark") {
      document.body.style.backgroundColor = "#202124";
    } else {
      document.body.style.backgroundColor = "#FFF";
    }
  }, [mode]);

  //Verificar se o acesso ainda está válido
  const checkAcessIsValid = (expiresAt) => {
    if (!expiresAt) {
      return false;
    }
    const currentDate = new Date().getTime();
    const expiresAtTime = new Date(expiresAt).getTime();

    const isExpired =
      currentDate > expiresAtTime ||
      Math.floor((expiresAtTime - currentDate) / 60000) <= 2;

    return !isExpired;
  };

  const refreshTokenErrorCallback = (error) => {
    if (error?.response?.status === 403) {
      setHasPermission(false);
      setCritialError(error?.response?.data);
    } else {
      setLoadingError(error);
    }
  };

  const initialDispatchers = (accountData) => {
    dispatch(saveAccount(accountData));
    dispatch(fetchGroups());
    dispatch(fetchUsers());
    dispatch(fetchPaymentTypes());
    dispatch(fetchExpenseTypes());
    dispatch(fetchProjects());
    dispatch(fetchApprovalFlows());
    dispatch(fetchOrgs());
    dispatch(fetchOccupations());
    dispatch(fetchRouteRates());
    dispatch(fetchCustomFields());
    dispatch(
      setSapIntegrationActivation(
        Boolean(
          accountData.account.integrations &&
            accountData.account.integrations.sap &&
            accountData.account.integrations.sap.active
        )
      )
    );
    dispatch(fetchUnredNotificationsTotal());
    dispatch(fetchSapSystem());
    dispatch(fetchSavedViews());
  };

  const removeLoadApplication = () => {
    setLoading(false);
    document.getElementById("load_application").innerHTML = "";
  };

  const startInitialConfiguration = async () => {
    const { isAuth } = await refreshToken({
      onError: refreshTokenErrorCallback,
    });
    if (isAuth) {
      startRefreshingToken();
      setHasPermission(true);
      const { ok, data, error } = await getAccount();
      if (ok) {
        initialDispatchers(data);
        WebSockets.start();
        setLoadingError(null);
      } else {
        if (error?.response?.status === 403) {
          setHasPermission(false);
          setCritialError(error?.response?.data);
        } else {
          setLoadingError(error);
        }
      }

      removeLoadApplication();
    }
  };

  useEffect(() => {
    startInitialConfiguration();

    function onVisibilityChange() {
      const state = store.getState();
      const accessExpiresAt = state.account.accessExpiresAt;
      const ok = checkAcessIsValid(accessExpiresAt);
      if (document.visibilityState === "visible" && !loading) {
        if (!ok) {
          refreshToken({
            onError: refreshTokenErrorCallback,
          });
        }
      }
    }

    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, []);

  if (loading) {
    return;
  } else {
    return (
      <div className="App">
        <ThemeProvider theme={theme(mode)}>
          <LocalizationProvider
            adapterLocale={ptBR}
            dateAdapter={AdapterDateFns}
          >
            <AccountSettingsProvider>
              {loadingError ? (
                <LoadingError error={loadingError} />
              ) : hasPermission ? (
                <RouterContainer {...props} />
              ) : (
                <NoAdminAccess error={critialError} />
              )}
            </AccountSettingsProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
