import { AssignmentOutlined, BarChartOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { memo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectAccountRoles,
  selectIsMe,
} from "../../../../../../store/reducers/accountSlice";
import { onChangeRequestsTableFilters } from "../../../../../../store/reducers/expenseRequestsSlice";
import { selectUserById } from "../../../../../../store/reducers/usersSlice";
import CreateButton from "../../../../../components/CreateButton";
import ActionsMenu from "./ActionsMenu";

function ActionsControlsContent({ userId, lastAccess, status }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const roles = useSelector(selectAccountRoles);

  const isMe = useSelector((state) => selectIsMe(state, userId));

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent={"flex-end"}>
        {lastAccess && (
          <Typography
            textAlign={"rigth"}
            fontSize={".92rem"}
            fontWeight={"500"}
          >
            Último acesso{" "}
            {formatDistanceToNow(new Date(lastAccess), {
              addSuffix: true,
              locale: ptBR,
            })}
          </Typography>
        )}
      </Box>
      <Box
        mt={4}
        display="flex"
        alignItems="center"
        justifyContent={"flex-end"}
        gap={1}
        sx={{
          "& button": {
            height: 40,
            borderColor: "divider",
          },
        }}
      >
        {(roles?.admin?.users_control || roles?.master) && !isMe && (
          <ActionsMenu userId={userId} status={status} />
        )}

        {(roles?.admin?.panel_control || roles?.master) &&
          status !== "inviting" && (
            <CreateButton
              sx={{ mr: 0 }}
              onClick={() => {
                navigate(`/expense-requests`);
                setTimeout(() => {
                  dispatch(
                    onChangeRequestsTableFilters({
                      filter: "users",
                      value: [userId],
                    })
                  );
                }, 1000);
              }}
              Icon={AssignmentOutlined}
              variant="outlined"
              disableCheckPermission
            >
              Ver relatórios de despesas
            </CreateButton>
          )}

        {(roles?.admin?.analysis || roles?.master) && status !== "inviting" && (
          <CreateButton
            onClick={() =>
              navigate(
                `/statistics?timeRange=CURRENT_MONTH&grouping=NONE&users=["${userId}"]`
              )
            }
            Icon={BarChartOutlined}
            variant="outlined"
            sx={{ mr: 0 }}
            disableCheckPermission
          >
            Ver estatísticas
          </CreateButton>
        )}
      </Box>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  const { userId } = ownProps;

  const { last_access, status } = selectUserById(state, userId) || {
    last_access: new Date().toISOString(),
    status: "",
  };

  return { lastAccess: last_access || null, status, ...ownProps };
}

export default connect(mapStateToProps)(memo(ActionsControlsContent));
