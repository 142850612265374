import { Typography } from "@mui/material";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

function Title(props) {
  const navigate = useNavigate();
  return (
    <Typography
      mr={"2em"}
      fontSize={"1.3rem"}
      fontWeight={"500"}
      ml={1.2}
      onClick={() => navigate("/statistics", { replace: true })}
      color={"text.primary"}
      sx={{
        cursor: "pointer",
        opacity: 0.7,
        ":hover": {
          textDecoration: "underline",
        },
      }}
    >
      Administrador
    </Typography>
  );
}

export default memo(Title);
