import {
  Box,
  Dialog,
  DialogContent,
  Slide,
  useScrollTrigger,
} from "@mui/material";
import React, { useState } from "react";
import DialogFullScreenHeader from "./DialogFullScreenHeader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} {...props} direction="up" />;
});

function DialogFullScreenLayout({
  open,
  onClose,
  headerContent,
  children,
  title,
  maxWidth = "sm",
  loading,
  Icon,
  disableInternalContainer,
}) {
  const [content, setContent] = useState(undefined);

  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    target: content,
    threshold: 0,
  });

  return (
    <Dialog
      disableEnforceFocus
      TransitionComponent={Transition}
      TransitionProps={{
        unmountOnExit: true,
      }}
      open={open}
      fullScreen
      PaperProps={{
        elevation: 0,
        sx: {
          borderRadius: 0,
          borderTopLeftRadius: { md: 10, xs: 0 },
          borderTopRightRadius: { md: 10, xs: 0 },
          marginTop: { xs: 0, md: 5 },
          mx: { xs: 0, md: 3 },
        },
      }}
    >
      <DialogFullScreenHeader
        Icon={Icon}
        scrolled={scrolled}
        title={title}
        onClose={onClose}
        loading={loading}
      >
        {headerContent}
      </DialogFullScreenHeader>
      {disableInternalContainer ? (
        children
      ) : (
        <DialogContent
          ref={(node) => node && setContent(node)}
          sx={{
            display: "flex",
            width: "100%",
            pb: 15,
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            width={"100%"}
            maxWidth={maxWidth}
            sx={{ display: "flex", flexDirection: "column", gap: 3 }}
          >
            {children}
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
}

export default DialogFullScreenLayout;
