import { InfoOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { resetRequestsTable } from "../store/reducers/expenseRequestsSlice";
import { store } from "../store/store";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super();
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, info) {
    this.setState({
      error,
      info,
    });
  }
  render() {
    if (this.state.error) {
      return (
        <Box
          height={"70%"}
          display={"flex"}
          justifyContent="center"
          flexDirection={"column"}
          alignItems="center"
        >
          <InfoOutlined sx={{ fontSize: "3rem", mb: 2 }} color="action" />
          <Typography
            variant="h4"
            fontWeight={"500"}
            gutterBottom
            textAlign={"center"}
          >
            Algo deu errado
          </Typography>
          <Typography maxWidth={700} textAlign={"center"} mb={2}>
            Vamos tentar novamente?
          </Typography>
          {/* <Typography>{this.state.info.componentStack}</Typography> */}
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              window.location.reload();
              store.dispatch({
                type: "RESET_STORE",
              });
              window.localStorage.removeItem("statistics-last-search");
              store.dispatch(resetRequestsTable());
            }}
          >
            Tentar novamente
          </Button>
        </Box>
      );
    }
    return this.props.children;
  }
}
