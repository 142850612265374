import { Close, Search } from "@mui/icons-material";
import { Backdrop, IconButton, Paper, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { HttpClient } from "../../api/http-client";
import { isStaging } from "../../theme";
import ContentSearch from "./ContentSearch";
import StyledInput from "./StyledInput";

const PaperCover = ({ children, contentSearch, renderInput, loading }) => {
  const [focused, setFocused] = useState(false);
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          zIndex: (t) => t.zIndex.appBar + 1001,
          bgcolor: (t) =>
            t.palette.mode === "dark"
              ? !focused
                ? "#27282C"
                : "#323338"
              : !focused
              ? isStaging
                ? "#E8E7E7"
                : "#E8EEF6"
              : "white",
          borderColor: (t) =>
            t.palette.mode === "dark"
              ? `rgba(255,255,255,.2)`
              : "rgba(0,0,0,.2)",
          mr: 4,
          position: "relative",
          transition: "none",
          borderRadius: 100,
          border: 0,
          px: 1,
          height: 45,
          display: { xs: "none", md: "flex" },
          alignItems: "center",
          width: 700,
          boxShadow: focused ? 2 : 0,
        }}
      >
        <IconButton>
          <Search />
        </IconButton>
        {renderInput({
          onFocus: () => setFocused(true),
        })}
        {children}
        {focused && contentSearch({ onBlur: () => setFocused(false) })}
      </Paper>
      <Backdrop
        sx={{ bgcolor: "transparent" }}
        onClick={() => setFocused(false)}
        open={focused}
      />
    </>
  );
};

const defaultPlaceholder = "Pesquisar colaboradores, configurações e mais";

function SearchBar(props) {
  const { palette } = useTheme();

  const { pathname } = useLocation();

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState({});

  useEffect(() => {
    if (search !== "") {
      setLoading(true);
      const delayDebounce = setTimeout(() => {
        searchQuery(search);
      }, 200);

      return () => clearTimeout(delayDebounce);
    }
  }, [search]);

  useEffect(() => {
    const searchEl = document.getElementById("search_main");
    if (searchEl) {
      searchEl.placeholder = defaultPlaceholder;
    }
  }, [pathname]);

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const searchQuery = async (value) => {
    try {
      const { data } = await HttpClient.client().get(`/search`, {
        params: {
          text: value,
        },
      });
      setResults(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PaperCover
      renderInput={({ onFocus }) => (
        <StyledInput
          id="search_main"
          placeholder={defaultPlaceholder}
          color={palette.text.primary}
          onFocus={onFocus}
          onChange={handleChange}
        />
      )}
      contentSearch={({ onBlur }) =>
        search && (
          <ContentSearch
            loading={loading}
            results={results}
            searchTerm={search}
            onBlur={() => {
              onBlur();
              setSearch("");
              setResults([]);
              const inpuEl = document.getElementById("search_main");
              if (inpuEl) {
                inpuEl.value = "";
              }
            }}
          />
        )
      }
    >
      {search && (
        <IconButton
          onClick={(e) => {
            setSearch("");
            setResults({});
            const inpuEl = document.getElementById("search_main");
            if (inpuEl) {
              inpuEl.value = "";
            }
          }}
        >
          <Close />
        </IconButton>
      )}
    </PaperCover>
  );
}

export default SearchBar;
