import {
  Add,
  BadgeOutlined,
  Check,
  Close,
  EditOutlined,
  FormatListBulletedOutlined,
  MoreVert,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HttpClient } from "../../../../api/http-client";
import HelpIconButton from "../../../../components/buttons/HelpIconButton";
import NoDataContent from "../../../../components/form/NoDataContent";
import TextInput from "../../../../components/inputs/text-input";
import LoadingSection from "../../../../components/loading/LoadingSection";
import ModalDelete from "../../../../modals/ModalDelete";
import ModalCreateOccupation from "../../../../modals/occupations/create/ModalCreateOccupation";
import { selectHasSettingsPermission } from "../../../../store/reducers/accountSlice";
import { openSnackbar } from "../../../../store/reducers/bases/snackbarBaseSlice";
import {
  fetchOccupations,
  removeOccupation,
  selectOccupationById,
  selectOccupationsIds,
  updateOccupation,
} from "../../../../store/reducers/occupationsSlice";
import {
  selectUsersIdsByOccupation,
  updateManyUsers,
} from "../../../../store/reducers/usersSlice";
import { HighlightedText } from "../../../../utils/functions/highlightText";

const RemoveItem = ({ occupationId }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const usersIdsInOccupation = useSelector((s) =>
    selectUsersIdsByOccupation(s, occupationId)
  );

  const handleDelete = async () => {
    try {
      await HttpClient.client().delete(`/occupations/${occupationId}`);
      dispatch(removeOccupation(occupationId));
      dispatch(openSnackbar({ message: "Cargo removido" }));
      dispatch(
        updateManyUsers({
          ids: usersIdsInOccupation,
          changes: { occupation: null },
        })
      );
    } catch (error) {}
  };

  return (
    <>
      <MenuItem
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        Excluir
      </MenuItem>
      <ModalDelete
        open={open}
        onClose={() => setOpen(false)}
        title="Exlcuir o cargo"
        onDelete={handleDelete}
      />
    </>
  );
};

const OccupationItemMore = ({ occupationId, onClose, disableRemoveButton }) => {
  const [menu, setMenu] = useState(null);
  const navigate = useNavigate();
  return (
    <>
      <IconButton
        sx={{ bgcolor: Boolean(menu) && "action.focus" }}
        onClick={(e) => setMenu(e.target)}
      >
        <MoreVert fontSize="small" />
      </IconButton>
      <Menu anchorEl={menu} open={Boolean(menu)} onClose={() => setMenu(null)}>
        {!disableRemoveButton && <RemoveItem occupationId={occupationId} />}
      </Menu>
    </>
  );
};

export const OccupationItem = memo(
  ({
    occupationId,
    onClose,
    searchTerm,
    activeHighlightedText,
    textSx = {},
    disableRemoveButton,
    hasPermission,
    activeIcon,
  }) => {
    const dispatch = useDispatch();

    const [editMode, setEditMode] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [loading, setLoading] = useState(false);

    const { name } = useSelector((s) => selectOccupationById(s, occupationId));

    const inputRef = useRef();

    const handleSave = async () => {
      setLoading(true);
      try {
        const newName = inputRef.current?.value;
        await HttpClient.client().put(`/occupations/${occupationId}`, {
          name: newName,
        });
        dispatch(
          updateOccupation({ id: occupationId, changes: { name: newName } })
        );
        dispatch(openSnackbar({ message: "Cargo salvo" }));
      } catch (error) {
      } finally {
        setEditMode(false);
        setLoading(false);
      }
    };

    return (
      <ListItem
        onMouseLeave={() => setHovered(false)}
        onMouseEnter={() => setHovered(true)}
        sx={{ height: 50, ":hover": { bgcolor: "action.hover" } }}
      >
        {editMode ? (
          <>
            <Tooltip title="Cancelar">
              <IconButton onClick={() => setEditMode(false)} sx={{ ml: -1 }}>
                <Close fontSize="small" />
              </IconButton>
            </Tooltip>
            <Box
              flex={1}
              component={"form"}
              onSubmit={(e) => {
                e.preventDefault();
                handleSave();
              }}
            >
              <TextInput
                inputRef={inputRef}
                autoFocus
                value={name}
                activeInternalValue
                margin={"none"}
                variant={"standard"}
              />
            </Box>
          </>
        ) : (
          <>
            {activeIcon && (
              <BadgeOutlined color="action" sx={{ mr: 2, ml: 0.8 }} />
            )}
            <ListItemText
              primaryTypographyProps={{
                fontWeight: "500",
                fontSize: ".95rem",
                ...textSx,
              }}
              primary={
                activeHighlightedText ? (
                  <HighlightedText searchTerm={searchTerm} text={name} />
                ) : (
                  name
                )
              }
            />
          </>
        )}
        {editMode ? (
          <>
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton onClick={handleSave} color="primary">
                <Check />
              </IconButton>
            )}
          </>
        ) : (
          hovered &&
          hasPermission && (
            <>
              <IconButton onClick={() => setEditMode(true)}>
                <EditOutlined fontSize="small" />
              </IconButton>
              <OccupationItemMore
                onClose={onClose}
                occupationId={occupationId}
                disableRemoveButton={disableRemoveButton}
              />
            </>
          )
        )}
      </ListItem>
    );
  }
);

const CreateButton = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<Add />}
        onClick={() => setOpen(true)}
      >
        Criar cargo
      </Button>
      <ModalCreateOccupation open={open} onClose={() => setOpen(false)} />
    </>
  );
};

function MenuOccupations({ open, onClose }) {
  const dispatch = useDispatch();

  const occupationsIds = useSelector(selectOccupationsIds);
  const hasPermission = useSelector(selectHasSettingsPermission);

  const status = useSelector((state) => state.occupations.status);

  useEffect(() => {
    if (open) {
      if (status === "idle") {
        dispatch(fetchOccupations());
      }
    }
  }, [open]);

  return (
    <Card variant="outlined" sx={{ width: "100%", borderRadius: 3 }}>
      <CardContent sx={{ p: 0, pb: 3 }}>
        <Box p={2} py={1} display={"flex"} alignItems={"center"}>
          <Typography fontSize={"1.3rem"} fontWeight={"500"}>
            Cargos ({occupationsIds.length})
          </Typography>
          <Box flex={1} />
          <HelpIconButton size="medium" title={"Cargos"}>
            Os cargos podem te ajudar a organizar e visualizar melhor os
            colaboradores da conta
          </HelpIconButton>
        </Box>
        <List disablePadding>
          {status === "loading" ? (
            <LoadingSection h={150} />
          ) : (
            <>
              {!Boolean(occupationsIds.length) && (
                <NoDataContent
                  height={120}
                  titleSize="1rem"
                  iconSize="3rem"
                  title={"Nenhum cargo adicionado"}
                  Icon={FormatListBulletedOutlined}
                />
              )}
              {occupationsIds.map((occupationId) => (
                <OccupationItem
                  hasPermission={hasPermission}
                  key={occupationId}
                  occupationId={occupationId}
                  onClose={onClose}
                />
              ))}
            </>
          )}
        </List>
      </CardContent>
      {hasPermission && (
        <>
          <CardActions sx={{ p: 2 }}>
            <CreateButton />
          </CardActions>
        </>
      )}
    </Card>
  );
}

export default memo(MenuOccupations);
