import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { HighlightedText } from "../../../../utils/functions/highlightText";

function BaseIconItem({
  option = { name: "", notes: "" },
  searchTerm,
  Icon,
  onClick,
  disableNotesEmptyIndicator,
}) {
  return (
    <ListItem disablePadding dense disableGutters>
      <ListItemButton onClick={onClick} sx={{ transition: "none" }}>
        {Icon && <Icon color="action" sx={{ mr: 2, ml: 0.8 }} />}
        <ListItemText
          primaryTypographyProps={{ fontWeight: "500" }}
          primary={
            <HighlightedText searchTerm={searchTerm} text={option.name} />
          }
          secondary={
            option.notes ? (
              <HighlightedText searchTerm={searchTerm} text={option.notes} />
            ) : (
              !disableNotesEmptyIndicator && "(Sem notas)"
            )
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

export default BaseIconItem;
