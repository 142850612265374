import { Box, LinearProgress } from "@mui/material";
import React, { memo } from "react";

const Fallback = (props) => {
  return (
    <Box
      bgcolor={(t) => `${t.palette.background.default}90`}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: (t) => t.zIndex.appBar + 1000,
        height: "100vh",
        width: "100vw",
      }}
    >
      <LinearProgress
        sx={{
          width: "100%",
          height: "2px",
        }}
      />
    </Box>
  );
};

export default memo(Fallback);
