import { Box, Button, Typography } from "@mui/material";
import React from "react";
import NotepayLogo from "./NotepayLogo";

const LoadingError = ({ error }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={600}
      justifyContent={"center"}
      alignItems={"center"}
      mx={3}
    >
      <NotepayLogo style={{ width: "120px" }} />
      <Typography fontWeight={"500"} textAlign={"center"} mt={3} variant="h5">
        Ocorreu um erro inesperado
      </Typography>
      <Button
        variant="contained"
        onClick={() => window.location.reload()}
        sx={{ mt: 2 }}
      >
        Atualizar página
      </Button>
    </Box>
  );
};

export default LoadingError;
