import { Groups2Outlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  fetchGroups,
  selectGroupById,
} from "../../../../../../../../store/reducers/groupsSlice";
import { selectUserById } from "../../../../../../../../store/reducers/usersSlice";
import DetailListItem from "../../../../../components/DetailListItem";
import MenuView from "./MenuView";

function GroupItem({ groupId, groupName, groupStatus, userId }) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (groupStatus === "idle") {
      dispatch(fetchGroups());
    }
  }, []);

  return (
    <>
      <DetailListItem
        emptyText={"Sem grupo"}
        Icon={Groups2Outlined}
        title={"Grupo"}
        focused={modal}
        loading={groupStatus === "loading"}
        onClick={(e) => setModal(true)}
      >
        {Boolean(groupId && groupName) && (
          <Typography
            component={"span"}
            variant="inherit"
            fontWeight={"500"}
          >
            {groupName}
          </Typography>
        )}
      </DetailListItem>
      <MenuView
        open={modal}
        onClose={() => setModal(false)}
        groupId={groupId}
        userId={userId}
      />
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { group_id } = selectUserById(state, ownProps.userId) || {
    group_id: "",
  };

  const groupData = selectGroupById(state, group_id);

  return {
    groupName: groupData?.name,
    groupId: group_id,
    groupStatus: state.groups.status,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(memo(GroupItem));
