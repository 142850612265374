export const rootMigrations = {
  0: (state) => {
    return {
      ...state,
      account: {
        ...state?.account,
        receiptsAccessToken: {
          token: null,
          expiresAt: null,
        },
      },
    };
  },

  //2.0.0 updates
  1: (state) => {
    return {
      ...state,
      account: {
        ...state?.account,
        hasUpdatesInVersion: "2.0.0",
        checkedUpdates: false,
      },
    };
  },
};

export const expenseRequestsMigrations = {
  0: (state) => {
    return {
      ...state,
      filters: {
        date: {
          type: "this-month",
          meta: {},
        },
      },
      reset: true,
    };
  },
  1: (state) => {
    return {
      ...state,
      fixedFilters: {
        users: true,
        status: true,
        sap: true,
      },
      reset: true,
    };
  },
  2: (state) => {
    return {
      ...state,
      filters: {
        date: {
          type: "this-month",
          meta: {},
        },
      },
      savedViews: {
        status: "idle",
        data: [],
        active: null,
      },
      reset: true,
    };
  },
  3: (state) => {
    return {
      ...state,
      reset: true,
    };
  },
  4: (state) => {
    return {
      ...state,
      fixedFilters: {
        users: true,
        status: true,
        sap: false,
      },
      reset: true,
    };
  },
  5: (state) => {
    return {
      ...state,
      reset: true,
      activeSavedView: null,
    };
  },
};
