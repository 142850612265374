import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //users
  newUser: {
    open: false,
    util: null,
  },
  inviteUsers: {
    open: false,
    util: null,
  },
  createUsersInvitation: {
    open: false,
    util: null,
  },

  //expType
  viewExpType: {
    open: false,
    util: null,
  },

  //rules
  viewExpPolicy: {
    open: false,
    util: null,
  },
  viewRequestLimit: {
    open: false,
    util: null,
  },

  //customFields
  customFieldView: {
    open: false,
    util: null,
  },

  //groups
  groupView: {
    open: false,
    util: null,
  },

  //route
  viewRoutePolicy: {
    open: false,
    util: null,
  },

  //apFlow
  viewApFlow: {
    open: false,
    util: null,
  },

  //sap system
  viewSapSystem: {
    open: false,
    util: null,
  },

  //projects
  viewProject: {
    open: false,
    util: null,
  },

  //orgs
  viewOrg: {
    open: false,
    util: null,
  },
  //orgs
  modalSnippet: {
    open: false,
    util: null,
  },

  //more
  newRequestsView: {
    open: false,
    util: null,
  },
  duplicatedChecker: {
    open: false,
    util: null,
  },
  requestExportPDF: {
    open: false,
    util: null,
  },
};

const portalSlice = createSlice({
  name: "portal",
  initialState,
  reducers: {
    openElement: (state, action) => {
      const { name, util } = action.payload;
      state[name].open = true;
      state[name].util = util || null;
    },
    closeElement: (state, action) => {
      state[action.payload].open = false;
      state[action.payload].util = null;
    },
  },
});

export const { openElement, closeElement } = portalSlice.actions;

export default portalSlice.reducer;

export const portalInitialState = initialState;
