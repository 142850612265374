import { OpenInNewOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  ImageListItem,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import React, { memo } from "react";
import Zoom from "react-medium-image-zoom";
import { useDispatch } from "react-redux";
import { openElement } from "../../../../../../../../store/reducers/portalSlice";
import { getReceiptUrl } from "../../../../../../../../utils/functions/receipts";
import TagsIndicator from "./TagsIndicator";

const ReceiptItem = ({ receipt = {}, duplicatedReceipts = [], userId }) => {
  const dispatch = useDispatch();

  const url = getReceiptUrl(receipt?.url);

  const { shadows } = useTheme();

  return (
    <Zoom>
      <ImageListItem sx={{ position: "relative", minHeight: 100 }}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            window.open(url);
          }}
          size="small"
          sx={{
            position: "absolute",
            top: 3,
            right: 3,
            bgcolor: "background.default",
            ":hover": {
              bgcolor: "background.default",
            },
          }}
        >
          <OpenInNewOutlined fontSize="small" />
        </IconButton>
        <img
          src={url}
          alt={receipt?.filename}
          style={{
            borderRadius: 10,
            boxShadow: shadows[1],
          }}
        />
        {(Boolean(receipt?.tags?.length) ||
          Boolean(duplicatedReceipts?.length)) && (
          <Box
            mt={-2}
            border={1}
            borderRadius={3}
            boxShadow={1}
            borderColor={"divider"}
            p={1}
            pt={3}
          >
            {Boolean(receipt?.tags?.length) && (
              <TagsIndicator tags={receipt?.tags || []} />
            )}
            {Boolean(duplicatedReceipts?.length) && (
              <Box
                mt={1}
                mx={-1}
                p={1}
                display={"flex"}
                flexDirection={"column"}
                bgcolor={(t) => `${t.palette.warning.main}10`}
              >
                <Typography
                  textAlign={"center"}
                  variant="body2"
                  fontWeight={"600"}
                  mb={0.4}
                >
                  Risco de duplicação
                </Typography>
                <Typography
                  textAlign={"center"}
                  variant="caption"
                  color={"text.secondary"}
                >
                  É possível que este comprovante tenha sido utilizado
                  anteriormente
                </Typography>
                <Link
                  mt={0.5}
                  component={"div"}
                  alignSelf={"center"}
                  fontWeight={"600"}
                  textAlign={"center"}
                  variant="body2"
                  onClick={() => {
                    dispatch(
                      openElement({
                        name: "duplicatedChecker",
                        util: {
                          receipt_url: receipt?.url,
                          user_id: userId,
                          duplicatedReceipts,
                        },
                      })
                    );
                  }}
                >
                  Comparar
                </Link>
              </Box>
            )}
          </Box>
        )}
      </ImageListItem>
    </Zoom>
  );
};

export default memo(ReceiptItem);
