import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";

const baseName = "groups";

const adapter = createEntityAdapter({
  selectId: (user) => user.id,
  sortComparer: (a, b) =>
    a?.name?.toUpperCase()?.localeCompare(b?.name?.toUpperCase()),
});

const initialState = adapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchGroups = createAsyncThunk(
  `${baseName}/fetchGroups`,
  async () => {
    const res = await HttpClient.client().get(`/groups`);
    return res.data;
  }
);

export const groupsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    addGroup(state, { payload }) {
      adapter.addOne(state, { ...payload, active: true });
    },
    updateGroup(state, { payload }) {
      const { id, changes } = payload;
      adapter.updateOne(state, {
        changes,
        id,
      });
    },
    removeGroup(state, { payload }) {
      adapter.removeOne(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroups.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        const data = action.payload;
        state.ids = data?.ids || [];
        state.entities = data?.entities || {};
      });
  },
});

export const { addGroup, updateGroup, removeGroup } = groupsSlice.actions;

export const {
  selectAll: selectAllGroups,
  selectIds: selectGroupsIds,
  selectById: selectGroupById,
  selectEntities: selectGroupsEntities,
  selectTotal: selectGroupsTotal,
} = adapter.getSelectors((state) => state[baseName]);

export default groupsSlice.reducer;
