import {
  AccountTreeOutlined,
  PersonPinCircleOutlined,
  PostAddOutlined,
  RuleOutlined,
} from "@mui/icons-material";
import { List, ListItem } from "@mui/material";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchOccupations } from "../../../store/reducers/occupationsSlice";
import { fetchOrgs } from "../../../store/reducers/orgsSlice";
import { openElement } from "../../../store/reducers/portalSlice";
import ListHeaderSection from "./ListHeaderSection";
import BaseIconItem from "./items/BaseIconItem";
import ExpTypeItem from "./items/ExpTypeItem";
import OccupationItem from "./items/OccupationItem";
import OrgItem from "./items/OrgItem";
import UserListItem from "./items/UserListItem";

function ListSection({
  sectionKey,
  options = [],
  path,
  title,
  Icon,
  viewName,
  viewPropId,
  onBlur,
  searchTerm,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickSearchItem = (id) => {
    navigate(path);
    dispatch(openElement({ name: viewName, util: { [viewPropId]: id } }));
    onBlur();
  };

  const sectionStatus = useSelector((state) =>
    state[sectionKey] ? state[sectionKey].status : "succeeded"
  );

  useEffect(() => {
    if (sectionStatus === "idle") {
      if (sectionKey === "orgs") {
        dispatch(fetchOrgs());
        return;
      }
      if (sectionKey === "occupations") {
        dispatch(fetchOccupations());
        return;
      }
    }
  }, []);

  const renderListItem = (option, idx) => {
    const id = option._id;
    const key = option._id + idx.toString();

    const onClick = () => onClickSearchItem(id);

    switch (sectionKey) {
      case "exp_types":
        return (
          <ExpTypeItem
            onClick={onClick}
            onBlur={onBlur}
            type={option}
            searchTerm={searchTerm}
            key={key}
          />
        );
      case "sap_systems":
        return (
          <BaseIconItem
            onClick={onClick}
            option={option}
            searchTerm={searchTerm}
            key={key}
          />
        );
      case "rules":
        return (
          <BaseIconItem
            onClick={onClick}
            Icon={RuleOutlined}
            option={{ name: option.name, notes: option.description }}
            searchTerm={searchTerm}
            key={key}
          />
        );
      case "orgs":
        return (
          <OrgItem
            onClose={onBlur}
            name={option.name}
            orgId={option._id}
            searchTerm={searchTerm}
            key={key}
          />
        );
      case "route_policies":
        return (
          <BaseIconItem
            onClick={onClick}
            Icon={PersonPinCircleOutlined}
            option={{ name: option.title, notes: option.notes }}
            searchTerm={searchTerm}
            key={key}
          />
        );
      case "users":
        return (
          <UserListItem
            onBlur={onBlur}
            searchTerm={searchTerm}
            key={key}
            user={option}
          />
        );
      case "apflows":
        return (
          <BaseIconItem
            onClick={onClick}
            Icon={AccountTreeOutlined}
            option={{ name: option.title, notes: option.notes }}
            searchTerm={searchTerm}
            key={key}
          />
        );
      case "projects":
        return (
          <BaseIconItem
            onClick={onClick}
            Icon={PostAddOutlined}
            option={{ name: option.name, notes: option.description }}
            searchTerm={searchTerm}
            key={key}
          />
        );
      case "general_configs":
        return (
          <BaseIconItem
            onClick={() => {
              navigate("my-company");
              onBlur();
            }}
            disableNotesEmptyIndicator
            option={{ name: option.name, notes: option.value }}
            searchTerm={searchTerm}
            key={key}
          />
        );
      case "occupations":
        return (
          <OccupationItem
            onClose={onBlur}
            name={option.name}
            occupationId={option._id}
            searchTerm={searchTerm}
            key={key}
          />
        );

      default:
        return <ListItem key={key}>{key}</ListItem>;
    }
  };

  return (
    <List sx={{ borderBottom: 1, borderColor: "divider" }}>
      <ListHeaderSection
        onBlur={onBlur}
        title={`${title} (${options.length})`}
        path={path}
      />
      {options.map(renderListItem)}
    </List>
  );
}

export default memo(ListSection);
