import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";

const baseName = "customFields";

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchCustomFields = createAsyncThunk(
  `${baseName}/fetchCustomFields`,
  async () => {
    const res = await HttpClient.client().get(`/custom-fields`);
    return res.data;
  }
);

export const customFieldsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    addCustomField(state, { payload }) {
      adapter.addOne(state, { ...payload, active: true });
    },
    updateCustomField(state, { payload }) {
      const { id, changes } = payload;
      adapter.updateOne(state, {
        changes,
        id,
      });
    },
    removeCustomField(state, { payload }) {
      adapter.removeOne(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomFields.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCustomFields.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchCustomFields.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        const data = action.payload;
        state.ids = data.ids;
        state.entities = data.entities;
      });
  },
});

export const { addCustomField, updateCustomField, removeCustomField } =
  customFieldsSlice.actions;

export const {
  selectAll: selectAllCustomFields,
  selectIds: selectCustomFieldsIds,
  selectById: selectCustomFieldById,
  selectEntities: selectCustomFieldsEntities,
  selectTotal: selectCustomFieldsTotal,
} = adapter.getSelectors((state) => state[baseName]);

export default customFieldsSlice.reducer;
