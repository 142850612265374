import { ChevronRightOutlined, EditOffOutlined } from "@mui/icons-material";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { selectHasUsersControlPermission } from "../../../../store/reducers/accountSlice";

function DetailListItem({
  Icon,
  title,
  divider = true,
  emptyText,
  children,
  loading,
  onClick = () => {},
  disableClickable,
  focused,
}) {
  const permited = useSelector(selectHasUsersControlPermission);

  return (
    <ListItem
      sx={{ height: 50 }}
      divider={divider}
      disableGutters
      disablePadding
    >
      <ListItemButton
        disableRipple={!permited}
        selected={focused}
        onClick={(e) => !loading && permited && onClick(e)}
        disableTouchRipple={loading}
        sx={{
          height: 50,
          transition: "none",
          cursor: permited ? "pointer" : "default",
        }}
      >
        <Box width={220} display={"flex"} alignItems="center">
          {Icon && <Icon sx={{ opacity: 0.8, mr: 2 }} fontSize="small" />}
          <Typography
            fontWeight={"500"}
            width={220}
            fontSize=".92rem"
            sx={{ opacity: 0.9 }}
          >
            {title}
          </Typography>
        </Box>
        <ListItemText
          sx={{ fontSize: ".92rem", fontWeight: "500" }}
          primaryTypographyProps={{
            noWrap: true,
            fontSize: ".92rem",
            color: children ? "inherit" : "text.secondary",
          }}
          primary={loading ? <Skeleton width={"60%"} /> : children || emptyText}
        />
        {permited ? (
          <ChevronRightOutlined color="action" />
        ) : (
          <EditOffOutlined color="disabled" />
        )}
      </ListItemButton>
    </ListItem>
  );
}

export default DetailListItem;
