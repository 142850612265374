import { Collapse, Divider, List } from "@mui/material";
import React, { memo, useState } from "react";
import ListSectionTitle from "./ListSectionTitle";

const ListSection = ({
  showDots,
  title = "",
  defaultExpanded = true,
  children,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <List disablePadding sx={{ mt: 1 }}>
      <ListSectionTitle
        expanded={expanded}
        onClick={() => setExpanded(!expanded)}
      >
        {!showDots ? title : null}
      </ListSectionTitle>
      <Collapse in={expanded}>{children}</Collapse>
      <Divider sx={{ mt: 1 }} />
    </List>
  );
};

export default memo(ListSection);
