import React, { memo } from "react";
import { OccupationItem as OccupationListItem } from "../../../../pages/my_company/components/items/MenuOccupations";

function OccupationItem({ name, onClose, occupationId, searchTerm }) {
  return (
    <OccupationListItem
      occupationId={occupationId}
      activeIcon
      activeHighlightedText
      searchTerm={searchTerm}
      textSx={{ fontSize: ".9rem" }}
      disableRemoveButton
      onClose={onClose}
    />
  );
}

export default memo(OccupationItem);
