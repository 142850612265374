import {
  AssignmentOutlined,
  BarChartOutlined,
  BusinessOutlined,
  CreditCardOutlined,
  Groups2Outlined,
  IntegrationInstructionsOutlined,
  PeopleAltOutlined,
  ReceiptLongOutlined,
  SettingsOutlined,
  SupportAgentOutlined,
} from "@mui/icons-material";
import { Box, Divider, List } from "@mui/material";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LogoSap from "../../assets/logo/logo-sap.png";
import { selectAccountRoles } from "../../store/reducers/accountSlice";
import { ACCOUNT_STATUS_BANNER_HEIGHT } from "../AccountStatusBanner";
import ListSection from "./ListSection";
import SidebarItem from "./SidebarItem";
import { organizationsConfig } from "./data";
import "./styles.css";

const isSelected = (link, pathname) => {
  return new RegExp(`${link}`).test(pathname);
};

function Sidebar({ accountReadOnly }) {
  const [hover, setHover] = useState(false);

  const { pathname } = useLocation();

  const expanded = useSelector((state) => state.sidebarContent.expanded);

  const roles = useSelector(selectAccountRoles);

  const showDots = useMemo(
    () => !Boolean(expanded || hover),
    [expanded, hover]
  );

  let timeoutHover = setTimeout(() => {}, 1000);

  const handleMouseEnter = useCallback(() => {
    if (expanded) return;
    timeoutHover = setTimeout(() => {
      setHover(true);
    }, 200);
  }, [expanded, hover]);

  const handleMouseLeave = useCallback(() => {
    clearTimeout(timeoutHover);
    setHover(false);
  }, [hover]);

  //renders
  const renderSAPLogo = useCallback(
    () => (
      <img
        style={{
          filter: !pathname.includes("sap-integration") && "grayscale(100%)",
          scale: "1.1",
        }}
        src={LogoSap}
        width="30px"
      />
    ),
    [pathname.includes("sap-integration")]
  );

  const getIsSelected = (path) => isSelected(path, pathname);

  return (
    <Box
      className="sidebar"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        overflow: "auto",
        overscrollBehavior: "contain",
        display: { xs: "none", xl: "flex" },
        flex: 1,
        flexBasis: 0,
        bgcolor: "elevation1.main",
        pb: 10,
        top: accountReadOnly ? 60 + ACCOUNT_STATUS_BANNER_HEIGHT : 64,
        pr: expanded || hover ? 0.8 : 0,
        width: expanded || hover ? "15.6em" : "4.2em",
        transitionDuration: ".2s",
        WebkitTransitionTimingFunction: "cubic-bezier(0.4,0,0.2,1)",
        transitionTimingFunction: "cubic-bezier(0.4,0,0.2,1)",
        overflowX: "hidden",
        flexDirection: "column",
        boxShadow: hover && "0px 7px 6px rgb(0 0 0 / 40%)",
        zIndex: (t) => t.zIndex.appBar + 10,
        overflowY: "auto",
        overflowX: "hidden",
        position: "fixed",
        height: "95%",
      }}
    >
      <List>
        <SidebarItem
          Icon={BarChartOutlined}
          path={"statistics"}
          title={"Visão geral"}
          isSelected={getIsSelected("statistics")}
        />
        <Divider sx={{ mt: 1, position: "sticky", top: 0 }} />
        <ListSection showDots={showDots} title="Financeiro">
          <SidebarItem
            Icon={AssignmentOutlined}
            path={"expense-requests"}
            title={"Relatórios"}
            isSelected={getIsSelected("expense-requests")}
          />
          <SidebarItem
            Icon={ReceiptLongOutlined}
            path={"all-expenses"}
            title={"Despesas"}
            isSelected={getIsSelected("all-expenses")}
          />
        </ListSection>
        <ListSection showDots={showDots} title="Conta">
          <SidebarItem
            Icon={BusinessOutlined}
            path={"company-info"}
            title={"Empresa"}
            isSelected={getIsSelected("company-info")}
          />
          <SidebarItem
            Icon={Groups2Outlined}
            path={"groups"}
            title={"Grupos"}
            isSelected={getIsSelected("groups")}
          />
          <SidebarItem
            Icon={PeopleAltOutlined}
            path={"collaborators"}
            title={"Colaboradores"}
            isSelected={getIsSelected("collaborators")}
          />
          {roles.master && (
            <>
              <SidebarItem
                Icon={CreditCardOutlined}
                path={"billing"}
                title={"Plano e faturamento"}
                isSelected={getIsSelected("billing")}
              />
              <SidebarItem
                Icon={SettingsOutlined}
                path={"advanced-settings"}
                title={"Avançado"}
                isSelected={getIsSelected("advanced-settings")}
              />
            </>
          )}
        </ListSection>
        <ListSection showDots={showDots} title="Configurações">
          {organizationsConfig.map((item, idx) => (
            <SidebarItem
              key={idx.toString()}
              Icon={item.icon}
              path={item.path}
              title={item.title}
              isSelected={getIsSelected(item.path)}
            />
          ))}
        </ListSection>
        <ListSection showDots={showDots} title="Integrações">
          <SidebarItem
            Icon={renderSAPLogo}
            path={"sap-integration"}
            title={"Integração SAP"}
            isSelected={getIsSelected("sap-integration")}
          />
          <SidebarItem
            Icon={IntegrationInstructionsOutlined}
            path={"api-rest"}
            title={"Notepay API"}
            isSelected={getIsSelected("api-rest")}
          />
        </ListSection>
        <Box mt={1} />
        <SidebarItem
          Icon={SupportAgentOutlined}
          path={"#"}
          title={"Suporte"}
          isSelected={false}
          onClick={() => {
            const helpButton = document.getElementById(
              "notepay-support-button"
            );
            if (helpButton) {
              helpButton.click();
            }
          }}
        />
        <Box mt={1} />
      </List>
    </Box>
  );
}

export default memo(Sidebar);
