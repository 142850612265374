export const getSectionDataByKey = (sectionKey) => {
  let obj = {
    title: sectionKey,
    path: sectionKey,
    viewPropId: sectionKey,
    viewName: sectionKey,
    Icon: null,
  };

  switch (sectionKey) {
    case "exp_types":
      obj.path = "expense-types";
      obj.title = "Categorias de despesa";
      obj.viewName = "viewExpType";
      obj.viewPropId = "expTypeId";
      break;
    case "sap_systems":
      obj.path = "sap-integration";
      obj.title = "Sistemas SAP";
      obj.viewName = "viewSapSystem";
      obj.viewPropId = "systemId";
      break;
    case "rules":
      obj.path = "expense-policies";
      obj.title = "Regras de despesas";
      obj.viewName = "viewExpPolicy";
      obj.viewPropId = "expPolicyId";
      break;
    case "orgs":
      obj.path = "#";
      obj.title = "Organizações";
      obj.viewName = "";
      obj.viewPropId = "";
      break;
    case "occupations":
      obj.path = "#";
      obj.title = "Cargos";
      obj.viewName = "";
      obj.viewPropId = "";
      break;
    case "route_policies":
      obj.path = "route-policies";
      obj.title = "Políticas de percurso";
      obj.viewName = "viewRoutePolicy";
      obj.viewPropId = "routePolicyId";
      break;
    case "users":
      obj.path = "collaborators";
      obj.title = "Colaboradores";
      break;
    case "apflows":
      obj.path = "approval-flows";
      obj.title = "Fluxos de aprovação";
      obj.viewName = "viewApFlow";
      obj.viewPropId = "apFlowId";
      break;
    case "projects":
      obj.path = "projects";
      obj.title = "Projetos";
      obj.viewName = "viewProject";
      obj.viewPropId = "projectId";
      break;

    default:
      break;
  }
  return obj;
};
