import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useThemeMode } from "../../../contexts/useThemeMode";

const ToggleThemeButton = () => {
  const { mode, changeThemeMode } = useThemeMode();
  return (
    <Tooltip title="Alterar tema">
      <IconButton
        onClick={() => {
          if (mode === "dark") {
            changeThemeMode("light");
          } else {
            changeThemeMode("dark");
          }
        }}
        sx={{ display: { xs: "none", md: "flex" }, mr: 2 }}
      >
        {mode === "dark" ? <LightModeOutlined /> : <DarkModeOutlined />}
      </IconButton>
    </Tooltip>
  );
};

export default ToggleThemeButton;
