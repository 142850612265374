import { LinearProgress, List, Paper, Typography } from "@mui/material";
import React, { memo, useMemo } from "react";
import { includesOnSearch } from "../../utils/more/search";
import ListSection from "./list-components/ListSection";
import { getSectionDataByKey } from "./utils";

const generalConfigs = [
  {
    name: "Moeda padrão da emprsa",
    value: "BRL",
  },
  {
    name: "Arquivo de política",
    value: "",
  },
  {
    name: "Taxa de percurso padrão",
    value: "",
  },
  {
    name: "Moedas e conversões",
    value: "",
  },
];

function ContentSearch({ results, loading, searchTerm, onBlur }) {
  const generalConfigsResult = useMemo(() => {
    return generalConfigs.filter((cfg) =>
      includesOnSearch(searchTerm, [cfg.name, cfg.value])
    );
  }, [searchTerm]);

  const noResults = useMemo(() => {
    if (results) {
      return Boolean(
        Object.keys(results).every((rKey) => !Boolean(results[rKey].length)) &&
          !generalConfigsResult.length
      );
    }
  }, [results, generalConfigsResult]);

  return (
    <Paper
      id="search_main_content"
      elevation={0}
      sx={{
        zIndex: (t) => t.zIndex.appBar + 1001,
        bgcolor: (t) => (t.palette.mode === "dark" ? "#323338" : "white"),
        borderRadius: 2,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        boxShadow: `2px 6px 5px rgb(0,0,0,.14), -2px 2px 5px rgb(0,0,0,.14)`,
        borderTop: 1,
        borderColor: (t) =>
          t.palette.mode === "dark" ? `rgba(255,255,255,.1)` : "rgba(0,0,0,.1)",
        position: "absolute",
        top: 44,
        left: 0,
        right: 0,
        overflow: "auto",
        maxHeight: 600,
        height: "auto",
      }}
    >
      <LinearProgress
        sx={{ height: 2.5, visibility: loading ? "visible" : "hidden" }}
      />
      {loading
        ? noResults && (
            <Typography m={2} variant="body2">
              Carregando...
            </Typography>
          )
        : noResults && (
            <Typography m={2} variant="body2">
              Nada encontrado
            </Typography>
          )}
      <List>
        {Object.keys(results).map((resultKey) => {
          const options = results[resultKey];
          if (Boolean(options && options.length)) {
            const data = getSectionDataByKey(resultKey);
            return (
              <ListSection
                onBlur={onBlur}
                searchTerm={searchTerm}
                sectionKey={resultKey}
                key={resultKey}
                options={options}
                {...data}
              />
            );
          }
        })}
        {Boolean(generalConfigsResult.length) && (
          <ListSection
            searchTerm={searchTerm}
            onBlur={onBlur}
            sectionKey={"general_configs"}
            options={generalConfigsResult}
            path="my-company"
            title="Configurações gerais"
          />
        )}
      </List>
    </Paper>
  );
}

export default memo(ContentSearch);
