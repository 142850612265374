import {
  AccountTreeOutlined,
  ArrowBack,
  Close,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HttpClient } from "../../../../../../../../api/http-client";
import SelectApprovalFlowList from "../../../../../../../../components/approval-flow/SelectApprovalFlowList";
import { setError } from "../../../../../../../../store/reducers/bases/errorBaseSlice";
import { openSnackbar } from "../../../../../../../../store/reducers/bases/snackbarBaseSlice";
import { updateUser } from "../../../../../../../../store/reducers/usersSlice";

const Header = memo(({ onClose, editView, isFromGroup, closeEditView }) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <DialogTitle fontSize={"1rem"} fontWeight={"500"} color={"text.secondary"}>
      {editView ? (
        <>
          <IconButton onClick={closeEditView} sx={{ m: -2, mr: 1 }}>
            <ArrowBack />
          </IconButton>
          <Typography variant="inherit">Escolha um fluxo</Typography>
        </>
      ) : (
        <>
          <AccountTreeOutlined
            fontSize="small"
            color="action"
            sx={{ mr: 1.5 }}
          />
          <Typography variant="inherit">
            Fluxo de aprovação {isFromGroup ? "- DO GRUPO" : ""}
          </Typography>
          <Box flex={1} />
          <IconButton onClick={handleClose} sx={{ m: -1 }}>
            <Close />
          </IconButton>
        </>
      )}
    </DialogTitle>
  );
});

const EditViewContent = ({
  flowId,
  onClose,
  onSave = () => {},
  userId,
  groupId,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(flowId || null);

  useEffect(() => {
    console.log(flowId);
    setValue(flowId);
  }, [flowId]);

  const handleSave = async () => {
    setLoading(true);
    try {
      await HttpClient.client().put(`/users/${userId}`, {
        apflowId: value,
      });
      setLoading(false);
      dispatch(
        updateUser({
          id: userId,
          changes: {
            apflowId: value,
          },
        })
      );
      dispatch(openSnackbar({ message: "Fluxo de aprovação salvo" }));
      onClose();
      onSave();
    } catch (error) {
      dispatch(setError({ title: "Erro ao definir fluxo", error }));
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent sx={{ pb: 5, mt: 1 }}>
        <SelectApprovalFlowList
          initialValue={value}
          onChange={setValue}
          groupId={groupId}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onSave}>Cancelar</Button>
        <Button
          onClick={handleSave}
          disabled={loading}
          disableElevation
          variant="contained"
        >
          {loading ? "Salvando" : "Salvar"}
        </Button>
      </DialogActions>
      {loading && (
        <LinearProgress
          sx={{ position: "absolute", top: 0, left: 0, width: "100%" }}
        />
      )}
    </>
  );
};

const ViewContent = ({ flowId, onEdit, isFromGroup, data = {}, groupName }) => {
  const hasFlow = data && flowId;

  const initial = { title: "", notes: "" };
  const { title, notes } = data || initial;

  return (
    <DialogContent sx={{ pb: 5 }}>
      <Box
        display={"flex"}
        alignItems={"center"}
        mt={1}
        p={2}
        borderRadius={2}
        border={1}
        borderColor={"divider"}
      >
        <Box>
          {hasFlow ? (
            <>
              <Typography
                mb={0.5}
                fontWeight={"500"}
                variant="h5"
                fontSize={"1.3rem"}
              >
                {title}
              </Typography>
              <Typography color={"text.secondary"} fontSize={".85rem"}>
                {notes || "Sem notas"}
              </Typography>
            </>
          ) : (
            <>
              <Typography color={"text.secondary"} fontSize={"1.1rem"}>
                Sem fluxo de aprovação
              </Typography>
            </>
          )}
        </Box>
        <Box flex={1} />
        <Button disableElevation variant="contained" onClick={onEdit}>
          {hasFlow ? "Editar" : "Selecionar"}
        </Button>
      </Box>
      {hasFlow ? (
        <>
          {isFromGroup ? (
            <Typography
              mt={2}
              display={"flex"}
              alignItems={"flex-start"}
              fontSize={".9rem"}
            >
              <InfoOutlined
                color="actiobe"
                sx={{ mr: 2, mt: 0.5, fontSize: "1rem" }}
              />
              <Typography component={"span"} variant="inherit">
                O fluxo de aprovação selecionado foi configurado e definido pelo
                grupo do colaborador: <strong>{groupName}</strong>
              </Typography>
            </Typography>
          ) : (
            <Typography
              mt={2}
              display={"flex"}
              alignItems={"flex-start"}
              fontSize={".9rem"}
            >
              <InfoOutlined
                color="actiobe"
                sx={{ mr: 2, mt: 0.5, fontSize: "1rem" }}
              />
              <Typography component={"span"} variant="inherit">
                O fluxo de aprovação foi configurado especificamente para este
                colaborador
              </Typography>
            </Typography>
          )}
        </>
      ) : (
        <Typography
          mt={2}
          display={"flex"}
          alignItems={"flex-start"}
          fontSize={".9rem"}
        >
          <InfoOutlined
            color="actiobe"
            sx={{ mr: 2, mt: 0.5, fontSize: "1rem" }}
          />
          Colaboradores sem fluxo de aprovação poderão ser aprovados por
          qualquer aprovador do sistema
        </Typography>
      )}
    </DialogContent>
  );
};

function ModalView({
  open,
  onClose,
  apflowId,
  userId,
  isFromGroup,
  data = {},
  groupName,
  groupId,
}) {
  const [editView, setEditView] = useState(false);

  const handleCloseEditView = useCallback(() => {
    setEditView(false);
  }, [editView]);

  const handleOpenEditView = useCallback(() => {
    setEditView(true);
  }, [editView]);

  useEffect(() => {
    if (!open) {
      setEditView(false);
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      PaperProps={{ sx: { maxWidth: 520, position: "relative" } }}
      open={open}
      transitionDuration={100}
      onClose={() => !editView && onClose()}
    >
      <Header
        isFromGroup={isFromGroup}
        onClose={onClose}
        editView={editView}
        closeEditView={handleCloseEditView}
      />
      {editView ? (
        <EditViewContent
          flowId={isFromGroup ? null : apflowId}
          onClose={handleCloseEditView}
          onSave={onClose}
          userId={userId}
          groupId={groupId}
        />
      ) : (
        <ViewContent
          flowId={apflowId}
          onEdit={handleOpenEditView}
          isFromGroup={isFromGroup}
          data={data}
          groupName={groupName}
        />
      )}
    </Dialog>
  );
}

export default ModalView;
