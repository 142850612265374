import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAccountRoles } from "../../store/reducers/accountSlice";
import NoAccessContent from "./NoAccessContent";

const CheckConfigAccessWrapper = ({
  children,
  settingKey = "",
  showNoAccessComponent = false,
}) => {
  const roles = useSelector(selectAccountRoles);

  const permited = useMemo(() => {
    if (roles.master || !settingKey) {
      return true;
    }
    const keys = settingKey.split(".");
    const totalKeys = keys.length;

    if (totalKeys === 1) {
      return Boolean(roles[keys[0]]);
    }
    if (totalKeys === 2) {
      return Boolean(roles[keys[0]][keys[1]]);
    }
    if (totalKeys === 3) {
      return Boolean(roles[keys[0]][keys[1]][keys[2]]);
    }
    return false;
  }, [roles, settingKey]);

  return permited ? (
    children
  ) : showNoAccessComponent ? (
    <NoAccessContent showUserInfo={false} staticMode />
  ) : (
    <></>
  );
};

export default memo(CheckConfigAccessWrapper);
