import { Box } from "@mui/material";
import React from "react";

const RouterContent = ({ children }) => {
  return (
    <Box
      flex={1}
      flexBasis={0}
      display={"flex"}
      flexDirection={"column"}
      boxShadow={"none"}
      color="text.primary"
      borderRadius={5}
      overflow={"auto"}
      bgcolor={"backgroundPrimary.main"}
      sx={{
        overflowX: { xs: "auto", xl: "hidden" },
        transition: ".2s ease",
        zIndex: 10,
      }}
      id="box_content"
      width={"100%"}
      position={"relative"}
    >
      {children}
    </Box>
  );
};

export default RouterContent;
