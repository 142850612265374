import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";

const baseName = "orgs";

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  status: "idle",
  error: null,
});

//chamada c/ api rest;
export const fetchOrgs = createAsyncThunk(`${baseName}/fetchOrgs`, async () => {
  const res = await HttpClient.client().get(`/organizations`);
  return res.data;
});

export const orgsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    addOrg(state, action) {
      adapter.addOne(state, action.payload);
    },
    updateOrg(state, action) {
      const {id, changes} = action.payload
      adapter.updateOne(state, {changes, id});
    },
    removeOrg(state, action) {
      adapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrgs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOrgs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchOrgs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        const data = action.payload;
        state.ids = data.ids;
        let obj = {};
        const arrayObj = Object.keys(data.entities);
        for (let index = 0; index < arrayObj.length; index++) {
          const id = arrayObj[index];
          obj[id] = {
            ...data.entities[id],
            searchType: baseName,
          };
        }
        state.entities = obj;
      });
  },
});

export const { addOrg, updateOrg, removeOrg } = orgsSlice.actions;

export const {
  selectAll: selectAllOrgs,
  selectIds: selectOrgsIds,
  selectById: selectOrgById,
  selectEntities: selectOrgsEntities,
  selectTotal: selectTotalOrgs,
} = adapter.getSelectors((state) => state[baseName]);

export default orgsSlice.reducer;
