import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./reducers";

//persist
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { rootMigrations } from "./migrations";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "expenseRequests",
    "allExpenses",
    "users",
    "account",
    "sidebarContent",
    "settings",
  ],
  blacklist: ["expenseRequests", "allExpenses"],
  version: 1,
  migrate: createMigrate(rootMigrations, {
    debug:
      process.env.REACT_APP_DEBUG === "true" ||
      process.env.REACT_APP_ENV === "development",
  }),
};

const appReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
