export const getBlob = (file) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    let binary = "";
    reader.onload = () => {
      binary = reader.result;
      res(binary);
    };
    reader.onerror = () => {
      rej("file reading has failed");
    };
    reader.readAsArrayBuffer(file);
  });
};
export const formatFileType = (type) => {
  switch (type) {
    case "image/jpeg":
      return "Jpeg";
    case "image/png":
      return "Png";
    case "application/pdf":
      return "Pdf";
    default:
      return type;
  }
};

export async function blobToBase64(blobUrl) {
  const response = await fetch(blobUrl);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64String = reader.result;
      const formated = base64String.split(',')[1]
      resolve(formated);
    };
    reader.onerror = reject;
  });
}
