import { AccessTimeOutlined } from "@mui/icons-material";
import { Chip, Tooltip } from "@mui/material";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectUserById } from "../../store/reducers/usersSlice";
import { formatDate } from "../../utils/more/date_functions";

const StatusLabel = ({ sx, userId }) => {
  const user = useSelector((state) => selectUserById(state, userId));

  const { status, valid_to } = user || {
    status: "",
  };

  const isValid = useMemo(() => {
    if (!valid_to) return false;
    return new Date().getTime() < new Date(valid_to).getTime();
  }, [valid_to]);

  const formatedStatus = useMemo(() => {
    if (isValid) return status;
    return "expired_access";
  }, [isValid, status]);

  return (
    <Tooltip
      disableHoverListener={formatedStatus !== "expired_access" || !valid_to}
      disableFocusListener={formatedStatus !== "expired_access" || !valid_to}
      title={`Expirou em ${formatDate(valid_to)}`}
    >
      <span>
        <Chip
          icon={
            formatedStatus === "expired_access" ? (
              <AccessTimeOutlined />
            ) : undefined
          }
          size="small"
          label={
            formatedStatus === "active"
              ? "Ativo"
              : formatedStatus === "inviting"
              ? "Convidado"
              : formatedStatus === "inactive"
              ? "Desativado"
              : formatedStatus === "expired_access"
              ? "Acesso expirado"
              : formatedStatus
          }
          color={
            formatedStatus === "active"
              ? "primary"
              : formatedStatus === "inviting" ||
                formatedStatus === "expired_access"
              ? "warning"
              : "default"
          }
          variant="outlined"
          sx={{
            border: "none",
            fontWeight: "600",
            bgcolor: (t) =>
              formatedStatus === "inactive"
                ? "action.hover"
                : `${
                    t.palette[
                      formatedStatus === "active"
                        ? "primary"
                        : formatedStatus === "inviting" ||
                          formatedStatus === "expired_access"
                        ? "warning"
                        : ""
                    ]?.main
                  }15`,
            ...sx,
          }}
        />
      </span>
    </Tooltip>
  );
};

export default memo(StatusLabel);
