import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HttpClient } from "../../../../../api/http-client";
import PermissionsList from "../../../../../components/USERS/PermissionsList";
import { setError } from "../../../../../store/reducers/bases/errorBaseSlice";
import { openSnackbar } from "../../../../../store/reducers/bases/snackbarBaseSlice";
import { updateUser } from "../../../../../store/reducers/usersSlice";
import { getProfilePicture } from "../../../../../utils/functions/profile";

const ModalEditPermissions = ({
  initialRoles,
  userId,
  open,
  onClose,
  name = "",
}) => {
  const dispatch = useDispatch();

  const [content, setContent] = useState(undefined);

  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    target: content,
    threshold: 0,
  });

  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState({
    master: initialRoles?.master,
    approver: initialRoles?.approver,
    admin: {
      active: Boolean(initialRoles?.admin),
      settings: initialRoles?.admin?.settings,
      analysis: initialRoles?.admin?.analysis,
      panel_sap: initialRoles?.admin?.panel_sap,
      panel_control: initialRoles?.admin?.panel_control,
      users_control: initialRoles?.admin?.users_control,
    },
  });

  useEffect(() => {
    setRoles({
      master: initialRoles?.master,
      approver: initialRoles?.approver,
      admin: {
        active: Boolean(initialRoles?.admin),
        settings: initialRoles?.admin?.settings,
        analysis: initialRoles?.admin?.analysis,
        panel_sap: initialRoles?.admin?.panel_sap,
        panel_control: initialRoles?.admin?.panel_control,
        users_control: initialRoles?.admin?.users_control,
      },
    });
  }, [open]);

  const handleChangePermissions = useCallback((path = "", value) => {
    const settings = path.split(".");
    setRoles((prev) => {
      if (!path.includes(".") && !Boolean(settings.length)) {
        prev[path] = value;
        return { ...prev };
      } else if (settings.length === 1) {
        prev[settings[0]] = value;
        return { ...prev };
      } else if (settings.length === 2) {
        prev[settings[0]][settings[1]] = value;
        return { ...prev };
      } else if (settings.length === 3) {
        prev[settings[0]][settings[1]][settings[2]] = value;
        return { ...prev };
      } else if (settings.length === 4) {
        prev[settings[0]][settings[1]][settings[2]][settings[3]] = value;
        return { ...prev };
      }
    });
  }, []);

  const handleSave = async () => {
    setLoading(true);

    const formatedRoles = {
      master: roles.master,
      admin: roles.admin.active
        ? {
            ...roles.admin,
          }
        : false,
      approver: roles.approver,
    };

    try {
      await HttpClient.client().put(`/users/${userId}`, {
        roles: formatedRoles,
      });
      dispatch(openSnackbar({ message: "Permissões de acesso atualizadas" }));
      dispatch(
        updateUser({
          id: userId,
          changes: {
            roles: formatedRoles,
          },
        })
      );
      onClose();
    } catch (error) {
      dispatch(
        setError({ title: "Erro ao atualizar permissões de acesso", error })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          height: "100%",
        },
      }}
    >
      {loading && <LinearProgress />}
      <DialogTitle boxShadow={scrolled && 3}>
        <Avatar
          src={getProfilePicture(userId)}
          sx={{ width: 40, height: 40, mr: 2 }}
        >
          {name[0]?.toUpperCase()}
        </Avatar>
        <Box>
          <Typography
            variant="body2"
            color={"text.secondary"}
            fontWeight={"500"}
          >
            {name}
          </Typography>
          <Typography fontSize={"1.1rem"} variant="inherit">
            Permissões de acesso
          </Typography>
        </Box>
        <Box flex={1} />
        <Button size="large" sx={{ mr: 1 }} onClick={onClose}>
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          disabled={loading}
          size="large"
          variant="contained"
          disableElevation
        >
          {loading ? "Salvando" : "Salvar"}
        </Button>
      </DialogTitle>
      <Divider />
      <DialogContent
        ref={(node) => node && setContent(node)}
        sx={{ px: 2, pb: 10, overflow: "scroll" }}
      >
        <PermissionsList
          permissions={roles}
          onChangePermissions={handleChangePermissions}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ModalEditPermissions;
