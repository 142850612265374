import { Backdrop, Button, CircularProgress, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AuthAPIGateway } from "../../api/auth";
import { AUTH_BASE_URL, EXPENSES_BASE_URL } from "../../api/urls";
import ModalErrorBase from "../../modals/error/ModalErrorBase";
import { setError } from "../../store/reducers/bases/errorBaseSlice";
import { formatDate } from "../../utils/more/date_functions";
import NoAccessContent from "./NoAccessContent";

const LogoutButton = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleExit = async () => {
    setLoading(true);
    try {
      await AuthAPIGateway.logout();
      window.location.replace(
        `${AUTH_BASE_URL}?step=0&email=${window.localStorage.getItem(
          "email"
        )}&redirect=${window.location.origin}`
      );
    } catch (error) {
      setLoading(false);
      dispatch(setError({ title: "Erro ao sair da conta", error }));
    }
  };
  return (
    <>
      <Button onClick={handleExit} variant="outlined">
        Entrar com outra conta
      </Button>
      <Backdrop open={loading}>
        <CircularProgress sx={{ color: "white" }} />
      </Backdrop>
    </>
  );
};

const errors = {
  F3: "Lamentamos informar que o acesso à sua conta foi revogado.",
  F4: "Sua conta está com o acesso expirado",
  F5: "Você não tem permissão para acessar o painel de administrador da conta",
};

const NoAdminAccess = ({ error }) => {
  const errorCode = error?.code;

  return (
    <>
      <NoAccessContent showUserInfo title={errors[errorCode]}>
        <Divider sx={{width: 400, mb: 2}}/>
        {errorCode === "F4" && (
          <Typography
            fontWeight={"600"}
            color={"text.secondary"}
            mb={2}
            variant="body2"
          >
            Acesso expirado em {formatDate(error?.valid_to)}
          </Typography>
        )}
        {errorCode === "F5" && (
          <Button
            sx={{ mb: 2 }}
            onClick={() => {
              window.location.replace(EXPENSES_BASE_URL);
            }}
            variant="contained"
          >
            Acessar gestão de despesas
          </Button>
        )}
        <LogoutButton />
      </NoAccessContent>
      <ModalErrorBase />
    </>
  );
};

export default NoAdminAccess;
