import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { connect } from "react-redux";
import { selectUserById } from "../../../../store/reducers/usersSlice";

function EmailLabel({ email, userId }) {
  return (
    <Box mb={0.3} display={"flex"} justifyContent="flex-start">
      <Typography
        color={"text.secondary"}
        display="flex"
        fontSize={"1rem"}
        alignItems={"center"}
      >
        {email}
      </Typography>
    </Box>
  );
}
function mapStateToProps(state, ownProps) {
  const { email } = selectUserById(state, ownProps.userId) || { email: "" };
  return {
    email,
    ...ownProps,
  };
}

export default connect(mapStateToProps)(memo(EmailLabel));
