import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import React, { memo, useEffect } from "react";
import { Link } from "react-router-dom";

const BaseItem = ({
  disableHiddenLabel,
  width,
  selected,
  title,
  content,
  Icon,
  onClick,
  subtitle,
  disabled,
}) => {
  return (
    <ListItemButton
      disabled={disabled}
      onClick={onClick}
      sx={{
        pl: !disableHiddenLabel && 3.2,
        height: 48,
        width,
        bgcolor: (t) => selected && `${t.palette.primary.main}15`,
        transition: "none",
        overflow: "hidden",
        position: "relative",
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
        ":hover": {
          bgcolor: (t) =>
            selected
              ? `${t.palette.primary.main}25`
              : `${t.palette.primary.main}15`,
        },
      }}
    >
      {Icon && (
        <ListItemIcon>
          <Icon
            color={selected ? "primary" : "text.primary"}
            sx={{ fontSize: "23px", opacity: selected ? 1 : 0.8 }}
          />
        </ListItemIcon>
      )}
      <ListItemText
        primaryTypographyProps={{
          variant: "body1",
          fontSize: ".899rem",
          fontWeight: "600",
          noWrap: true,
          color: selected ? "primary" : "text.primary",
          ml: disableHiddenLabel ? -3 : -2,
        }}
        secondaryTypographyProps={{
          fontSize: ".7rem",
          ml: disableHiddenLabel ? -3 : -2,
        }}
        primary={title}
        secondary={subtitle}
      />
      {content}
    </ListItemButton>
  );
};

function SidebarItem({
  path,
  title,
  subtitle,
  disableLink,
  Icon,
  width = "100%",
  disableHiddenLabel,
  onClick,
  content,
  disabled,
  isSelected,
}) {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  const selected = isSelected;

  useEffect(() => {
    if (selected) {
      setTimeout(() => {
        const el = document.getElementById(`sidebar_item_${path}`);
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 100);
    }
  }, []);

  if (disableLink) {
    return (
      <BaseItem
        onClick={onClick}
        Icon={Icon}
        content={content}
        disableHiddenLabel={disableHiddenLabel}
        selected={selected}
        title={title}
        width={width}
        subtitle={subtitle}
        disabled={disabled}
      />
    );
  }

  return (
    <Link
      id={`sidebar_item_${path}`}
      style={{
        textDecoration: "none",
        outline: "none",
        cursor: disabled ? "default" : "pointer",
        color: `${main}45`,
      }}
      to={disabled ? "#" : path}
    >
      <BaseItem
        disabled={disabled}
        Icon={Icon}
        content={content}
        disableHiddenLabel={disableHiddenLabel}
        selected={selected}
        title={title}
        width={width}
        subtitle={subtitle}
        onClick={onClick}
      />
    </Link>
  );
}

export default memo(SidebarItem);
