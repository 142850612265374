import {
  AccountTreeOutlined,
  CategoryOutlined,
  DashboardCustomizeOutlined,
  PaymentsOutlined,
  PersonPinCircleOutlined,
  RuleOutlined,
  SpeakerNotesOutlined,
  StickyNote2Outlined,
} from "@mui/icons-material";

export const organizationsConfig = [
  {
    title: "Categorias de despesa",
    path: "expense-types",
    icon: CategoryOutlined,
  },
  {
    title: "Regras",
    path: "rules",
    icon: RuleOutlined,
  },
  {
    title: "Percurso",
    path: "route-policies",
    icon: PersonPinCircleOutlined,
  },
  {
    title: "Fluxos de aprovação",
    path: "approval-flows",
    icon: AccountTreeOutlined,
  },
  {
    title: "Campos personalizados",
    path: "custom-fields",
    icon: DashboardCustomizeOutlined,
  },
  {
    title: "Motivos de rejeição",
    path: "rejection-reasons",
    icon: SpeakerNotesOutlined,
  },
  {
    title: "Projetos",
    path: "projects",
    icon: StickyNote2Outlined,
  },
  {
    title: "Formas de pagamento",
    path: "payment-methods",
    icon: PaymentsOutlined,
  },
];
