import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";

const baseName = "sap";

const initialState = {
  active: false,
  status: "idle",
  error: null,
  settings: {
    auto: false, // integração ocorre automática conforme o rel. é aprovado.
    conn_id: null, // Conexão ativa
    connections: {},
    users: {},
    accounting: {},
    check_interval_min: 30,
    check_job: {},
    check_paid_docs: false,
    int_job: {},
    interval_min: 10,
  },
};

export const sapInitialState = initialState;

export const fetchSapSystem = createAsyncThunk(
  `sap/fetchSapSystem`,
  async (isBackground) => {
    const res = await HttpClient.client().get(`/sapconfig/systems`);
    return res.data;
  }
);

export const sapSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    updateSapUser(state, { payload }) {
      const { id, changes } = payload;
      state.settings.users[id] = {
        ...state.settings.users[id],
        ...changes,
      };
    },
    updateManySapSettings(state, { payload }) {
      state.settings = {
        ...state.settings,
        ...payload,
      };
    },
    updateSapSetting(state, { payload }) {
      const { setting, subSetting, value } = payload;
      if (subSetting) {
        state.settings[setting][subSetting] = value;
      } else {
        state.settings[setting] = value;
      }
    },
    updateSapConnection(state, { payload }) {
      const { id, changes } = payload;
      state.settings.connections[id] = {
        ...state.settings.connections[id],
        ...changes,
      };
    },
    removeSapConnection(state, { payload }) {
      delete state.settings.connections[payload];
    },
    addSapConnection(state, { payload }) {
      state.settings.connections[payload.id] = { ...payload };
    },
    //

    setSapIntegrationActivation(state, { payload }) {
      state.active = payload;
    },
    setActiveSAPConnection(state, { payload }) {
      state.settings.active_con_id = payload;
    },
    updateSystemConnection(state, { payload }) {
      const { connectionId, changes } = payload;
      state.settings.connections[connectionId] = {
        ...state.settings.connections[connectionId],
        ...changes,
      };
    },
    addSystemConnection(state, { payload }) {
      const { connectionId, data } = payload;
      state.settings.connections[connectionId] = data;
    },
    removeSystemConnection(state, { payload }) {
      const { connectionId } = payload;
      delete state.settings.connections[connectionId];
    },
    saveUserSAPSettings(state, { payload }) {
      const { userId, company, branch, creditor, cost_center, cc_type } =
        payload;
      state.settings.users[userId] = {
        company,
        branch,
        cost_center,
        creditor,
        cc_type,
      };
    },
    updateSAPStatus(state, { payload }) {
      state.settings.status = payload;
    },

    //accounting
    upsertAccountingData(state, { payload }) {
      state.settings.accounting = {
        ...state.settings.accounting,
        ...payload,
      };
    },
    removeCostCenters(state, { payload }) {
      if (!payload) return;
      for (let index = 0; index < payload.length; index++) {
        const costCenter = payload[index];
        delete state.settings.accounting[costCenter];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      //status sap integration configs..
      .addCase(fetchSapSystem.pending, (state, action) => {
        const isBackground = action.meta.arg;
        if (!isBackground) {
          state.status = "loading";
        }
      })
      .addCase(fetchSapSystem.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.active = false;
      })
      .addCase(fetchSapSystem.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.active = Boolean(action.payload?.status === "active");
        state.settings = action.payload;
      });
  },
});

export const {
  setSapIntegrationActivation,
  updateSystemConnection,
  addSystemConnection,
  removeSystemConnection,
  saveUserSAPSettings,
  setActiveSAPConnection,
  updateSAPStatus,

  //
  updateSapUser,
  addSapConnection,
  removeSapConnection,
  updateSapConnection,
  updateSapSetting,
  updateManySapSettings,
  upsertAccountingData,
  removeCostCenters,
} = sapSlice.actions;

export default sapSlice.reducer;

export const selectSapIsIntegrated = (state) => state.sap.active;
export const selectSapSettings = (state) => state.sap.settings;

export const selectDevConnection = (state) => state.sap.settings.conn_id_test;
export const selectProdConnection = (state) => state.sap.settings.conn_id_prod;

export const selectUserSAPSettings = createSelector(
  [(state) => state.sap.settings.users, (state, userId) => userId],
  (settings, userId) => {
    return settings[userId];
  }
);

export const selectSAPAccounts = (state) => state.sap.settings.accounting || {};

export const selectSAPAccountById = createSelector(
  [(state) => state.sap.settings.accounting, (state, id) => id],
  (accounts, id) => {
    return accounts[id];
  }
);

export const selectSAPUsers = (state) => state.sap.settings.users;

export const selectSAPUserById = createSelector(
  [selectSAPUsers, (state, userId) => userId],
  (settings, userId) => {
    return settings[userId];
  }
);

export const selectSAPConnectionsIds = createSelector(
  [(state) => state.sap.settings.connections],
  (connections) => {
    return [...Object.keys(connections)];
  }
);

export const selectSAPConnectionById = createSelector(
  [
    (state) => state.sap.settings.connections,
    (state, connectionId) => connectionId,
  ],
  (connections, id) => {
    return connections[id];
  }
);

export const selectActiveSAPConnectionId = createSelector(
  [(state) => state.sap.settings.active_con_id],
  (id) => id
);
