import { Paper } from "@mui/material";
import React from "react";

function SectionPaper({ children, sx }) {
  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
        boxShadow: 0,
        borderRadius: 5,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        overflow: "hidden",
        ...sx,
      }}
    >
      {children}
    </Paper>
  );
}

export default SectionPaper;
