import { createTheme, Grow, Paper } from "@mui/material";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});
const DialogPaperComponent = React.forwardRef(function Transition(props, ref) {
  return (
    <Paper {...props} elevation={2} sx={{ boxShadow: 5, borderRadius: 2 }} />
  );
});

export const isStaging =
  process.env.REACT_APP_ENV === "staging" ||
  process.env.REACT_APP_ENV === "development";

const primaryColor = {
  light: isStaging ? "#262626" : "#047BC4",
  dark: "#9ACBFF",
};

const elevation1 = {
  light: isStaging ? "#F5F5F5" : "#F7FAFD",
  dark: "#202124",
};

const theme = (mode) =>
  createTheme({
    typography: {
      fontFamily: "'Figtree', sans-serif",
      allVariants: {
        letterSpacing: -0.1,
        fontVariantLigatures: "no-contextual",
        WebkitFontSmoothing: "antialiased",
        textRendering: "optimizeLegibility",
      },
      button: {
        fontWeight: "600",
        textTransform: "none",
      },
    },
    components: {
      MuiDialogActions: {
        styleOverrides: {
          root: {
            height: 60,
            padding: 23,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: ".9rem",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? "#27282C" : "#FFF",
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "100ms",
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "100ms",
            },
          },
        },
        defaultProps: {
          TabIndicatorProps: {
            sx: {
              height: 3,
              borderTopLeftRadius: 100,
              borderTopRightRadius: 100,
            },
          },
        },
      },
      MuiListItemText: {
        defaultProps: {
          primaryTypographyProps: {
            sx: { fontSize: "1rem", fontWeight: "600" },
          },
          secondaryTypographyProps: {
            sx: { fontSize: ".9rem" },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "100ms",
            },
            paddingInline: "18px",
          },
          containedSizeLarge: {
            paddingInline: "30px",
          },
          outlinedPrimary: {
            borderColor:
              mode === "dark" ? "rgb(255,255,255,.2)" : "rgb(0,0,0,.2)",
            ":hover": {
              borderColor:
                mode === "dark" ? "rgb(255,255,255,.1)" : "rgb(0,0,0,.3)",
            },
          },
          outlinedInherit: {
            borderColor:
              mode === "dark" ? "rgb(255,255,255,.2)" : "rgb(0,0,0,.2)",
            ":hover": {
              borderColor:
                mode === "dark" ? "rgb(255,255,255,.1)" : "rgb(0,0,0,.3)",
            },
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          "data-disableselect": true,
          onDragStart: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
          TouchRippleProps: {
            "data-disableselect": true,
            onDragStart: (e) => {
              e.stopPropagation();
              e.preventDefault();
            },
          },
        },
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "100ms",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: ".925rem",
            fontWeight: "500",
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "100ms",
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "100ms",
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "100ms",
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "100ms",
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "100ms",
            },
            transition: "none",
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "100ms",
            },
          },
        },
      },
      MuiCardActionArea: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "300ms",
            },
          },
        },
      },
      MuiTooltip: {
        defaultProps: {
          componentsProps: {
            popper: {
              "data-disableselect": true,
              sx: {
                boxShadow: 0,
              },
            },
          },
        },
        styleOverrides: {
          arrow: {
            color: "#474747",
          },
          tooltip: {
            borderRadius: 4,
            backgroundColor: "#474747",
            fontSize: ".75rem",
            fontWeight: "600",
          },
        },
      },
      MuiLink: {
        defaultProps: {
          sx: {
            cursor: "pointer",
          },
        },
      },
      MuiMenu: {
        defaultProps: {
          "data-disableselect": true,
          PaperProps: {
            "data-disableselect": true,
          },
        },
        styleOverrides: {
          paper: {
            borderRadius: 4,
          },
        },
      },
      MuiDialog: {
        defaultProps: {
          "data-disableselect": true,
          PaperComponent: DialogPaperComponent,
          TransitionComponent: Transition,
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? "#272932" : "#323232",
            color: "#fff",
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? "#3C3F44" : "#F8F9FA",
            "&:hover": {
              backgroundColor: mode === "dark" ? "#3C3F44" : "#EFF1F3",
            },
            "&.Mui-focused": {
              backgroundColor: mode === "dark" ? "#3C3F44" : "#F8F9FA",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            fontSize: ".92rem",
            fontWeight: "500",
          },
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          noOptionsText: "Nada encontrado",
          loadingText: "Carregando...",
        },
      },
      MuiDialogTitle: {
        defaultProps: {
          component: "div",
          display: "flex",
          alignItems: "center",
        },
        styleOverrides: {
          root: {
            display: "flex",
            alignItems: "center",
            zIndex: 10,
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          "data-disableselect": true,
          onDragStart: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
          TouchRippleProps: {
            "data-disableselect": true,
            onDragStart: (e) => {
              e.stopPropagation();
              e.preventDefault();
            },
          },
        },
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "100ms",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: mode === "dark" ? "#FFF" : "#272727",
            fontWeight: "500",
            fontSize: ".95rem",
          },
          shrink: {
            fontWeight: "500",
            opacity: 0.8,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontWeight: "600",
          },
        },
      },
    },
    palette: {
      primary: {
        main: primaryColor[mode],
      },
      background: {
        default: mode === "dark" ? "#202124" : "#FFF",
        paper: mode === "dark" ? "#202124" : "#FFF",
      },
      backgroundPrimary: {
        main: mode === "dark" ? "#27282C" : "#FFF",
      },
      elevation1: {
        main: elevation1[mode],
      },
      secondary: {
        main: "#3B428C",
      },
      error: {
        main: mode === "dark" ? "#FFB4AB" : "#f24b4b",
      },
      text: {
        main: mode === "dark" ? "#FFFFFF" : "#4B4B4B",
      },
      capition: {
        main: "#B1AEAE",
      },
      cleanWhite: {
        main: mode === "dark" ? "#32333C" : "#FFFFFF",
      },
      warning: {
        main: mode === "dark" ? "#F4932C" : "#ED6C03",
      },
      action: {
        active: mode === "dark" ? "#FFF" : "#5F6367",
      },
      mode,
    },
  });

export default theme;
