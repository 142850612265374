import { ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import ExitButton from "./components/ExitButton";
import SectionPaper from "./components/SectionPaper";

export const ButtonItem = ({
  Icon,
  children,
  divider = true,
  color,
  onClick,
  sx,
}) => {
  return (
    <ListItemButton
      onClick={onClick}
      sx={{
        transition: "none",
        ":hover": {
          bgcolor: (t) => `${t.palette[color || "primary"].main}25`,
        },
        ...sx,
      }}
    >
      <Icon sx={{ mr: 3 }} color={color || "action"} />
      <ListItemText
        primaryTypographyProps={{
          fontWeight: "500",
          fontSize: ".9rem",
          color,
        }}
        primary={children}
      />
    </ListItemButton>
  );
};

function FooterActions(props) {
  return (
    <>
      {/* <SectionPaper sx={{ p: 0 }}>
        <ButtonItem Icon={KeyOutlined}>Alterar senha</ButtonItem>
      </SectionPaper> */}
      <SectionPaper
        sx={{
          p: 0,
          mt: 0.5,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
        }}
      >
        <ExitButton />
      </SectionPaper>
    </>
  );
}

export default FooterActions;
