import { combineReducers } from "@reduxjs/toolkit";
import accountSlice from "./accountSlice";

//persist
import { createMigrate, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { expenseRequestsMigrations } from "../migrations";
import advancedSettingsSlice from "./advancedSettingsSlice";
import allExpensesSlice from "./allExpensesSlice";
import approvalFlowsSlice from "./approvalFlowsSlice";
import errorBaseSlice from "./bases/errorBaseSlice";
import settingsSlice from "./bases/settingsSlice";
import sidebarContentSlice from "./bases/sidebarContentSlice";
import snackbarBaseSlice from "./bases/snackbarBaseSlice";
import billingSlice from "./billingSlice";
import customFieldsSlice from "./customFieldsSlice";
import expensePoliciesSlice from "./expensePoliciesSlice";
import expenseRequestsSlice from "./expenseRequestsSlice";
import expenseTypesSlice from "./expenseTypesSlice";
import groupsSlice from "./groupsSlice";
import notificationsSlice from "./notificationsSlice";
import occupationsSlice from "./occupationsSlice";
import orgsSlice from "./orgsSlice";
import paymentTypesSlice from "./paymentTypesSlice";
import portalSlice from "./portalSlice";
import projectsSlice from "./projectsSlice";
import rejectionReasonsSlice from "./rejectionReasonsSlice";
import requestsLimitsSlice from "./requestsLimitsSlice";
import routeRatesSlice from "./routeRatesSlice";
import sapIntegrationSlice from "./sapIntegrationSlice";
import savedViewsSlice from "./savedViewsSlice";
import searchSlice from "./searchSlice";
import snippetsSlice from "./snippetsSlice";
import usersSlice from "./usersSlice";

const expenseRequestsPersist = {
  key: "expenseRequests",
  storage,
  blacklist: ["ids", "entities", "status", "working"],
  version: 5,
  migrate: createMigrate(expenseRequestsMigrations, {
    debug:
      process.env.REACT_APP_DEBUG === "true" ||
      process.env.REACT_APP_ENV === "development",
  }),
};

const allExpensesPersist = {
  key: "allExpenses",
  storage,
  blacklist: ["ids", "entities", "status", "working"],
};

export const rootReducer = combineReducers({
  //bases
  errorBase: errorBaseSlice,
  snackbarBase: snackbarBaseSlice,
  sidebarContent: sidebarContentSlice,
  search: searchSlice,
  portal: portalSlice,
  account: accountSlice,
  users: usersSlice,
  billing: billingSlice,
  expenseTypes: expenseTypesSlice,
  rejectionReasons: rejectionReasonsSlice,
  projects: projectsSlice,
  expensePolicies: expensePoliciesSlice,
  routeRates: routeRatesSlice,
  approvalFlows: approvalFlowsSlice,
  orgs: orgsSlice,
  snippets: snippetsSlice,
  occupations: occupationsSlice,
  paymentTypes: paymentTypesSlice,
  savedViews: savedViewsSlice,
  advancedSettings: advancedSettingsSlice,
  settings: settingsSlice,
  expenseRequests: persistReducer(expenseRequestsPersist, expenseRequestsSlice),
  allExpenses: persistReducer(allExpensesPersist, allExpensesSlice),
  sap: sapIntegrationSlice,
  notifications: notificationsSlice,

  //new reducers 26.04.2024
  requestsLimits: requestsLimitsSlice,
  customFields: customFieldsSlice,

  //new reducers 17.05.2024
  groups: groupsSlice,
});
