import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
} from "date-fns";
import { HttpClient } from "../../api/http-client";
import normalize from "../../utils/normalize";

const baseName = "allExpenses";

const adapter = createEntityAdapter({
  selectId: (entity) => entity.id,
});

const initialState = adapter.getInitialState({
  working: false,
  status: "idle",
  filters: {
    date: {
      type: "this-month",
      meta: {},
    },
  },
  activeSavedView: null,
});

export const fetchAllExpenses = createAsyncThunk(
  `${baseName}/fetchAllExpenses`,
  async ({ filters, skip = undefined }) => {
    const { type, meta } = filters?.date || {
      type: "this-month",
      meta: {},
    };

    const today = startOfDay(new Date());
    const yesterday = subDays(today, 1);

    const from_date =
      type === "today"
        ? today
        : type === "yesterday"
        ? startOfDay(yesterday)
        : type === "this-month"
        ? startOfMonth(today)
        : type === "this-year"
        ? startOfYear(today)
        : type === "last-month"
        ? startOfMonth(subMonths(today, 1))
        : type === "last-90-days"
        ? subDays(today, 90)
        : type === "custom"
        ? startOfDay(new Date(meta?.from))
        : undefined;

    const to_date =
      type === "today"
        ? endOfDay(today)
        : type === "yesterday"
        ? endOfDay(yesterday)
        : type === "this-month"
        ? endOfMonth(today)
        : type === "this-year"
        ? endOfYear(today)
        : type === "last-month"
        ? endOfMonth(subMonths(today, 1))
        : type === "last-90-days"
        ? today
        : type === "custom"
        ? endOfDay(new Date(meta?.to))
        : undefined;

    const res = await HttpClient.client().post(
      "/controlling/expenses",
      {
        users: filters?.users,
        to_date,
        from_date,
        to_value: (filters?.amount && filters?.amount[1]) || undefined,
        from_value: (filters?.amount && filters?.amount[0]) || undefined,
        status: filters?.status ? filters?.status : undefined,
        sap: filters?.sap || undefined,
        refundable: filters?.refundable ? 1 : undefined,
        is_route: filters?.is_route ? true : undefined,
        types: filters?.expTypes || undefined,
        projects: filters?.projects || undefined,
        payment_types: filters?.payments || undefined,
        groups: filters?.groups || undefined,
        orgs: filters?.orgs || undefined,
        occupations: filters?.occupations || undefined,
      },
      {
        params: {
          text: filters?.search,
        },
      }
    );
    return res.data;
  }
);

export const fetchExpenseDetails = createAsyncThunk(
  `${baseName}/fetchExpenseDetails`,
  async (expenseId) => {
    const res = await HttpClient.client().get(`/expenses/${expenseId}`);
    return res.data;
  }
);

export const allExpensesSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setTableWorking(state, { payload }) {
      state.working = Boolean(payload);
    },
    resetExpensesTable(state) {
      state.filters = initialState.filters;
      state.activeSavedView = null;
      state.reset = false;
    },

    onChangeExpensesTableFilters(state, { payload }) {
      const { filter, value } = payload;
      state.filters[filter] = value;
    },
    removeExpensesTableFilter(state, { payload }) {
      delete state.filters[payload];
    },
    setAllExpensesFilters(state, { payload }) {
      if (!payload) return;
      state.filters = payload;
    },
    clearExpensesTableFilter(state) {
      state.filters = {
        ...initialState.filters,
        date: state.filters.date,
      };
    },
    //table views
    setTableActiveView(state, { payload }) {
      state.activeSavedView = payload;
    },
  },
  extraReducers: (builder) => {
    builder

      //ALL LIST
      .addCase(fetchAllExpenses.pending, (state, action) => {
        state.working = true;
      })
      .addCase(fetchAllExpenses.rejected, (state, action) => {
        state.working = false;
      })
      .addCase(fetchAllExpenses.fulfilled, (state, action) => {
        state.working = false;
        state.status = "succeeded";
        const data = action.payload;
        const formatedData = data.map((expense) => ({
          ...expense,
          id: expense._id,
        }));
        const { ids, entities } = normalize(formatedData);
        adapter.setAll(state, entities);
      })

      //DETAIL
      .addCase(fetchExpenseDetails.pending, (state, { meta: { arg } }) => {
        state.entities[arg].fetching = true;
      })
      .addCase(fetchExpenseDetails.rejected, (state, { meta: { arg } }) => {
        state.entities[arg].fetching = false;
      })
      .addCase(
        fetchExpenseDetails.fulfilled,
        (state, { meta: { arg }, payload }) => {
          state.entities[arg] = {
            ...state.entities[arg],
            ...payload,
            id: arg,
            fetching: false,
          };
        }
      );
  },
});

export const {
  setTableWorking,
  clearExpensesTableFilter,
  onChangeExpensesTableFilters,
  removeExpensesTableFilter,
  resetExpensesTable,
  setAllExpensesFilters,
  setTableActiveView,
} = allExpensesSlice.actions;

export const {
  selectAll: selectAllExpenses,
  selectIds: selectExpensesIds,
  selectById: selectExpenseById,
  selectEntities: selectExpensesEntities,
  selectTotal: selectTotalExpenses,
} = adapter.getSelectors((state) => state[baseName]);

export default allExpensesSlice.reducer;
