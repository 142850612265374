import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";

const baseName = "expenseTypes";

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  status: "idle",
  error: null,
});

//chamada c/ api rest;
export const fetchExpenseTypes = createAsyncThunk(
  `${baseName}/fetchExpenseTypes`,
  async () => {
    const res = await HttpClient.client().get(`/expenseTypes`);
    return res.data;
  }
);

export const expenseTypesSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    updateExpenseType(state, { payload }) {
      adapter.updateOne(state, {
        changes: payload.changes,
        id: payload.id,
      });
    },
    addExpenseType(state, action) {
      adapter.addOne(state, { ...action.payload, active: true });
    },
    setNewExpType(state, action) {
      const bool = action.payload;
      state.new = bool;
    },
    setViewExpType(state, action) {
      const typeId = action.payload;
      state.view = typeId;
    },
    removeExpenseType(state, action) {
      adapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpenseTypes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchExpenseTypes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchExpenseTypes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        const data = action.payload;
        state.ids = data.ids;
        let obj = {};
        const arrayObj = Object.keys(data.entities);
        for (let index = 0; index < arrayObj.length; index++) {
          const id = arrayObj[index];
          obj[id] = {
            ...data.entities[id],
            searchType: baseName,
          };
        }
        state.entities = obj;
      });
  },
});

export const {
  saveExpensesTypes,
  setNewExpType,
  setViewExpType,
  addExpenseType,
  updateExpenseType,
  removeExpenseType,
} = expenseTypesSlice.actions;

export const {
  selectAll: selectAllExpenseTypes,
  selectIds: selectExpenseTypesIds,
  selectById: selectExpenseTypeById,
  selectEntities: selectExpenseTypesEntities,
  selectTotal: selectExpenseTypesTotal,
} = adapter.getSelectors((state) => state[baseName]);

export default expenseTypesSlice.reducer;
