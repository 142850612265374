import { InfoOutlined } from "@mui/icons-material";
import { Box, Link, darken, lighten, useTheme } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAccountStatus } from "../store/reducers/accountSlice";
import { getAccountStatusData } from "../utils/accountStatus";

export const ACCOUNT_STATUS_BANNER_HEIGHT = 35;

const AccountStatusBanner = () => {
  const navigate = useNavigate();

  const accountStatus = useSelector(selectAccountStatus);

  const { palette } = useTheme();

  const { title, severity } = getAccountStatusData(accountStatus);

  const textColor = darken(
    palette[severity]?.main || "#272727",
    palette.mode === "dark" ? 0.7 : 0.5
  );
  const bgcolor = lighten(
    palette[severity]?.main || palette.primary,
    palette.mode === "light" ? 0.9 : 0.7
  );

  return (
    <Box
      bgcolor={bgcolor}
      display={"flex"}
      alignItems={"center"}
      px={2}
      pl={3.5}
      height={ACCOUNT_STATUS_BANNER_HEIGHT}
      left={0}
      width={"100%"}
      color={textColor}
      fontWeight={"500"}
      fontSize={".915rem"}
      gap={2}
    >
      <InfoOutlined sx={{ color: textColor, mr: 1 }} fontSize="small" />
      {title}
      <Link
        onClick={() => navigate("/billing")}
        color={"inherit"}
        variant="inherit"
      >
        Ver plano e faturamento
      </Link>
      <Box flex={1} />
    </Box>
  );
};

export default memo(AccountStatusBanner);
