import { Box } from "@mui/material";
import React, { memo } from "react";
import PermissionsSection from "../permissions/PermissionsSection";
import AccessSection from "./access/AccessSection";
import ConfigsSection from "./configs/ConfigsSection";

function DetailsSection({ userId }) {
  return (
    <Box
      mt={3}
      width={"100%"}
      display={"flex"}
      maxWidth={"55em"}
      flexDirection="column"
      gap={3}
    >
      <ConfigsSection userId={userId} />
      <Box display={"flex"} alignItems={"flex-start"} gap={2}>
        <PermissionsSection userId={userId} />
        <AccessSection userId={userId} />
      </Box>
    </Box>
  );
}

export default memo(DetailsSection);
