import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useCallback, useState } from "react";
import TextInput from "./text-input";

function PasswordInput({
  value,
  error,
  label,
  clearError,
  autoFocus,
  disableVisibilityButton,
  controlledType,
  onChange,
  inputRef
}) {
  const [visible, setVisible] = useState(false);

  const renderVisibilityButton = useCallback(() => {
    return (
      <IconButton onClick={() => setVisible(!visible)}>
        {visible ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
      </IconButton>
    );
  }, [visible]);

  return (
    <TextInput
      autoFocus={autoFocus}
      propError={Boolean(error)}
      helperText={error || ""}
      label={label || "Digite sua senha"}
      margin={"normal"}
      inputRef={inputRef}
      type={
        disableVisibilityButton ? controlledType : visible ? "text" : "password"
      }
      value={value}
      onChange={(v) => onChange(v.trim())}
      clearError={clearError}
      required
      RightIcon={!disableVisibilityButton && renderVisibilityButton}
    />
  );
}

export default PasswordInput;
