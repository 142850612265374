import { Close } from "@mui/icons-material";
import { darken, IconButton, Snackbar } from "@mui/material";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSnackbar,
  closeSnackbar,
} from "../../store/reducers/bases/snackbarBaseSlice";

function SnackbarBase(props) {
  const dispatch = useDispatch();

  const { open, message, action, error } = useSelector(
    (state) => state.snackbarBase
  ) || { open: false, message: "", action: null, error: false };

  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      autoHideDuration={3000}
      onClose={handleClose}
      TransitionProps={{
        onExited: () => dispatch(clearSnackbar()),
      }}
      message={message}
      ContentProps={{
        sx: {
          fontWeight: "600",
          bgcolor: (t) => error && darken(t?.palette?.error?.main, 0.1),
        },
      }}
      action={
        <>
          {action && action}
          <IconButton onClick={handleClose} color="inherit">
            <Close />
          </IconButton>
        </>
      }
    />
  );
}

export default memo(SnackbarBase);
