import { WorkspacePremiumOutlined } from "@mui/icons-material";
import { Avatar, Box, Chip } from "@mui/material";
import React, { memo } from "react";
import { connect } from "react-redux";
import StatusLabel from "../../../../../../components/USERS/StatusLabel";
import { selectUserById } from "../../../../../../store/reducers/usersSlice";
import { getProfilePicture } from "../../../../../../utils/functions/profile";
import EmailLabel from "../../../components/EmailLabel";
import NameLabel from "../../../components/NameLabel";

const AvatarPicker = memo(({ userId }) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      p={0.5}
      borderRadius={100}
      sx={{
        border: 1,
        borderColor: "divider",
      }}
    >
      <Avatar
        src={getProfilePicture(userId)}
        sx={{
          width: 105,
          height: 105,
          fontSize: "2.5rem",
          fontWeight: "500",
        }}
      ></Avatar>
    </Box>
  );
});

function DetailsContent({ userId, status, isMaster }) {
  return (
    <>
      <AvatarPicker userId={userId} />
      <Box ml={2.5}>
        <NameLabel userId={userId} />
        <EmailLabel userId={userId} />
        {isMaster && (
          <Chip
            size="small"
            sx={{ fontSize: ".8rem", fontWeight: "600", mt: 1 }}
            variant="outlined"
            label="Proprietário"
            icon={<WorkspacePremiumOutlined fontSize="small" color="primary" />}
          />
        )}
        <Box display={"flex"} mt={1} alignItems={"center"}>
          <StatusLabel userId={userId} />
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { name, lastname, email, status, roles } = selectUserById(
    state,
    ownProps.userId
  ) || {
    name: "",
    lastname: "",
    email: "",
    status: "",
  };
  return {
    name,
    lastname,
    email,
    status,
    isMaster: Boolean(roles?.master),
    userId: ownProps.userId,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(memo(DetailsContent));
