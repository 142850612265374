import {
  BarChartOutlined,
  BusinessOutlined,
  Close,
  CreditCardOutlined,
  DisplaySettingsOutlined,
  Groups2Outlined,
  IntegrationInstructionsOutlined,
  MenuOutlined,
  PeopleAltOutlined,
  ReceiptLongOutlined,
  SettingsOutlined,
  SupportAgentOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LogoSap from "../../assets/logo/logo-sap.png";
import { selectAccountRoles } from "../../store/reducers/accountSlice";
import NotepayLogo from "../NotepayLogo";
import ListSection from "../sidebar/ListSection";
import SidebarItem from "../sidebar/SidebarItem";
import { organizationsConfig } from "../sidebar/data";

const isSelected = (link, pathname) => {
  return new RegExp(`${link}`).test(pathname);
};

const CustomDrawer = ({ open, onClose }) => {
  const { pathname } = useLocation();

  const roles = useSelector(selectAccountRoles);
  //renders
  const renderSAPLogo = useCallback(
    () => (
      <img
        style={{
          filter: !pathname.includes("sap-integration") && "grayscale(100%)",
          scale: "1.1",
        }}
        src={LogoSap}
        width="30px"
      />
    ),
    [pathname.includes("sap-integration")]
  );

  useEffect(() => {
    onClose();
  }, [pathname]);

  const getIsSelected = (path) => isSelected(path, pathname);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "70%",
          maxWidth: 300,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        },
      }}
      open={open}
      onClose={onClose}
      SlideProps={{
        unmountOnExit: true,
      }}
    >
      <Box p={2} pr={1} pt={2} display={"flex"} justifyContent={"flex-end"}>
        <NotepayLogo style={{ width: "100px" }} />
        <Box flex={1} />
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <List sx={{ pr: 1, pb: 10 }}>
        <Divider sx={{ mb: 1 }} />
        <SidebarItem
          Icon={BarChartOutlined}
          path={"statistics"}
          title={"Visão geral"}
          isSelected={getIsSelected("statistics")}
        />
        <Divider sx={{ mt: 1 }} />
        <ListSection title="Financeiro">
          <SidebarItem
            Icon={DisplaySettingsOutlined}
            path={"expense-requests"}
            title={"Relatórios"}
            isSelected={getIsSelected("expense-requests")}
          />
          <SidebarItem
            Icon={ReceiptLongOutlined}
            path={"all-expenses"}
            title={"Despesas"}
            isSelected={getIsSelected("all-expenses")}
          />
        </ListSection>
        <ListSection title="Conta">
          <SidebarItem
            Icon={BusinessOutlined}
            path={"company-info"}
            title={"Empresa"}
            isSelected={getIsSelected("company-info")}
          />
          <SidebarItem
            Icon={Groups2Outlined}
            path={"groups"}
            title={"Grupos"}
            isSelected={getIsSelected("groups")}
          />
          <SidebarItem
            Icon={PeopleAltOutlined}
            path={"collaborators"}
            title={"Colaboradores"}
            isSelected={getIsSelected("collaborators")}
          />
          {roles.master && (
            <>
              <SidebarItem
                Icon={CreditCardOutlined}
                path={"billing"}
                title={"Plano e faturamento"}
                isSelected={getIsSelected("billing")}
              />
              <SidebarItem
                Icon={SettingsOutlined}
                path={"advanced-settings"}
                title={"Avançado"}
                isSelected={getIsSelected("advanced-settings")}
              />
            </>
          )}
        </ListSection>
        <ListSection title="Configurações">
          {organizationsConfig.map((item, idx) => (
            <SidebarItem
              key={idx.toString()}
              Icon={item.icon}
              path={item.path}
              title={item.title}
              isSelected={getIsSelected(item.path)}
            />
          ))}
        </ListSection>
        <ListSection title="Integrações">
          <SidebarItem
            Icon={renderSAPLogo}
            path={"sap-integration"}
            title={"Integração SAP"}
            isSelected={getIsSelected("sap-integration")}
          />
          <SidebarItem
            Icon={IntegrationInstructionsOutlined}
            path={"api-rest"}
            title={"Notepay API"}
            isSelected={getIsSelected("api-rest")}
          />
        </ListSection>
        <Box mt={1} />
        <SidebarItem
          Icon={SupportAgentOutlined}
          path={"#"}
          title={"Suporte"}
          isSelected={false}
          onClick={() => {
            const helpButton = document.getElementById(
              "notepay-support-button"
            );
            if (helpButton) {
              helpButton.click();
            }
            onClose();
          }}
        />
      </List>
    </Drawer>
  );
};

const DrawerNavigation = () => {
  const [opened, setOpened] = useState(false);

  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <>
      <IconButton
        sx={{ mr: 2, display: { xs: "flex", xl: "none" } }}
        onClick={() => setOpened(true)}
      >
        <MenuOutlined fontSize="24px" />
      </IconButton>
      {isSmall && (
        <CustomDrawer open={opened} onClose={() => setOpened(false)} />
      )}
    </>
  );
};

export default memo(DrawerNavigation);
