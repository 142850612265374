import { Close } from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    IconButton,
    LinearProgress,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { HttpClient } from "../../../../../../api/http-client";
import DateInput from "../../../../../../components/inputs/DateInput";
import { setError } from "../../../../../../store/reducers/bases/errorBaseSlice";
import { openSnackbar } from "../../../../../../store/reducers/bases/snackbarBaseSlice";
import { updateUser } from "../../../../../../store/reducers/usersSlice";

const ModalEditAccess = ({ initialValidTo, open, onClose, userId }) => {
  const dispatch = useDispatch();

  const [validTo, setValidTo] = useState(
    initialValidTo ? new Date(initialValidTo) : new Date()
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setValidTo(initialValidTo ? new Date(initialValidTo) : new Date());
      setLoading(false);
    }
  }, [open]);

  const handleSave = async () => {
    try {
      setLoading(true);
      await HttpClient.client().put(`/users/${userId}`, {
        valid_to: validTo,
      });
      dispatch(
        updateUser({
          id: userId,
          changes: {
            valid_to: validTo,
          },
        })
      );
      dispatch(
        openSnackbar({
          message: "Acesso atualizado",
        })
      );
    } catch (error) {
      dispatch(setError({ title: "Erro ao salvar validade de acesso", error }));
    } finally {
      onClose();
      setLoading(false);
    }
  };

  const isValid = useMemo(() => {
    if (!validTo) return false;
    return new Date().getTime() < new Date(validTo).getTime();
  }, [validTo]);

  return (
    <Dialog
      TransitionProps={{
        exit: false,
      }}
      open={open}
      maxWidth="xs"
      fullWidth
    >
      {loading && <LinearProgress />}
      <DialogTitle>
        Validade de acesso <Box flex={1} />
        <IconButton onClick={onClose} sx={{ m: -1 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DateInput
          autoFocus
          margin={"normal"}
          label={"Acesso válido até"}
          disabled={loading}
          value={validTo}
          onChange={setValidTo}
          minDate={new Date()}
          disableHelperText
        />
        {!isValid && (
          <FormHelperText error>Escolha uma data futura</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={handleSave}
          disabled={loading || !isValid}
          variant="contained"
          disableElevation
        >
          {loading ? "Salvando" : "Salvar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditAccess;
