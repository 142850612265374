import { NotificationsActiveOutlined } from "@mui/icons-material";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import {
  fetchNotifications,
  readNotifications,
  resetUnreadNotificationsTotal,
  selectNotificationsTotal,
  selectUnreadNotificationsNum,
} from "../../../../store/reducers/notificationsSlice";
import NoDataContent from "../../../form/NoDataContent";
import Layout from "./Layout";
import NotificationButton from "./NotificationButton";
import NotificationsMenu from "./NotificationsMenu";
import NotificationItem from "./notification-item/NotificationItem";

function NotificationsMain(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [menu, setMenu] = useState(null);

  const onClose = useCallback(() => {
    setMenu(null);
  }, []);

  const opened = useMemo(() => Boolean(menu), [menu]);

  const [filter, setFilter] = useState("all");

  const loading = useSelector((state) => state.notifications.loading);
  const notificationsTotal = useSelector(selectNotificationsTotal);
  const unreadNum = useSelector(selectUnreadNotificationsNum);

  const fetcher = useCallback(() => {
    dispatch(fetchNotifications());
  }, []);

  useEffect(() => {
    if (opened) {
      fetcher();
      dispatch(resetUnreadNotificationsTotal());
      dispatch(readNotifications());
    }
  }, [opened]);

  useEffect(() => {
    if (unreadNum > 0 && opened) {
      fetcher();
    }
  }, [unreadNum]);

  // References
  const listRef = useRef({});
  const rowHeights = useRef({});

  const handleChangeFilter = useCallback((value) => {
    setFilter(value);
  }, []);

  const setRowHeight = useCallback((index, size) => {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }, []);

  const getRowHeight = useCallback((index) => {
    return rowHeights.current[index] || 100;
  }, []);

  const renderRow = useCallback((props) => {
    return <NotificationItem {...props} setRowHeight={setRowHeight} />;
  }, []);

  const renderAutoSizerContent = useCallback(
    ({ height, width }) => {
      return (
        <VariableSizeList
          itemCount={notificationsTotal}
          itemSize={getRowHeight}
          height={height}
          width={width}
          ref={listRef}
          style={{ paddingBottom: "40px" }}
        >
          {renderRow}
        </VariableSizeList>
      );
    },
    [notificationsTotal, getRowHeight, renderRow]
  );

  return (
    <>
      <NotificationButton
        unreadNum={unreadNum}
        opened={opened}
        onClick={(e) => setMenu(e.target)}
      />
      {opened && (
        <NotificationsMenu anchorEl={menu} onClose={onClose}>
          <Layout
            filter={filter}
            onChangeFilter={handleChangeFilter}
            loading={loading}
            num={notificationsTotal}
            onClose={onClose}
          >
            {!Boolean(notificationsTotal) ? (
              <NoDataContent
                iconSize="6rem"
                titleSize="1.2rem"
                Icon={NotificationsActiveOutlined}
                title={"Nenhuma notificação"}
                subtitle={"Volte mais tarde para ver novidades"}
              />
            ) : (
              <AutoSizer>{renderAutoSizerContent}</AutoSizer>
            )}
          </Layout>
        </NotificationsMenu>
      )}
    </>
  );
}

export default memo(NotificationsMain);
