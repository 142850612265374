import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";

const baseName = "approvalFlows";

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchApprovalFlows = createAsyncThunk(
  `${baseName}/fetchApprovalFlows`,
  async () => {
    const res = await HttpClient.client().get("/apflows");
    return res.data;
  }
);

export const approvalFlowsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    addApprovalFlow(state, { payload }) {
      adapter.addOne(state, { ...payload, active: true });
    },
    removeApprovalFlow(state, { payload: entityId }) {
      adapter.removeOne(state, entityId);
    },
    updateApprovalFlow(state, { payload: { id, changes } }) {
      adapter.updateOne(state, {
        changes,
        id,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApprovalFlows.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchApprovalFlows.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchApprovalFlows.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        const data = action.payload;
        state.ids = data.ids;
        state.entities = data.entities;
      });
  },
});

export const { addApprovalFlow, removeApprovalFlow, updateApprovalFlow } =
  approvalFlowsSlice.actions;

export const {
  selectAll: selectAllApprovalFlows,
  selectIds: selectApprovalFlowsIds,
  selectById: selectApprovalFlowById,
  selectEntities: selectApprovalFlowsEntities,
  selectTotal: selectApprovalFlowsTotal,
} = adapter.getSelectors((state) => state[baseName]);

export default approvalFlowsSlice.reducer;
