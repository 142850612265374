import { EditOutlined } from "@mui/icons-material";
import {
  Alert,
  Box,
  Divider,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { selectHasUsersControlPermission } from "../../../../../../store/reducers/accountSlice";
import { selectUserById } from "../../../../../../store/reducers/usersSlice";
import {
  formatDate,
  formatMoment,
} from "../../../../../../utils/more/date_functions";
import BasePaper from "../../../components/BasePaper";
import ModalEditAccess from "./ModalEditAccess";

const AccessSection = ({ userId, lastAccess, validTo, createdAt }) => {
  const isValid = useMemo(() => {
    if (!validTo) return false;
    return new Date().getTime() < new Date(validTo).getTime();
  }, [validTo]);

  const [editModal, setEditModal] = useState(false);

  const hasPermissionToEdit = useSelector(selectHasUsersControlPermission);

  return (
    <BasePaper title={"Outras informações"}>
      {!isValid && (
        <Alert severity="warning" sx={{ mb: 2, mt: -0.5, fontWeight: "500" }}>
          O acesso do colaborador expirou em {formatDate(validTo)}
          {hasPermissionToEdit && (
            <Link
              width={100}
              component={"div"}
              variant="inherit"
              color={"inherit"}
              onClick={() => setEditModal(true)}
            >
              Editar
            </Link>
          )}
        </Alert>
      )}
      <Box px={2} pb={2}>
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant="body2" color={"text.secondary"}>
            Validade de acesso
          </Typography>
          <Box flex={1} />
          <Typography
            color={isValid ? "text.primary" : "warning.main"}
            variant="body2"
            fontWeight={isValid ? "500" : "600"}
          >
            {validTo ? formatMoment(validTo) : "--"}
          </Typography>
          {hasPermissionToEdit && (
            <Tooltip title="Editar validade de acesso">
              <IconButton
                onClick={() => setEditModal(true)}
                sx={{ ml: 1, mr: -1 }}
                size="small"
              >
                <EditOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant="body2" color={"text.secondary"}>
            Último acesso
          </Typography>
          <Box flex={1} />
          <Typography variant="body2" fontWeight={"500"}>
            {lastAccess ? formatMoment(lastAccess) : "--"}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant="body2" color={"text.secondary"}>
            Data de criação
          </Typography>
          <Box flex={1} />
          <Typography variant="body2" fontWeight={"500"}>
            {createdAt ? formatMoment(createdAt) : "--"}
          </Typography>
        </Box>
      </Box>
      {hasPermissionToEdit && (
        <ModalEditAccess
          initialValidTo={validTo}
          open={editModal}
          onClose={() => setEditModal(false)}
          userId={userId}
        />
      )}
    </BasePaper>
  );
};
function mapStateToProps(state, ownProps) {
  const { userId } = ownProps;

  const { last_access, valid_to, created_at } = selectUserById(
    state,
    userId
  ) || {
    last_access: new Date().toISOString(),
    valid_to: new Date().toISOString(),
    created_at: new Date().toISOString(),
  };
  return {
    lastAccess: last_access,
    validTo: valid_to,
    createdAt: created_at,
    ...ownProps,
  };
}

export default connect(mapStateToProps)(memo(AccessSection));
