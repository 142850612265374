import { EditOutlined, PersonOutline } from "@mui/icons-material";
import { Avatar, Badge } from "@mui/material";
import React, { memo, useRef, useState } from "react";
import ModalViewPhoto from "./ModalViewPhoto";

function AvatarPicker({ photo, onChange, onRemovePhoto }) {
  const [open, setOpen] = useState(false);

  const inputFileRef = useRef(null);

  return (
    <>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <>
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation();
                inputFileRef && inputFileRef.current.click();
              }}
              sx={{
                bgcolor: "background.default",
                borderRadius: 100,
                p: 0.5,
                fontSize: "2rem",
                cursor: "pointer",
                ":hover": {
                  boxShadow: 2,
                },
              }}
              color="primary"
            />
          </>
        }
      >
        <Avatar
          onClick={(e) => {
            if (photo) {
              setOpen(true);
            } else {
              inputFileRef && inputFileRef.current.click();
            }
          }}
          src={photo}
          sx={{
            width: 120,
            height: 120,
            border: 1,
            bgcolor: (t) => `${t.palette.primary.main}10`,
            color: "primary.main",
            borderColor: "divider",
            cursor: "pointer",
            ":hover": {
              opacity: 0.8,
              boxShadow: 2,
            },
          }}
        >
          <PersonOutline sx={{ fontSize: "4rem" }} />
        </Avatar>
      </Badge>
      <input
        ref={inputFileRef}
        onChange={onChange}
        hidden
        accept="image/*"
        multiple
        type="file"
      />
      {open && (
        <ModalViewPhoto
          onClose={() => setOpen(false)}
          open={open}
          onEdit={(e) => {
            e.stopPropagation();
            inputFileRef && inputFileRef.current.click();
          }}
          photo={photo}
          onRemove={() => {
            setOpen(false);
            onRemovePhoto();
          }}
        />
      )}
    </>
  );
}

export default memo(AvatarPicker);
