import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Box, ListItemButton, ListItemText, Typography } from "@mui/material";
import React, { memo } from "react";

const ListSectionTitle = ({ children, onClick, expanded }) => {
  return (
    <ListItemButton
      onClick={onClick}
      sx={{
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
        height: 43,
        display: "flex",
        alignItems: "center",
      }}
    >
      {Boolean(children) ? (
        <ListItemText
          primary={
            <Typography
              variant="body2"
              fontSize={".92rem"}
              color={"text.primary"}
              fontWeight={"500"}
              sx={{ opacity: 0.8 }}
              noWrap
            >
              {children}
            </Typography>
          }
        />
      ) : (
        <Box mr={1.5} />
      )}
      {expanded ? (
        <ArrowDropUp fontSize="small" color="action" sx={{ opacity: 0.7 }} />
      ) : (
        <ArrowDropDown fontSize="small" color="action" sx={{ opacity: 0.7 }} />
      )}
    </ListItemButton>
  );
};

export default memo(ListSectionTitle);
