import { BusinessCenterOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  fetchOrgs,
  selectOrgById,
} from "../../../../../../../../store/reducers/orgsSlice";
import { selectUserById } from "../../../../../../../../store/reducers/usersSlice";
import DetailListItem from "../../../../../components/DetailListItem";
import MenuView from "./MenuView";

function OrgItem({ orgName, orgId, userId, orgStatus }) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (orgStatus === "idle") {
      dispatch(fetchOrgs());
    }
  }, []);

  return (
    <>
      <DetailListItem
        emptyText={"Sem organização"}
        Icon={BusinessCenterOutlined}
        title={"Organização"}
        focused={modal}
        loading={orgStatus === "loading"}
        onClick={(e) => setModal(true)}
      >
        {orgId && (
          <Typography component={"span"} variant="inherit" fontWeight={"500"}>
            {orgName}
          </Typography>
        )}
      </DetailListItem>
      <MenuView
        open={modal}
        onClose={() => setModal(false)}
        org={orgId}
        userId={userId}
      />
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { org } = selectUserById(state, ownProps.userId) || { org: "" };

  const orgData = selectOrgById(state, org);

  return {
    orgName: orgData && orgData.name,
    orgId: org,
    orgStatus: state.orgs.status,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(memo(OrgItem));
