import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";

const initialState = {
  general: {
    status: "idle",
    error: null,
    data: {
      alcohol_receipts: true,
      dup_receipts: true,
    },
  },
  sso: {
    status: "idle",
    isActive: false,
    error: null,
    data: {
      is_configured: false,
    },
  },
};

export const fetchGeneral = createAsyncThunk(
  "advancedSettings/fetchGeneral",
  async () => {
    const res = await HttpClient.client().get("/account/settings/general");
    return res.data;
  }
);

export const fetchSSODetails = createAsyncThunk(
  "advancedSettings/fetchSSODetails",
  async () => {
    const res = await HttpClient.client().get("/account/settings/sso ");
    return res.data;
  }
);

export const advancedSettingsSlice = createSlice({
  name: "advancedSettings",
  initialState,
  reducers: {
    setGeneralSetting(state, { payload }) {
      const { setting, value } = payload;
      state.general.data[setting] = value;
    },
    setSSOData(state, { payload }) {
      state.sso.data = {
        ...state.sso.data,
        ...payload,
      };
    },
    setSSOActive(state, { payload }) {
      state.sso.isActive = Boolean(payload);
    },
  },
  extraReducers: (builder) =>
    builder
      //general
      .addCase(fetchGeneral.pending, (state, { payload }) => {
        state.general.status = "loading";
        state.general.error = null;
      })
      .addCase(fetchGeneral.rejected, (state, { error }) => {
        state.general.status = "failed";
        state.general.error = error.message;
      })
      .addCase(fetchGeneral.fulfilled, (state, { payload }) => {
        state.general.status = "succeeded";
        state.general.error = null;
        state.general.data.dup_receipts = Boolean(payload?.enable_dup_receipts);
        state.general.data.alcohol_receipts = Boolean(
          payload?.enable_alcohol_receipts
        );
      })

      //SSO
      .addCase(fetchSSODetails.pending, (state, { payload }) => {
        state.sso.status = "loading";
        state.sso.error = null;
      })
      .addCase(fetchSSODetails.rejected, (state, { error }) => {
        state.sso.status =
          error.code === "ERR_BAD_REQUEST" ? "succeeded" : "failed";
        state.sso.isActive = false;
        state.sso.error = error.message;
      })
      .addCase(fetchSSODetails.fulfilled, (state, { payload }) => {
        state.sso.status = "succeeded";
        state.sso.error = null;
        state.sso.isActive = Boolean(payload?.is_configured);
        state.sso.data = payload;
      }),
});

export const { setGeneralSetting, setSSOData, setSSOActive } =
  advancedSettingsSlice.actions;

export default advancedSettingsSlice.reducer;
