import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { HttpClient } from "../../../api/http-client";
import TextInput from "../../../components/inputs/text-input";
import { setError } from "../../../store/reducers/bases/errorBaseSlice";
import { openSnackbar } from "../../../store/reducers/bases/snackbarBaseSlice";
import { addOccupation } from "../../../store/reducers/occupationsSlice";

function ModalCreateOccupation({ open, onClose, onCreate = () => {} }) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");

  const [loading, setLoading] = useState(false);

  const handleCreate = async () => {
    setLoading(true);
    try {
      const { data } = await HttpClient.client().post("/occupations", {
        name,
      });
      dispatch(addOccupation({ id: data.id, name }));
      dispatch(openSnackbar({ message: "Cargo criado" }));
      setName("");
      onClose();
      onCreate(data.id);
    } catch (error) {
      dispatch(
        setError({
          title: "Erro ao criar cargo",
          error,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      {loading && <LinearProgress />}
      <DialogTitle>Criar cargo</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Defina cargos para facilitar a gestão de sua equipe, encontrando colaboradores com mais facilidade
        </DialogContentText>
        <TextInput
          value={name}
          placeholder={"Ex: Gerente de TI"}
          onChange={setName}
          autoFocus
          required
          margin="normal"
          label="Nome do cargo"
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          disabled={!Boolean(name.trim().length) || loading}
          onClick={handleCreate}
          variant="contained"
          disableElevation
        >
          Criar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalCreateOccupation;
