import { createSelector, createSlice, nanoid } from "@reduxjs/toolkit";
import { accountStatusIsError } from "../../utils/accountStatus";
import { getProfilePicture } from "../../utils/functions/profile";
import { curr } from "../../utils/more/currency_country";

const initialState = {
  accessExpiresAt: new Date().toISOString(),
  data: {
    account: {
      name: "",
      status: "",
      currency: "",
    },
    roles: {},
    hasLogo: false,
  },
  receiptsAccessToken: {
    token: null,
    expiresAt: null,
  },
  hasUpdatesInVersion: "2.0.0",
  checkedUpdates: false,
  logoVersion: 0,
};

export const accountInitialState = initialState;

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    saveAccount: (state, { payload }) => {
      state.data = {
        ...state.data,
        ...payload,
        account: {
          ...state.data.account,
          ...payload?.account,
          _id: payload?.account?._id || state.data?.account?._id,
        },
        hasLogo: payload?.account?.has_logo || false,
        profilePhotoUrl: getProfilePicture(
          payload._id,
          payload?.profile_photo_version || nanoid(4)
        ),
      };
    },
    updateCompanyName(state, { payload }) {
      state.data.account.name = payload;
    },
    updateAccountCurrency(state, { payload }) {
      state.data.account.currency = payload;
    },
    checkUpdates(state, { payload }) {
      state.checkedUpdates = true;
    },
    updateProfilePhotoUrl(state, { payload }) {
      state.data.profilePhotoUrl = payload;
    },
    updateLogoVersion(state) {
      state.logoVersion = nanoid(4);
    },
    updateDefaultRoutePolicy(state, { payload }) {
      state.data.account.route_policy = payload;
    },
    setHasLogo(state, { payload }) {
      state.data.hasLogo = payload;
    },
    setReceiptsAccessToken(state, { payload }) {
      state.receiptsAccessToken = {
        token: payload?.token || null,
        expiresAt: payload?.expiresAt || null,
      };
    },
    setAccessExpiresAt(state, { payload }) {
      state.accessExpiresAt = new Date(payload).toISOString();
    },
  },
});

export const {
  saveAccount,
  updateProfilePhotoUrl,
  updateDefaultRoutePolicy,
  updateCompanyName,
  updateAccountCurrency,
  setHasLogo,
  setReceiptsAccessToken,
  updateLogoVersion,
  checkUpdates,
  setAccessExpiresAt,
} = accountSlice.actions;

export default accountSlice.reducer;

export const selectProfilePhotoUrl = (state) =>
  state.account.data.profilePhotoUrl;

export const selectIsMe = createSelector(
  [(state) => state.account.data._id, (state, userId) => userId],
  (myId, compareId) => {
    return Boolean(myId === compareId);
  }
);

export const selectReceiptsAccessToken = (state) =>
  state.account.receiptsAccessToken;

export const selectMyUserId = (state) => state.account.data._id;

//my company
export const selectCompanyName = (state) =>
  state.account.data &&
  state.account.data.account &&
  state.account.data.account?.name;

export const selectAccountCurrency = createSelector(
  [
    (state) => state.account.data.account?.currency,
    (state, formatter = true) => formatter,
  ],
  (currency, formatter = true) => {
    return formatter ? curr(currency) : currency;
  }
);

export const selectDefaultRoutePolicy = (state) =>
  state.account.data &&
  state.account.data.account &&
  state.account.data.account?.route_policy;

export const selectAccountRoles = (state) => state.account.data.roles;

export const selectAccountIsMaster = (state) =>
  Boolean(state.account.data.roles?.master);

export const selectHasSettingsPermission = (state) =>
  (Boolean(state.account.data.roles?.master) ||
    Boolean(state.account.data.roles?.admin?.settings)) &&
  !accountStatusIsError(state.account.data?.account?.status);

export const selectAccountReadOnly = (state) =>
  accountStatusIsError(state.account.data?.account?.status);

export const selectHasUsersControlPermission = (state) =>
  Boolean(state.account.data.roles?.master) ||
  Boolean(state.account.data.roles?.admin?.users_control);

export const selectHasSAPControlPermission = (state) =>
  Boolean(state.account.data.roles?.master) ||
  Boolean(state.account.data.roles?.admin?.panel_sap);

export const selectAccountStatus = (state) =>
  state.account.data?.account?.status;

export const selectAccountFeatures = (state) =>
  state.account.data?.account?.features;
