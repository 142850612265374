import {
  DeleteOutlineOutlined,
  ForwardToInboxOutlined,
  MoreVert,
} from "@mui/icons-material";
import {
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HttpClient } from "../../../../../../api/http-client";
import ModalDelete from "../../../../../../modals/ModalDelete";
import { setError } from "../../../../../../store/reducers/bases/errorBaseSlice";
import { openSnackbar } from "../../../../../../store/reducers/bases/snackbarBaseSlice";
import { removeUser } from "../../../../../../store/reducers/usersSlice";
import ModalNewInvite from "../../../components/modals/ModalNewInvite";
import ModalUserActivation from "../../../components/modals/ModalUserActivation";

const MenuItemSx = {
  height: 40,
  px: 1.5,
  fontSize: ".95rem",
  fontWeight: "600",
  borderRadius: 1,
  border: 1,
  borderColor: "divider",
  color: "primary.main",
  gap: 1.5,
};

const RemoveUserItem = ({ userId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleDelete = async () => {
    try {
      await HttpClient.client().delete(`/users/${userId}`);
      dispatch(removeUser(userId));
      navigate("/collaborators");
      dispatch(openSnackbar({ message: "Colaborador removido" }));
    } catch (error) {
      dispatch(setError({ title: "Erro ao excluir colaborador", error }));
    }
  };

  return (
    <>
      <MenuItem onClick={() => setOpen(true)} sx={MenuItemSx}>
        <DeleteOutlineOutlined color="primary" /> Excluir
      </MenuItem>
      <ModalDelete
        open={open}
        title="Excluir colaborador"
        subtitle="O colaborador não poderá mais acessar sua conta. Esta ação é irreversível."
        onClose={() => setOpen(false)}
        onDelete={handleDelete}
      />
    </>
  );
};

const ActivationItem = memo(({ status, userId }) => {
  const [modal, setModal] = useState(false);
  const active = status === "active";

  return (
    <>
      <MenuItem
        onClick={() => {
          setModal(true);
        }}
        sx={{
          height: 50,
          fontSize: "1rem",
          fontWeight: "600",
          color: active ? "primary.main" : "text.secondary",
        }}
      >
        {active ? "Ativo" : "Desativado"}
        <Switch checked={active} sx={{ ml: "auto" }} />
      </MenuItem>
      <ModalUserActivation
        open={modal}
        onClose={() => setModal(false)}
        active={active}
        userId={userId}
      />
    </>
  );
});

const NewInviteItem = memo(({ userId }) => {
  const [modal, setModal] = useState(false);
  return (
    <>
      <MenuItem
        onClick={() => {
          setModal(true);
        }}
        sx={MenuItemSx}
      >
        <ForwardToInboxOutlined color="primary" />
        Reenviar convite
      </MenuItem>
      <ModalNewInvite
        open={modal}
        onClose={() => setModal(false)}
        userId={userId}
      />
    </>
  );
});

const ActionsMenu = ({ userId, status }) => {
  const [menu, setMenu] = useState(null);

  return (
    <>
      {status !== "inviting" && (
        <Tooltip title="Mais opções">
          <IconButton onClick={(e) => setMenu(e.target)} sx={{ mr: 1 }}>
            <MoreVert color="primary" />
          </IconButton>
        </Tooltip>
      )}
      <Menu
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        slotProps={{
          paper: {
            sx: {
              width: 250,
            },
          },
        }}
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={() => setMenu(null)}
      >
        {status !== "inviting" && (
          <>
            <Typography m={2} my={1} color={"text.secondary"} fontSize={"1rem"}>
              Status
            </Typography>
            <ActivationItem status={status} userId={userId} />
          </>
        )}
      </Menu>
      {status === "inviting" && <NewInviteItem userId={userId} />}
      <RemoveUserItem userId={userId} />
    </>
  );
};

export default memo(ActionsMenu);
