import { addDays } from "date-fns";
import { setReceiptsAccessToken } from "../store/reducers/accountSlice";
import { store } from "../store/store";
import { HttpClient } from "./http-client";
import { refreshToken } from "./start";
import { RECEIPTS_URL } from "./urls";

const updateReceiptsAccessToken = async (accessToken) => {
  if (!accessToken) return;
  try {
    const {
      data: { token: receiptsAccessToken },
    } = await HttpClient.client().get(
      `${RECEIPTS_URL}/token?access_token=${accessToken}`
    );
    const expiresAt = addDays(new Date(), 20).toISOString();
    store.dispatch(
      setReceiptsAccessToken({
        token: receiptsAccessToken,
        expiresAt,
      })
    );
  } catch (error) {
    const status = error?.response?.status;
    if (status === 401) {
      const { isAuth, accessToken: newAccessToken } = await refreshToken();
      if (isAuth) {
        updateReceiptsAccessToken(newAccessToken);
      }
    }
  }
};
export default updateReceiptsAccessToken;
