import { Close, EditOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { HttpClient } from "../../../../api/http-client";
import TextInput from "../../../../components/inputs/text-input";
import { selectHasUsersControlPermission } from "../../../../store/reducers/accountSlice";
import { openSnackbar } from "../../../../store/reducers/bases/snackbarBaseSlice";
import {
  selectUserById,
  updateUser,
} from "../../../../store/reducers/usersSlice";

function MenuView({ open, onClose, initialName, initialLastname, userId }) {
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(initialName || "");
  const [lastname, setLastname] = useState(initialLastname || "");

  useEffect(() => {
    setName(initialName);
    setLastname(initialLastname);
  }, [open]);

  const dispatch = useDispatch();

  const handleSave = async () => {
    setLoading(true);
    try {
      const changes = {
        name,
        lastname,
      };
      await HttpClient.client().put(`/users/${userId}`, changes);
      dispatch(updateUser({ id: userId, changes }));
      dispatch(openSnackbar({ message: "Nome salvo" }));
      onClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
      {loading && <LinearProgress />}
      <DialogTitle height={55}>
        <Typography
          fontSize={"1rem"}
          color={"text.secondary"}
          variant="inherit"
        >
          Editar nome
        </Typography>
        <Box flex={1} />
        <IconButton sx={{ m: -1 }} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box mt={3} display={"flex"} alignItems={"center"}>
          <TextInput
            value={name}
            autoFocus
            label={"Nome"}
            required
            onChange={setName}
            variant="standard"
          />
          <TextInput
            value={lastname}
            label={"Último nome"}
            onChange={setLastname}
            variant="standard"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancelar
        </Button>
        <Button disabled={loading} onClick={handleSave}>
          {loading ? "Salvando" : "Salvar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function NameLabel({ name, lastname, userId }) {
  const [modal, setModal] = useState(false);

  const permited = useSelector(selectHasUsersControlPermission);

  const hasName = useMemo(
    () => Boolean((name + lastname)?.trim()?.length),
    [name, lastname]
  );

  return (
    <Box mb={0.3} display={"flex"} justifyContent="flex-start">
      <Typography
        variant="h4"
        onClick={(e) => permited && setModal(true)}
        display="flex"
        fontSize={"1.9rem"}
        fontWeight={"500"}
        alignItems={"center"}
        color={hasName ? "text.primary" : "text.secondary"}
        sx={{
          cursor: "pointer",
          ":hover": {
            textDecoration: permited && "underline",
            opacity: permited && 0.85,
          },
        }}
      >
        {hasName ? `${name} ${lastname}` : "Sem nome"}
        {permited && (
          <IconButton
            sx={{ ml: 1, opacity: 0.4, ":hover": { opacity: 0.8 } }}
            size="small"
          >
            <EditOutlined fontSize="small" />
          </IconButton>
        )}
      </Typography>
      {permited && (
        <MenuView
          open={modal}
          onClose={() => setModal(false)}
          initialLastname={lastname}
          initialName={name}
          userId={userId}
        />
      )}
    </Box>
  );
}

function mapStateToProps(state, ownProps) {
  const { name, lastname } = selectUserById(state, ownProps.userId) || {
    name: "",
    lastname: "",
  };
  return {
    name,
    lastname,
    ...ownProps,
  };
}

export default connect(mapStateToProps)(memo(NameLabel));
