import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { HighlightedText } from "../../../../utils/functions/highlightText";
import { getProfilePicture } from "../../../../utils/functions/profile";

function UserListItem({ user = {}, searchTerm, onBlur }) {
  const navigate = useNavigate();

  const { name, email, lastname, _id } = user || { name: "", email: "" };

  const fullname = `${name}${lastname ? ` ${lastname}` : ""}`;

  return (
    <ListItem disablePadding dense disableGutters>
      <ListItemButton
        onClick={() => {
          onBlur();
          navigate(`/collaborators/?userView=${_id}`);
        }}
        sx={{ transition: "none", height: 60 }}
      >
        <ListItemAvatar>
          <Avatar
            src={getProfilePicture(_id)}
            sx={{ width: 30, height: 30, fontWeight: "600" }}
          >
            {(name[0] && name[0]?.toUpperCase()) ||
              (email[0] && email[0]?.toUpperCase())}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ fontWeight: "600" }}
          sx={{ ml: -1 }}
          primary={
            <HighlightedText text={fullname || ""} searchTerm={searchTerm} />
          }
          secondary={
            <HighlightedText text={email || ""} searchTerm={searchTerm} />
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

export default UserListItem;
