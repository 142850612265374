import { PersonAddOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import { HttpClient } from "../../api/http-client";
import { setError } from "../../store/reducers/bases/errorBaseSlice";
import { openSnackbar } from "../../store/reducers/bases/snackbarBaseSlice";
import { closeElement } from "../../store/reducers/portalSlice";
import { addUser } from "../../store/reducers/usersSlice";
import { blobToBase64 } from "../../utils/more/images_transform";
import DialogFullScreenLayout from "../DialogFullScreenLayout";
import BaseInfoSection from "./sections/BaseInfoSection";
import PermissionsSection from "./sections/PermissionsSection";
import SettingsSection from "./sections/SettingsSection";
import ValidSection from "./sections/ValidSection";

export const initialPermissions = {
  master: false,
  approver: false,
  admin: {
    active: false,
    settings: false,
    users_control: false,
    analysis: false,
    panel_control: false,
    panel_sap: false,
  },
};

export const userInitialSettings = {
  org: "",
  valid_to: "",
  routePolicyId: "default",
  apflowId: "",
  occupation: "",
  group_id: "",
  notes: "",
};

const initialInfo = {
  name: "",
  lastname: "",
  email: "",
};

function ModalRegisterUser(props) {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.portal.newUser.open);

  const onClose = useCallback(() => {
    dispatch(closeElement("newUser"));
  }, []);

  const [loading, setLoading] = useState(false);

  const [photo, setPhoto] = useState(null);

  const [info, setInfo] = useState(initialInfo);

  const [settings, setSettings] = useState(userInitialSettings);

  const [validTo, setValidTo] = useState(
    new Date().setFullYear(new Date().getFullYear() + 2)
  );

  const [permissions, setPermissions] = useState(initialPermissions);

  useEffect(() => {
    if (!open) {
      setInfo(initialInfo);
      setSettings(userInitialSettings);
      setPhoto(null);
      setValidTo(new Date().setFullYear(new Date().getFullYear() + 2));
    }
  }, [open]);

  //info
  const handleChangePhoto = useCallback((e) => {
    setPhoto(URL.createObjectURL(e.target.files[0]));
  }, []);

  const handleRemovePhoto = useCallback((e) => {
    setPhoto(null);
  }, []);

  const handleInfoChange = useCallback((prop, v) => {
    setInfo((prev) => ({ ...prev, [prop]: v }));
  }, []);

  //settings
  const handleChangeSettings = useCallback((prop, v) => {
    setSettings((prev) => ({ ...prev, [prop]: v }));
  }, []);

  //permissions

  const handleChangePermissions = useCallback((path = "", value) => {
    const settings = path.split(".");
    setPermissions((prev) => {
      if (!path.includes(".") && !Boolean(settings.length)) {
        prev[path] = value;
        return { ...prev };
      } else if (settings.length === 1) {
        prev[settings[0]] = value;
        return { ...prev };
      } else if (settings.length === 2) {
        prev[settings[0]][settings[1]] = value;
        return { ...prev };
      } else if (settings.length === 3) {
        prev[settings[0]][settings[1]][settings[2]] = value;
        return { ...prev };
      } else if (settings.length === 4) {
        prev[settings[0]][settings[1]][settings[2]][settings[3]] = value;
        return { ...prev };
      }
    });
  }, []);

  //valid
  const handleChangeValid = useCallback((v) => {
    setValidTo(v);
  }, []);

  const isOk = useMemo(() => {
    return (
      [info.name.trim(), info.email.trim()].every(Boolean) &&
      validator.isEmail(info.email)
    );
  }, [info.email, info.name]);

  //

  const handleRegisterUser = async () => {
    setLoading(true);
    try {
      const formatedData = {
        ...info,
        ...settings,
        status: "inviting",
        routePolicyId:
          settings.routePolicyId === "default" || settings.routePolicyId === ""
            ? null
            : settings.routePolicyId,
        apflowId:
          settings.apflowId === "default" || settings.apflowId === ""
            ? null
            : settings.apflowId,
        org: settings.org || null,
        group_id: settings.group_id || null,
        valid_to: validTo,
        roles: {
          master: permissions.master,
          admin: permissions.admin.active
            ? {
                ...permissions.admin,
              }
            : false,
          approver: permissions.approver,
        },
      };
      const res = await HttpClient.client().post("users", formatedData);
      if (photo) {
        const base64String = await blobToBase64(photo);
        await HttpClient.client().post(`/users/${res.data.userId}/photo`, {
          base64: base64String,
        });
      }
      dispatch(
        openSnackbar({
          message: "O colaborador foi adicionado",
        })
      );
      dispatch(
        addUser({
          id: res.data.userId,
          photo,
          ...formatedData,
        })
      );
      onClose();
      return;
    } catch (error) {
      dispatch(
        setError({
          title: "Erro ao registrar colaborador",
          error,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <DialogFullScreenLayout
      open={open}
      onClose={onClose}
      title={"Cadastrar colaborador"}
      maxWidth={700}
      Icon={PersonAddOutlined}
      loading={loading}
      headerContent={
        <>
          <Button
            disabled={!isOk || loading}
            onClick={handleRegisterUser}
            variant="contained"
            disableElevation
            size="large"
          >
            {loading ? "Cadastrando..." : "Cadastrar"}
          </Button>
        </>
      }
    >
      <BaseInfoSection
        email={info.email}
        photo={photo}
        onChangePhoto={handleChangePhoto}
        onRemovePhoto={handleRemovePhoto}
        onInputChange={handleInfoChange}
      />
      <SettingsSection
        groupId={settings?.group_id}
        onInputChange={handleChangeSettings}
      />
      <PermissionsSection
        permissions={permissions}
        onChangePermissions={handleChangePermissions}
      />
      <ValidSection validTo={validTo} onChange={handleChangeValid} />
    </DialogFullScreenLayout>
  );
}

export default ModalRegisterUser;
