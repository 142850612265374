import { ListItemButton, ListItemText } from "@mui/material";
import React, { memo } from "react";

function SectionActionButton({ children, Icon, disabled, onClick }) {
  return (
    <ListItemButton
      disabled={disabled}
      disableTouchRipple
      onClick={onClick}
      sx={{
        transition: "none",
        ":hover": {
          bgcolor: (t) => `${t.palette.primary.main}25`,
        },
      }}
    >
      {Icon && <Icon sx={{ mr: 3 }} color="action" />}
      <ListItemText
        primaryTypographyProps={{
          fontWeight: "500",
          fontSize: ".9rem",
        }}
        primary={children}
      />
    </ListItemButton>
  );
}

export default memo(SectionActionButton);
