import { Add, LockClockOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import NoAccessTooltip from "../../components/access/NoConfigAccessTooltip";
import { selectHasSettingsPermission } from "../../store/reducers/accountSlice";

function primeiraLetraMaiuscula(str) {
  // Transforma toda a string em minúsculas
  str = str.toLowerCase();
  // Converte a primeira letra para maiúscula
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function CreateButton({
  onClick,
  children,
  disableIcon,
  variant = "outlined",
  color,
  Icon = Add,
  sx,
  disableCheckPermission,
}) {
  const hasPermission = useSelector((state) =>
    disableCheckPermission ? true : selectHasSettingsPermission(state)
  );

  return (
    <NoAccessTooltip disableCheckPermission={disableCheckPermission}>
      <span>
        <Button
          disabled={!hasPermission}
          variant={variant}
          color={color}
          disableElevation={variant === "contained"}
          startIcon={
            !hasPermission ? (
              <LockClockOutlined />
            ) : !disableIcon ? (
              <Icon />
            ) : null
          }
          sx={{
            mr: 1,
            transition: "none",
            ...sx,
            borderRadius: undefined
          }}
          onClick={onClick}
        >
          <Typography
            noWrap
            lineHeight={"24px"}
            fontSize={".95rem"}
            fontWeight={"600"}
          >
            {children && primeiraLetraMaiuscula(children)}
          </Typography>
        </Button>
      </span>
    </NoAccessTooltip>
  );
}

export default memo(CreateButton);
