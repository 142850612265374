import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography,
} from "@mui/material";
import React, { memo, useCallback } from "react";

const PermissionListItem = ({
  divider,
  checked,
  primary = "",
  secondary = "",
  onClick,
  strong,
}) => {
  return (
    <ListItem divider={divider} disableGutters>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        minWidth={80}
      >
        <Switch checked={checked} onClick={() => onClick(!checked)} />
      </Box>
      <ListItemText
        secondaryTypographyProps={{ fontSize: ".85rem" }}
        primaryTypographyProps={{
          fontSize: ".95rem",
          fontWeight: strong ? "600" : "500",
        }}
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );
};

const PermissionsList = ({
  sx,
  permissions = { approver: false, admin: { active: false }, master: false },
  onChangePermissions = () => {},
}) => {
  return (
    <List sx={sx}>
      <Typography mx={2} fontWeight={"500"} fontSize={".9rem"}>
        Por padrão, todos os colaboradores podem prestar despesas.
      </Typography>
      <PermissionListItem
        strong
        divider
        primary="Aprovador"
        secondary="Permita que o colaborador aprove relatórios e adiantamentos."
        checked={permissions.approver}
        onClick={useCallback(
          (value) => onChangePermissions("approver", value),
          []
        )}
      />
      <PermissionListItem
        strong
        primary="Administrador"
        secondary="Permita que o colaborador acesse o painel de administrador da sua empresa"
        checked={permissions.admin.active}
        onClick={useCallback(
          (value) => onChangePermissions("admin.active", value),
          []
        )}
      />
      <Collapse in={permissions.admin.active}>
        <Box>
          <Typography
            fontWeight={"600"}
            variant="overline"
            color={"text.secondary"}
            ml={2}
          >
            Opções de administração
          </Typography>
          <PermissionListItem
            strong
            divider
            primary="Editar configurações gerais da conta"
            secondary="Permita que o colaborador gerencie os dados da sua empresa: categorias de despesas, formas de pagamentos, fluxos de aprovação, motivos de rejeição, regras de despesas, projetos e políticas de percurso."
            checked={permissions.admin.settings}
            onClick={useCallback(
              (value) => onChangePermissions("admin.settings", value),
              []
            )}
          />
          <PermissionListItem
            strong
            divider
            primary="Gerenciar e configurar colaboradores"
            secondary="Permita que o colaborador gerencie as configurações e acessos de toda a sua equipe, exceto do proprietário da conta"
            checked={permissions.admin.users_control}
            onClick={useCallback(
              (value) => onChangePermissions("admin.users_control", value),
              []
            )}
          />
          <PermissionListItem
            strong
            divider
            primary="Analisar estatísticas gerenciais"
            secondary="Permita que o colaborador obtenha insights importantes da sua empresa, visualizando gráficos de gastos totais ao longo de um período"
            checked={permissions.admin.analysis}
            onClick={useCallback(
              (value) => onChangePermissions("admin.analysis", value),
              []
            )}
          />
          <PermissionListItem
            strong
            primary="Gerenciar relatórios de despesas"
            secondary="Permita que o colaborador gerencie os relatórios de despesas da empresa, acessando o painel de controle da empresa"
            checked={permissions.admin.panel_control}
            onClick={useCallback(
              (value) => onChangePermissions("admin.panel_control", value),
              []
            )}
          />
          <Divider />
          <PermissionListItem
            strong
            primary="Acesso ao painel de integração SAP"
            secondary="Permita que o colaborador acesse o painel de integração SAP para gerenciar conexões e configurações"
            checked={permissions.admin.panel_sap}
            onClick={useCallback(
              (value) => onChangePermissions("admin.panel_sap", value),
              []
            )}
          />
        </Box>
      </Collapse>
    </List>
  );
};

export default memo(PermissionsList);
