import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Select,
} from "@mui/material";
import React from "react";

function SelectInput({
  value,
  onChange,
  children,
  label,
  renderValue,
  variant,
  StartIcon,
  size,
  error,
  onBlur,
  onFocus,
  fullWidth,
  width,
  placeholder,
  disabled,
  onOpen,
  onClose,
  required,
  sx,
  onClick,
  className,
  readOnly,
  defaultValue,
  autoFocus,
  multiple,
  endIcon,
  noLabel,
  transitionDuration = 0,
  margin,
  helperText = "Campo obrigatório",
  inputRef,
  inputSx
}) {
  return (
    <FormControl
      variant={variant}
      margin={margin}
      size={size}
      fullWidth={fullWidth || true}
      sx={{ width: width, ...sx }}
      disabled={disabled}
    >
      {!noLabel && (
        <InputLabel
          id="defualtSelect-label"
          error={error}
          variant={variant}
          required={required}
        >
          {label}
        </InputLabel>
      )}
      <Select
        MenuProps={{ transitionDuration }}
        labelId="defualtSelect-label"
        autoFocus={autoFocus}
        defaultOpen={autoFocus}
        onClick={onClick}
        inputRef={inputRef}
        displayEmpty={noLabel}
        label={noLabel ? "" : label}
        multiple={multiple}
        startAdornment={
          StartIcon && (
            <InputAdornment position="start">
              <StartIcon fontSize={size} />
            </InputAdornment>
          )
        }
        endAdornment={
          endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>
        }
        required={required}
        renderValue={renderValue}
        readOnly={readOnly}
        onOpen={onOpen}
        onClose={onClose}
        defaultValue={defaultValue}
        disabled={disabled}
        onFocus={onFocus}
        className={className}
        onBlur={onBlur}
        placeholder={placeholder}
        error={error}
        value={value}
        sx={inputSx}
        onChange={(e) => onChange(e.target.value)}
      >
        {children}
      </Select>
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default SelectInput;
