import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { HttpClient } from "../../../../../api/http-client";
import { setError } from "../../../../../store/reducers/bases/errorBaseSlice";
import { openSnackbar } from "../../../../../store/reducers/bases/snackbarBaseSlice";
import { updateUser } from "../../../../../store/reducers/usersSlice";

const ModalUserActivation = ({ open, onClose, active, userId }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await HttpClient.client().put(`/users/${userId}`, {
        status: active ? "inactive" : "active",
      });
      dispatch(
        openSnackbar({
          message: `Colaborador ${active ? "desativado" : "ativado"}`,
        })
      );
      dispatch(
        updateUser({
          id: userId,
          changes: {
            status: active ? "inactive" : "active",
          },
        })
      );
      onClose();
    } catch (error) {
      dispatch(setError(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      {loading && <LinearProgress />}
      <DialogTitle>
        {active ? "Desativar colaborador" : "Ativar colaborador"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {active
            ? "O logout será realizado e o colaborador não poderá acessar as aplicações da Notepay"
            : "Seu acesso será reativado e o colaborador poderá acessar as aplicações da Notepay "}
        </DialogContentText>
        {/* <Box borderRadius={2} mt={3} border={1} borderColor={"divider"} p={2}>
          <Typography fontWeight={"600"}>Atenção</Typography>
          <Typography fontSize={".9rem"}>
            A cobrança mensal será aplicada apenas aos colaboradores que
            estiverem ativos na conta.
          </Typography>
        </Box> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          disabled={loading}
          disableElevation
          variant="contained"
          color={active ? "error" : "primary"}
          onClick={handleConfirm}
        >
          {active
            ? loading
              ? "Desativando"
              : "Desativar"
            : loading
            ? "Ativando"
            : "Ativar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalUserActivation;
