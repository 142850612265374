import {
  CreditCardOffOutlined,
  InfoOutlined,
  PaymentOutlined,
  TimerOutlined,
} from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CompanyLogoSkeleton from "../../../../assets/imgs/company-logo-skeleton.png";
import { selectAccountIsMaster } from "../../../../store/reducers/accountSlice";
import { getAccountStatusData } from "../../../../utils/accountStatus";
import SectionActionButton from "./components/SectionActionButton";
import SectionPaper from "./components/SectionPaper";

function CompanyInfo({ companyName, logo, closeMenu, accountStatus }) {
  const navigate = useNavigate();

  const { description, severity, title } = useMemo(
    () => getAccountStatusData(accountStatus),
    [accountStatus]
  );

  const isMaster = useSelector(selectAccountIsMaster);

  return (
    <>
      <SectionPaper
        sx={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
      >
        <Typography
          gutterBottom
          fontWeight={"500"}
          fontSize={".75rem"}
          variant="body2"
          color={"text.secondary"}
        >
          Empresa:
        </Typography>
        <Box
          borderRadius={2}
          overflow={"hidden"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img src={logo || CompanyLogoSkeleton} width={"60%"} />
        </Box>
        <Typography
          textAlign={"center"}
          mt={2}
          mb={1}
          variant="h5"
          fontSize={"1.3rem"}
          fontWeight={"500"}
          sx={{
            textShadow: "0px 0px 1px rgb(0,0,0,.4)",
          }}
        >
          {companyName}
        </Typography>
      </SectionPaper>
      <Box mt={2} />
      {accountStatus !== "active" && (
        <>
          <SectionPaper
            sx={{ bgcolor: (t) => `${t.palette[severity].main}20` }}
          >
            <Box display={"flex"} gap={1} alignItems={"center"}>
              {["unpaid", "canceled", "paused"].includes(accountStatus) ? (
                <InfoOutlined color={severity} />
              ) : (
                <TimerOutlined color="info" />
              )}
              <Typography ml={2} fontWeight={"500"} variant="body2">
                {title}
              </Typography>
            </Box>
          </SectionPaper>
          <Tooltip
            title="Você não tem permissão para gerenciar plano e faturamento"
            disableHoverListener={isMaster}
            disableFocusListener={isMaster}
          >
            <span>
              <SectionPaper
                sx={{
                  p: 0,
                  mt: 0.5,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
              >
                <SectionActionButton
                  disabled={!isMaster}
                  onClick={() => {
                    navigate(`/billing`);
                    closeMenu();
                  }}
                  Icon={isMaster ? PaymentOutlined : CreditCardOffOutlined}
                >
                  Ver plano e faturamento
                </SectionActionButton>
              </SectionPaper>
            </span>
          </Tooltip>
        </>
      )}
    </>
  );
}

export default CompanyInfo;
