import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/http-client";

const baseName = "users";

const adapter = createEntityAdapter({
  selectId: (user) => user.id,
  sortComparer: (a, b) =>
    (a?.name || a?.email)
      ?.toUpperCase()
      ?.localeCompare((b?.name || b?.email)?.toUpperCase()),
});

const initialState = adapter.getInitialState({
  status: "succeeded",
  error: null,
});

//fetchs
export const fetchUsers = createAsyncThunk(
  `${baseName}/fetchUsers`,
  async () => {
    const res = await HttpClient.client().get(`/users`);
    return res.data;
  }
);

export const usersSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    updateUser(state, action) {
      const { id, changes } = action.payload;
      adapter.updateOne(state, {
        changes,
        id,
      });
    },
    updateManyUsers(state, action) {
      const { ids, changes } = action.payload;
      adapter.updateMany(
        state,
        ids.map((id) => ({ id, changes }))
      );
    },
    updateAllUsers(state, action) {
      adapter.updateMany(
        state,
        state.ids.map((id) => ({ id, changes: action.payload }))
      );
    },
    addUser(state, action) {
      const data = action.payload;
      adapter.addOne(state, data);
    },
    removeUser(state, action) {
      const userId = action.payload;
      adapter.removeOne(state, userId);
    },
    removeManyUsers(state, action) {
      const ids = action.payload;
      adapter.removeMany(state, ids);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        const { entities } = action.payload;
        adapter.setAll(state, entities);
      });
  },
});

export const {
  updateManyUsers,
  addUser,
  updateUser,
  updateAllUsers,
  removeUser,
  removeManyUsers,
} = usersSlice.actions;

export const {
  selectAll: selectAllUsers,
  selectIds: selectUsersIds,
  selectById: selectUserById,
  selectEntities: selectUsersEntities,
  selectTotal: selectUsersTotal,
} = adapter.getSelectors((state) => state[baseName]);

//roles
export const selectUsersIdsByApFlow = createSelector(
  [selectUsersIds, selectUsersEntities, (state, apFlowId) => apFlowId],
  (usersIds, usersEntities, apFlowId) => {
    return usersIds.filter(
      (uId) =>
        usersEntities[uId].apflowId && usersEntities[uId].apflowId === apFlowId
    );
  }
);

export const selectUsersIdsByRole = createSelector(
  [selectUsersIds, selectUsersEntities, (state, roleId) => roleId],
  (usersIds, usersEntities, roleId) => {
    return usersIds.filter((uId) => usersEntities[uId].roles.includes(roleId));
  }
);

export const selectUsersIdsByOrg = createSelector(
  [selectUsersIds, selectUsersEntities, (state, orgId) => orgId],
  (usersIds, usersEntities, orgId) => {
    return usersIds.filter((uId) => usersEntities[uId].org === orgId);
  }
);
export const selectUsersIdsByOccupation = createSelector(
  [selectUsersIds, selectUsersEntities, (state, occupationId) => occupationId],
  (usersIds, usersEntities, occupationId) => {
    return usersIds.filter(
      (uId) => usersEntities[uId].occupation === occupationId
    );
  }
);
export const selectTotalUsersInOrg = createSelector(
  [selectUsersIds, selectUsersEntities, (state, orgId) => orgId],
  (usersIds, usersEntities, orgId) => {
    return usersIds.filter((uId) => usersEntities[uId].org === orgId).length;
  }
);
export const selectUsersIdsByProject = createSelector(
  [selectUsersIds, selectUsersEntities, (state, projectId) => projectId],
  (usersIds, usersEntities, projectId) => {
    if (!projectId) return;
    return usersIds.filter(
      (uId) =>
        usersEntities[uId].projects &&
        usersEntities[uId].projects.includes(projectId)
    );
  }
);

//route
export const selectUsersIdsNoRoutePolicy = createSelector(
  [selectUsersIds, selectUsersEntities],
  (usersIds, usersEntities) => {
    return usersIds.filter((uId) => !usersEntities[uId].routePolicyId);
  }
);
export const selectUsersIdsByRoutePolicy = createSelector(
  [
    selectUsersIds,
    selectUsersEntities,
    (state, routePolicyId) => routePolicyId,
  ],
  (usersIds, usersEntities, routePolicyId) => {
    return usersIds.filter(
      (uId) =>
        usersEntities[uId].routePolicyId &&
        usersEntities[uId].routePolicyId === routePolicyId
    );
  }
);

//filters
const filtersBase = (allUsers = [], filteredIds = [], mapToId) => {
  const users = allUsers.filter((user) => filteredIds.includes(user.id));
  return {
    getByAccess: (access) =>
      users
        .filter((user) =>
          user.roles && access === "provider"
            ? !user?.roles.admin && !user?.roles.master && !user?.roles.approver
            : Boolean(user.roles[access])
        )
        .map((item) => (mapToId ? item.id : item)),
    getByOrg: (org) =>
      users
        .filter((user) => user.org && user.org === org)
        .map((item) => (mapToId ? item.id : item)),
    getByGroup: (group) =>
      users
        .filter((user) => user.group_id && user.group_id === group)
        .map((item) => (mapToId ? item.id : item)),
    getByStatus: (status) =>
      users
        .filter((user) => user.status && user.status === status)
        .map((item) => (mapToId ? item.id : item)),
    getByOccupation: (occupation) =>
      users
        .filter((user) => user.occupation && user.occupation === occupation)
        .map((item) => (mapToId ? item.id : item)),
  };
};

export const selectUsersIdsByFilters = createSelector(
  [
    (state, usersIds) => usersIds,
    (state, usersIds, filters) => filters,
    selectAllUsers,
    selectUsersEntities,
    (state, usersIds, filters, mapToId) => mapToId,
  ],
  (usersIds, filters, allUsers, entities, mapToId) => {
    if (!usersIds.length) return [];
    let filtered = [
      ...usersIds?.map((userId) => (mapToId ? userId : entities[userId])),
    ];
    if (filters && "access" in filters) {
      filtered = filtersBase(allUsers, usersIds, mapToId).getByAccess(
        filters.access
      );
    }
    if (filters && "org" in filters) {
      filtered = filtersBase(allUsers, usersIds, mapToId).getByOrg(filters.org);
    }
    if (filters && "group" in filters) {
      filtered = filtersBase(allUsers, usersIds, mapToId).getByGroup(
        filters.group
      );
    }
    if (filters && "status" in filters) {
      filtered = filtersBase(allUsers, usersIds, mapToId).getByStatus(
        filters.status
      );
    }
    if (filters && "occupation" in filters) {
      filtered = filtersBase(allUsers, usersIds, mapToId).getByOccupation(
        filters.occupation
      );
    }

    return filtered;
  }
);

//by group
export const selectUsersIdsByGroup = createSelector(
  [selectUsersIds, selectUsersEntities, (state, groupId) => groupId],
  (ids, entities, groupId) => {
    return ids.filter((uId) => entities[uId]?.group_id === groupId);
  }
);

export default usersSlice.reducer;
