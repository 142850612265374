import React from 'react';
import styled from 'styled-components';


const StyledInput = React.forwardRef((props, ref) => {
    return <Styled ref={ref} {...props} />;
});

export default React.memo(StyledInput)

const Styled = styled.input`
    background-color: transparent;
    height: 100%;
    outline: none;
    margin-left: 10px;
    flex: 1;
    font-size: 1.03rem;
    font-weight: 500;
    opacity: 1;
    color: ${p => p.color};
    ::placeholder{
        text-shadow: ${p => p.tx};
        color: ${p => p.color};
        opacity: .65;
    }
`