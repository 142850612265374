import axios from "axios";
import { HttpClient } from "./http-client";
import { AUTH_CLIENT_URL } from "./urls";

export class AuthClient {
  static _client = null;
  static client() {
    if (!AuthClient._client) {
      AuthClient._client = axios.create({
        baseURL: AUTH_CLIENT_URL,
        withCredentials: true,
      });
    }

    return AuthClient._client;
  }
  static setAccessToken(token) {
    AuthClient.client().defaults.headers["api-access-token"] = token;
  }
}

export class AuthAPIGateway {
  static async refreshToken(account = undefined) {
    const r = await AuthClient.client().get("/refresh_token", {
      account,
    });
    const d = r.data;
    if (d.accessToken) {
      AuthClient.setAccessToken(d.accessToken);
    }
    return r;
  }
  static getMe() {
    return HttpClient.client().get("/users/me");
  }
  static async logout() {
    await AuthClient.client().get("/logout");
  }
}
